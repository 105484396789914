<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :docsName="docsName" />

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>

              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-if="editable"
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>
                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.m_lead.delete && editable"
                  >
                    <font-awesome-icon icon="fa-duotone fa-trash" />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-12 pb-3">
          <ul class="arrowbox">
            <li
              v-for="item in leadstages"
              :key="item.id"
              class="arrowbox-item"
              v-bind:class="{
                'arrowbox-item-selected': item.selected,
              }"
              @click="setStage(item)"
            >
              {{ item.bezeichnung }}
            </li>
          </ul>
        </div>

        <!-- START Linke Seite -->
        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Daten -->
          <container-headline
            :headline="$t('global.masterdata')"
            :col="4"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-6">
                    <single-select-firma
                      id="ansprechpartner-firma"
                      :firma="lead.firma"
                      :editable="editable"
                      :allowOpen="true"
                      :required="checkGP"
                      :label="$t('global.primarycontactcompany')"
                      @confirmed="setFirma"
                      :tabindex="1"
                    />
                  </div>
                  <div class="col-xl-6">
                    <single-select-person
                      id="ansprechpartner"
                      :person="lead.ansprechpartner"
                      :firma="lead.firma"
                      :label="$t('global.primarycontactperson')"
                      :allowOpen="true"
                      :editable="editable"
                      :required="checkGP"
                      @confirmed="setAnsprechpartner"
                      :tabindex="2"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <input
                        v-model="lead.bezeichnung"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <single-select-person
                      id="firstcontact"
                      :person="lead.erstkontakt"
                      :label="$t('global.FirstContact')"
                      :allowOpen="true"
                      :editable="editable"
                      @confirmed="setErstKontakt"
                      :tabindex="3"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-3">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :initial="lead.erfassungsdatum"
                        :disabled="!editable"
                        placeholder=" "
                        :show-icon="true"
                        @update="(val) => (lead.erfassungsdatum = val)"
                      />
                      <label>{{ $t("global.recorded") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-3">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :initial="lead.abschlussdatum"
                        :disabled="!editable"
                        placeholder=" "
                        :show-icon="true"
                        @update="(val) => (lead.abschlussdatum = val)"
                      />
                      <label>{{ $t("global.completionuntil") }}</label>
                    </div>
                  </div>

                  <div class="col-xl-3">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :initial="lead.naechsterkontakt"
                        :disabled="!editable"
                        placeholder=" "
                        :show-icon="true"
                        @update="(val) => (lead.naechsterkontakt = val)"
                      />
                      <label>{{ $t("global.nextcontact") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-3">
                    <div class="form-group">
                      <input
                        v-model.number="lead.anrufversuche"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <label>{{ $t("global.NoOfCalls") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="lead.artikel"
                        :options="artikelListe"
                        label="bezeichnung"
                        :disabled="!editable"
                        :placeholder="$t('global.item')"
                      >
                        <template slot="option" slot-scope="option">
                          {{ option.kurzbezeichnung }} -
                          {{ option.bezeichnung }} ({{ option.artikelnummer }})
                        </template>

                        <template slot="selected-option" slot-scope="option">
                          {{ option.kurzbezeichnung }} -
                          {{ option.bezeichnung }} ({{ option.artikelnummer }})
                        </template>
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.item") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-select
                        v-model="lead.mitbewerberprodukt"
                        label="bezeichnung"
                        :options="mitbewerberprodukte"
                        :disabled="!editable"
                        placeholder="Mitbewerberprodukt"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.CompProduct") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ENDE DATEN -->
        </div>

        <!-- START Rechte Seite -->

        <div class="col-xl-6 pl-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Zordnung -->

          <container-headline
            :headline="$t('global.categorization')"
            :col="4"
          ></container-headline>

          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group">
                      <ValidationProvider
                        rules="required"
                        immediate
                        v-slot="{ passed }"
                      >
                        <v-select
                          v-model="lead.typ"
                          label="bezeichnung"
                          v-bind:class="{ 'border-info': !passed }"
                          :options="leadtypen"
                          :disabled="!editable"
                          placeholder="Typ"
                        >
                          <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                        </v-select>
                        <label v-bind:class="{ 'text-danger': !passed }">{{
                          $t("global.type")
                        }}</label>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-xl-8">
                    <div class="form-group">
                      <ValidationProvider
                        rules="required"
                        immediate
                        v-slot="{ passed }"
                      >
                        <v-select
                          v-model="lead.stage"
                          v-bind:class="{ 'border-info': !passed }"
                          :options="leadstages"
                          label="bezeichnung"
                          :disabled="!editable"
                          :placeholder="$t('global.stage')"
                        >
                          <template slot="option" slot-scope="option">
                            {{ option.wahrscheinlichkeit }}%
                            {{ option.bezeichnung }}
                          </template>

                          <template slot="selected-option" slot-scope="option">
                            {{ option.wahrscheinlichkeit }}%
                            {{ option.bezeichnung }}
                          </template>
                          <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                        </v-select>
                        <label v-bind:class="{ 'text-danger': !passed }">{{
                          $t("global.stage")
                        }}</label>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group">
                      <ValidationProvider
                        rules="required"
                        immediate
                        v-slot="{ passed }"
                      >
                        <b-form-input
                          v-model.number="lead.umsatz"
                          type="number"
                          class="form-control"
                          placeholder=" "
                          v-bind:class="{ 'border-danger': !passed }"
                          :readonly="!editable"
                        />
                        <label>{{ $t("global.saleschf") }}</label>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group">
                      <b-form-input
                        v-model.number="lead.umsatzbewertet"
                        class="form-control"
                        placeholder=" "
                        readonly
                      />
                      <label>{{ $t("global.evaluatedchf") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group">
                      <v-select
                        v-model="lead.quelle"
                        label="bezeichnung"
                        :options="leadquellen"
                        :disabled="!editable"
                        :placeholder="$t('global.source')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.source") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group">
                      <v-select
                        v-model="lead.ACCESSGROUP"
                        label="name"
                        :options="gruppenliste"
                        :disabled="!editable"
                        :placeholder="$t('global.source')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.accessgroup") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-8">
                    <single-select-person
                      id="verantwortlicher"
                      :person="lead.verantwortlicher"
                      :label="$t('global.Responsible')"
                      :allowOpen="true"
                      :editable="editable"
                      :required="true"
                      @confirmed="setVerantwortlicher"
                      :tabindex="3"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- ENDE Zuordnung -->
          </div>

          <!-- ENDE Rechte Seite -->
        </div>

        <div class="col-xl-12 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Daten -->
          <container-headline
            :headline="$t('global.description')"
            :col="4"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="lead.kurznotiz"
                        class="form-control"
                        placeholder=" "
                        :readonly="!editable"
                      />
                      <label>{{ $t("global.kurznotiz") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <textarea
                        v-model.trim="lead.beschreibung"
                        class="form-control"
                        placeholder=" "
                        :readonly="!editable"
                      ></textarea>
                      <label>{{ $t("global.description") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ENDE DATEN -->
        </div>

        <div v-if="lead.id" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 p-0">
              <ul class="nav nav-tabs mt-3 fs-28">
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 0 }"
                    :to="{
                      name: 'lead',
                      params: { id: id, anzeige: 0 },
                    }"
                    >{{ $t("global.contactpersons") }}</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 1 }"
                    :to="{
                      name: 'lead',
                      params: { id: id, anzeige: 1 },
                    }"
                    >{{ $t("global.activities") }}</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 2 }"
                    :to="{
                      name: 'lead',
                      params: { id: id, anzeige: 2 },
                    }"
                    >{{ $t("global.documents") }}</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="col-xl-12 p-0">
              <div class="tab-container p-3">
                <div v-show="anzeige === 0">
                  <lead-kontakt-liste
                    :lead="lead.id"
                    :shown="anzeige == 0 ? true : false"
                    :editable="editable"
                  />
                </div>

                <div v-show="anzeige === 1">
                  <lead-pendenzen
                    :lead="lead.id"
                    :geschaeftspartner="lead.ansprechpartner"
                    :editable="editable"
                    :standardContactbased="true"
                  />
                </div>

                <div v-show="anzeige === 2">
                  <dokumentenliste
                    :foreignKey="id"
                    foreignTable="lead"
                    :dokumentEditable="editable"
                    :person="lead.ansprechpartner?.id"
                    :key="componentKey"
                    @updateComponent="updateDokumentenliste"
                    :shown="anzeige == 2 ? true : false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <unsaved-changes-modal
        @discard="handleDiscard"
        @stay="handleStay"
        :saveFunction="invalid == true ? null : speichern"
      />
    </ValidationObserver>

    <loading-overlay v-show="loading"></loading-overlay>

    <loeschen-modal id="lead-loeschen-modal" @confirmed="leadloeschen" />
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";

import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import DatePicker from "@/components/Datepicker";
import LeadKontaktListe from "@/pages/CRM/LeadKontaktListe";
//import LeadAktivitaetenListe from "@/pages/CRM/LeadAktivitaetenListe";
import Dokumentenliste from "@/components/Reiter/Dokumentenliste";
import LeadPendenzen from "@/components/Reiter/Aktivitaetenliste.vue";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import Api from "@/Api";
import store from "@/store";

import page from "@/mixins/Page";
import ChangeWatcherNew from "@/mixins/ChangewatcherNew";
import UnsavedChangesModal from "@/components/Modals/UnsavedChangesModal.vue";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import SingleSelectFirma from "@/components/global/SingleSelectFirma.vue";

import LoeschenModal from "@/components/Modals/LoeschenModal";

import { LADE_AUSWAHLWERTE_CRM } from "@/store/crm/actions.type";
import { LADE_ARTIKEL } from "@/store/artikel/actions.type";

export default {
  name: "Lead",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    DatePicker,
    LoeschenModal,
    SingleSelectPerson,
    LeadKontaktListe,
    // LeadAktivitaetenListe,
    LeadPendenzen,
    Dokumentenliste,
    SingleSelectFirma,
    UnsavedChangesModal,
  },
  mixins: [page, ChangeWatcherNew],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    person: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
    kategorieProp: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      page: 0,
      infiniteId: +new Date(),
      anzahlProPage: 25,
      editable: true,
      pruefungKey: 0,

      lead: {
        ansprechpartner: null,
        firma: {},
        verantwortlicher: null,
        stage: {},
      },

      componentKey: 0,
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      return params;
    },

    navbarTitel: function () {
      if (this.id) {
        return this.$t("global.lead");
      } else {
        return this.$t("global.newlead");
      }
    },

    gruppenliste: {
      get() {
        return this.$store.state.gruppenliste;
      },
    },

    leadstages: {
      get() {
        const stages = this.$store.state.crm.leadstages;

        if (this.lead && this.lead.stage) {
          stages.forEach((stage) => {
            stage.selected = stage.id == this.lead.stage.id ? true : false;
          });
        }

        stages.sort((a, b) => {
          return a.sort > b.sort;
        });

        return stages;
      },
    },
    leadquellen: {
      get() {
        return this.$store.state.crm.leadquellen;
      },
    },
    leadtypen: {
      get() {
        return this.$store.state.crm.leadtypen;
      },
    },
    mitbewerberprodukte: {
      get() {
        return this.$store.state.crm.mitbewerberprodukte;
      },
    },
    artikelListe() {
      let tempList = this.$store.state.artikel.artikel;
      return tempList.sort((a, b) =>
        a.kurzbezeichnung.localeCompare(b.kurzbezeichnung)
      );
    },

    checkGP() {
      if (this.lead.ansprechpartner) {
        return false;
      }
      if (this.lead.firma) {
        return false;
      }
      return true;
    },
  },
  watch: {},
  created() {
    this.loading = true;

    this.loadLead();
  },
  mounted: function () {
    if (this.leadstages.length == 0)
      this.$store.dispatch(`crm/${LADE_AUSWAHLWERTE_CRM}`);

    if (this.artikelListe.length == 0)
      this.$store.dispatch(`artikel/${LADE_ARTIKEL}`);
  },
  methods: {
    loadLead() {
      if (this.id) {
        Api.get("crm/lead/", { params: { id: this.id } }).then((response) => {
          this.initializeLead(response.data);

          this.loading = false;
        });
      } else {
        let today = new Date(Date.now());

        this.lead.verantwortlicher = this.angemeldeterMitarbeiter.person;
        this.lead.erfassungsdatum = today.toLocaleDateString();
        this.lead.stage = this.leadstages.find(
          (stage) => stage.id === this.$CONST("CONFIG").LEADSTAGE
        );

        if (this.person) {
          Api.get("geschaeftspartner/", { params: { id: this.person } }).then(
            (response) => {
              this.lead.ansprechpartner = response.data;
            }
          );
        }

        this.editable = true;
        this.loading = false;
      }
    },

    setStage(item) {
      this.lead.stage = item;
    },

    abbrechen() {
      this.$router.push({ name: "lead-liste" });
    },

    initializeLead(lead) {
      if (lead) {
        this.lead = lead;

        /*this.editable =
          this.lead.leadstage.id == this.$CONST("LEADSTAGES").WON ||
          this.lead.leadstage.id == this.$CONST("LEADSTAGES").LOST ||
          this.lead.leadstage.id == this.$CONST("LEADSTAGES").CLOSED
            ? false
            : true;*/

        this.editable = true;

        if (this.berechtigungen.m_lead.update == false) this.editable = false;

        //this.initializeChangewatcher(["lead"]);
      } else {
        console.error("lead ist null");
      }
    },

    speichern() {
      if (this.loading) return;
      this.loading = true;
      var json = this.lead;

      json.umsatzbewertet = json.umsatz
        ? parseInt(json.umsatz) *
          (parseInt(json.stage.wahrscheinlichkeit) / 100)
        : 0;

      if (!this.id) {
        Api.post("crm/lead/", json)
          .then((response) => {
            this.initializeLead(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.leadsavedsuccessfully"),
            });

            this.$router.replace({
              name: "lead",
              params: { id: response.data.id, anzeige: 0 },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("crm/lead/", json, { params: { id: this.id } })
          .then((response) => {
            this.initializeLead(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.leadsavedsuccessfully"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "lead-loeschen-modal");
    },

    leadloeschen() {
      Api.delete("crm/lead/", { data: [this.id] })
        .then(() => {
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.leaddeletedsuccessfully"),
          });
          this.$router.push({ name: "lead-liste" });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {});
    },

    setVerantwortlicher(person) {
      this.lead.verantwortlicher = person;
    },

    setErstKontakt(person) {
      this.lead.erstkontakt = person;
    },

    setAnsprechpartner(person) {
      this.lead.ansprechpartner = person;
    },

    setFirma(firma) {
      this.lead.firma = firma;
    },

    updateDokumentenliste() {
      this.componentKey++;
    },
  },
};
</script>

<style>
.arrowbox {
  display: block;
  width: 100%;
  font-weight: bold;
  color: white;
  margin: 0 auto;
  padding: 10px 0px;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
}

.arrowbox-item {
  font-size: 7pt !important;
  display: block;
  position: relative;
  float: left;
  width: calc(100% / 9 - 9px) !important;
  height: 40px;
  line-height: 40px;
  margin-right: 10px;
  padding: 0 10px;
  background-color: rgb(18, 72, 98);
  border-radius: 4px;
}

.arrowbox-item:before {
  content: "";
  position: absolute;
  right: -9px;
  height: 0;
  width: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 10px solid rgb(18, 72, 98);
  border-radius: 4px;
}

.arrowbox-item:after {
  content: "";
  position: absolute;
  left: -1px;
  height: 0;
  width: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 10px solid #deedf6;
  border-radius: 4px;
}

.arrowbox-item:first-child:after {
  display: none;
}

.arrowbox-item:last-child {
  margin-right: 0;
}

.arrowbox-item:last-child:before {
  display: none;
}

.arrowbox-item-selected {
  background-color: green !important;
}

.arrowbox-item-selected:before {
  border-left: 10px solid green !important;
}
</style>
