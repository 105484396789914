<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>

    <navbar v-if="showNavbar" :docsName="docsName" />

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>

              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-shortkey.once="['ctrl', 's']"
                    v-if="editable || isAdmin"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>

                  <button
                    v-if="editable"
                    class="btn btn-primary mr-2"
                    @click="abbrechen"
                  >
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>

                  <button
                    v-if="editable && $CONST('CONFIG').APPTYPE === 'Education'"
                    class="btn btn-primary mr-2"
                    :disabled="invalid || !id"
                    @click="neueAnmeldung"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-ticket"
                      class="mr-2"
                    />
                    {{ $t("global.newregistration") }}
                  </button>

                  <button
                    class="btn btn-primary mr-2"
                    @click="neueMail"
                    :disabled="invalid || !id"
                    v-if="
                      berechtigungen.m_korrespondenz.create &&
                      $CONST('CONFIG').APPTYPE != 'DESKA'
                    "
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-envelope-open-text"
                      class="mr-2"
                    />
                    <span>{{ $t("global.sendemail") }}</span>
                  </button>

                  <button
                    class="btn btn-primary mr-2"
                    @click="neueKorrespondenz"
                    :disabled="invalid || !id"
                    v-if="
                      berechtigungen.m_korrespondenz.create &&
                      $CONST('CONFIG').APPTYPE != 'DESKA'
                    "
                  >
                    <font-awesome-icon icon="fa-duotone fa-memo" class="mr-2" />
                    <span>{{ $t("global.newcorrespondence") }}</span>
                  </button>

                  <button
                    class="btn btn-primary mr-2"
                    @click="abacusExport"
                    v-if="berechtigungen.m_korrespondenz.read"
                  >
                    <font-awesome-icon
                      icon="fa-regular fa-file-export"
                      class="mr-2"
                    />
                    {{ $t("global.adressexport") }}
                  </button>

                  <button
                    class="btn btn-primary"
                    @click="
                      carbone(
                        'Abklaerungsbericht',
                        '31941b64845f09a0452f3b054aee101803843b7fac744549e44f6a7ae57ce894',
                        'carbone-erstbeurteilung/?id='
                      )
                    "
                    v-if="berechtigungen.m_korrespondenz.read"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-print"
                      class="mr-2"
                    />
                    {{ $t("global.clarificationreport") }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="editable"
                  >
                    <font-awesome-icon
                      class="mr-2"
                      icon="fa-duotone fa-trash"
                    />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- START Linke Seite -->
        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <div class="row">
            <div class="col-xl-12">
              <personalangaben
                :id="id"
                :person="person"
                :roles="roles"
                :editable="editable"
                :key="personalAngabenKey"
                @rolesChanged="computeRoles"
                @bildUpdated="ladeGeschaeftspartner(id, true)"
              />
            </div>
            <div v-if="$CONST('CONFIG').APPTYPE != 'DESKA'">
              <div class="col-xl-12">
                <beruf-und-ausbildung
                  v-if="
                    roles.isDozent ||
                    roles.isMitarbeiter ||
                    roles.isDiplomarbeiten ||
                    roles.isReferent ||
                    roles.isStudent ||
                    roles.isAnstaltsdirektor ||
                    roles.isNichtZugeordnet
                  "
                  :person="person"
                  :roles="roles"
                  :editable="editable"
                />
              </div>
              <div class="col-xl-12">
                <kommunikationsangaben
                  :person="person"
                  :roles="roles"
                  :isPerson="true"
                  :editable="editable"
                  @commChanged="commChanged"
                />
              </div>
              <div class="col-xl-12" v-if="id">
                <portalzugaenge :personid="id" :users="person.users" />
              </div>
            </div>
            <!--  Kundenspezifische-Felder -->
            <div class="col-xl-12">
              <KundenspezifischeFelder :target="person" :editable="editable" />
            </div>
          </div>
        </div>
        <div class="col-xl-6 pl-4" :class="{ 'col-xl-12': !showNavbar }">
          <div class="row">
            <div class="col-xl-12">
              <Zusatzangaben
                v-if="$CONST('CONFIG').APPTYPE != 'DESKA'"
                :person="person"
                :roles="roles"
                :editable="editable"
              />
              <RechnungAdresse
                v-if="$CONST('CONFIG').APPTYPE != 'DESKA'"
                :person="person"
                :editable="editable"
                @invoiceToChanged="invoiceToChanged"
              />
              <KommunikationsAdresse
                v-if="$CONST('CONFIG').APPTYPE != 'DESKA'"
                :person="person"
                :editable="editable"
                @dataChanged="commToChanged"
              />
              <kommunikationsangaben
                v-if="$CONST('CONFIG').APPTYPE === 'DESKA'"
                :person="person"
                :roles="roles"
                :editable="editable"
                @commChanged="commChanged"
              />
            </div>
          </div>
        </div>

        <!-- ENDE Rechte Seite -->

        <!-- HEADER DER REITER -->

        <div v-if="id && $CONST('CONFIG').APPTYPE != 'DESKA'" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 p-0">
              <ul class="nav nav-tabs mt-3 fs-28">
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 0 }"
                    :to="{
                      name: 'geschaeftspartner',
                      params: { id: id, anzeige: 0 },
                    }"
                    >{{ $t("global.contactinformation") }}</router-link
                  >
                </li>

                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 14 }"
                    :to="{
                      name: 'geschaeftspartner',
                      params: { id: id, anzeige: 14 },
                    }"
                    >{{ $t("global.relationships") }}</router-link
                  >
                </li>

                <li
                  v-show="person && berechtigungen.m_finanzen.read"
                  class="nav-item"
                >
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 1 }"
                    :to="{
                      name: 'geschaeftspartner',
                      params: { id: id, anzeige: 1 },
                    }"
                    >{{ $t("global.bankdetails") }}</router-link
                  >
                </li>

                <li
                  v-show="
                    person &&
                    (roles.isStudent || roles.isDozent) &&
                    berechtigungen.m_bildung.read
                  "
                  class="nav-item"
                >
                  <router-link
                    v-if="$CONST('CONFIG').NIVEAULH != 'true'"
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 2 }"
                    :to="{
                      name: 'geschaeftspartner',
                      params: { id: id, anzeige: 2 },
                    }"
                    >{{ $t("global.qualifications") }}</router-link
                  >
                  <router-link
                    v-if="$CONST('CONFIG').NIVEAULH == 'true'"
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 2 }"
                    :to="{
                      name: 'geschaeftspartner',
                      params: { id: id, anzeige: 2 },
                    }"
                    >Niveau</router-link
                  >
                </li>

                <li v-show="person" class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 3 }"
                    :to="{
                      name: 'geschaeftspartner',
                      params: { id: id, anzeige: 3 },
                    }"
                    >{{ $t("global.registrations") }}</router-link
                  >
                </li>

                <li v-show="person && roles.isDozent" class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 10 }"
                    :to="{
                      name: 'geschaeftspartner',
                      params: { id: id, anzeige: 10 },
                    }"
                    >{{ $t("global.wagerates") }}</router-link
                  >
                </li>
                <li
                  v-show="person && berechtigungen.m_finanzen.read"
                  class="nav-item"
                >
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 5 }"
                    :to="{
                      name: 'geschaeftspartner',
                      params: { id: id, anzeige: 5 },
                    }"
                    >{{ $t("global.invoices") }}</router-link
                  >
                </li>

                <li
                  v-show="person && berechtigungen.m_korrespondenz.read"
                  class="nav-item"
                >
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 6 }"
                    :to="{
                      name: 'geschaeftspartner',
                      params: { id: id, anzeige: 6 },
                    }"
                    >{{ $t("global.correspondence") }}</router-link
                  >
                </li>

                <li
                  v-show="person && berechtigungen.m_korrespondenz.read"
                  class="nav-item"
                >
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 7 }"
                    :to="{
                      name: 'geschaeftspartner',
                      params: { id: id, anzeige: 7 },
                    }"
                    >{{ $t("global.email") }}</router-link
                  >
                </li>
                <li
                  v-show="person && !hatDozentenBenutzergruppe"
                  class="nav-item"
                >
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 8 }"
                    :to="{
                      name: 'geschaeftspartner',
                      params: { id: id, anzeige: 8 },
                    }"
                    >{{ $t("global.documents") }}</router-link
                  >
                </li>
                <li
                  v-show="
                    person &&
                    roles.isStudent &&
                    berechtigungen.m_subvention.read
                  "
                  class="nav-item"
                >
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 9 }"
                    :to="{
                      name: 'geschaeftspartner',
                      params: { id: id, anzeige: 9 },
                    }"
                    >{{ $t("global.subsidies") }}</router-link
                  >
                </li>

                <li
                  v-show="person && berechtigungen.m_notizen.read"
                  class="nav-item"
                >
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 11 }"
                    :to="{
                      name: 'geschaeftspartner',
                      params: { id: id, anzeige: 11 },
                    }"
                    >{{ $t("global.notes") }}</router-link
                  >
                </li>

                <li
                  v-show="(person && roles.isStudent) || roles.isDozent"
                  class="nav-item"
                >
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 15 }"
                    :to="{
                      name: 'geschaeftspartner',
                      params: { id: id, anzeige: 15 },
                    }"
                    >{{ $t("global.journal") }}</router-link
                  >
                </li>
                <li v-show="person || roles.isDozent" class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 16 }"
                    :to="{
                      name: 'geschaeftspartner',
                      params: { id: id, anzeige: 16 },
                    }"
                    >{{ $t("global.presencejournal") }}</router-link
                  >
                </li>
                <li v-show="person" class="nav-item">
                  <router-link
                    v-if="berechtigungen.m_bildung.read"
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 17 }"
                    :to="{
                      name: 'geschaeftspartner',
                      params: { id: id, anzeige: 17 },
                    }"
                    >ToR</router-link
                  >
                </li>
                <li v-show="person" class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 18 }"
                    :to="{
                      name: 'geschaeftspartner',
                      params: { id: id, anzeige: 18 },
                    }"
                    >{{ $t("global.pendingissues") }}</router-link
                  >
                </li>
              </ul>
            </div>

            <div class="col-xl-12 p-0">
              <div class="tab-container p-3">
                <div v-if="anzeige === 0">
                  <kontaktreiter
                    :person="id"
                    :key="kontaktkey"
                    :editable="editable"
                    :shown="anzeige == 0 ? true : false"
                    @added="mailAdresseHinzufuegen"
                    v-on:updatekontakte="kontaktegeaendert"
                  />
                </div>

                <div v-show="anzeige === 1">
                  <bankverbindung-reiter
                    :person="id"
                    :editable="editable"
                    v-on:updatebankverbindung="bankverbindungeaendert"
                    :shown="anzeige == 1 ? true : false"
                  />
                </div>

                <div v-show="anzeige === 2">
                  <qualifikationen-reiter
                    :person="id"
                    :editable="editable"
                    v-on:updatequalifikationen="qualifikationenGeaendert"
                    :shown="anzeige == 2 ? true : false"
                  />
                </div>

                <div v-show="anzeige === 10">
                  <lohnansatz-liste
                    :person="id"
                    :editable="editable"
                    :shown="anzeige == 10 ? true : false"
                  />
                </div>

                <div v-show="anzeige === 3">
                  <anmeldungsliste
                    :person="id"
                    :editable="editable"
                    :showRechnung="false"
                    :mailadressen="mailadressen"
                    :key="anmeldungkey"
                    :shown="anzeige == 3 ? true : false"
                  />
                </div>

                <div v-show="anzeige === 5">
                  <rechnung-ausgangliste
                    :person="id"
                    :editable="editable"
                    :shown="anzeige == 5 ? true : false"
                  />
                </div>

                <div v-show="anzeige === 6">
                  <korrespondenzliste
                    :person="id"
                    :editable="editable"
                    :shown="anzeige == 6 ? true : false"
                  />
                </div>

                <div v-show="anzeige === 7">
                  <mailliste
                    :person="id"
                    :mailadressen="mailadressen"
                    :editable="editable"
                    :shown="anzeige == 7 ? true : false"
                    :key="mailkey"
                    @confirmed="maillisteUpdated"
                  />
                </div>

                <div v-show="anzeige === 8">
                  <dokumentenliste
                    :person="id"
                    :dokumentEditable="editable"
                    :shown="anzeige == 8 ? true : false"
                    :key="componentKey"
                    @updateComponent="updateDokumentenliste"
                  />
                </div>

                <div v-show="anzeige === 9">
                  <subventionen-reiter
                    :person="id"
                    :editable="editable"
                    :shown="anzeige == 9 ? true : false"
                  />
                </div>

                <div v-show="anzeige === 14">
                  <beziehung-reiter
                    :gp="id"
                    :defaultGP="person"
                    :gptyp="'person'"
                    :editable="editable"
                    :shown="anzeige == 14 ? true : false"
                    @reloadGP="ladeGeschaeftspartner(id)"
                  />
                </div>

                <div v-show="anzeige === 11">
                  <notizen-liste
                    :person="id"
                    :editable="editable"
                    :shown="anzeige == 11 ? true : false"
                  />
                </div>

                <div
                  v-show="anzeige === 15"
                  v-if="berechtigungen.m_journal.read"
                >
                  <!-- Start Journal -->
                  <journal-eintrag-liste
                    :personID="id"
                    :person="person"
                    fkey=""
                    ftable=""
                    :editable="editable"
                    :shown="anzeige == 15"
                  />
                  <!-- Ende Journal -->
                </div>
                <div
                  v-show="anzeige === 16"
                  v-if="berechtigungen.m_journal.read"
                >
                  <praesenzjournalliste
                    :person="id"
                    fkey=""
                    ftable=""
                    :editable="editable"
                    :shown="anzeige == 16"
                  />
                </div>
                <div v-show="anzeige === 17">
                  <tor-liste
                    :person="id"
                    :editable="editable"
                    :shown="anzeige == 17"
                  />
                </div>
                <div v-show="anzeige === 18">
                  <person-pendenzen
                    :person="id"
                    :geschaeftspartner="person"
                    :editable="editable"
                    :shown="anzeige == 18"
                    :standardContactbased="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="id && $CONST('CONFIG').APPTYPE === 'DESKA'"
          class="col-xl-12"
        >
          <div class="row">
            <div class="col-xl-12 p-0">
              <ul class="nav nav-tabs mt-3 fs-28">
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 0 }"
                    :to="{
                      name: 'GeschaeftspartnerMitIdUndAnzeige',
                      params: { id: id, anzeige: 0 },
                    }"
                    >{{ $t("global.contactinformation") }}</router-link
                  >
                </li>

                <li v-show="person" class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 7 }"
                    :to="{
                      name: 'GeschaeftspartnerMitIdUndAnzeige',
                      params: { id: id, anzeige: 7 },
                    }"
                    >{{ $t("global.email") }}</router-link
                  >
                </li>

                <li v-show="person" class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 11 }"
                    :to="{
                      name: 'GeschaeftspartnerMitIdUndAnzeige',
                      params: { id: id, anzeige: 11 },
                    }"
                    >{{ $t("global.notes") }}</router-link
                  >
                </li>
              </ul>
            </div>

            <div class="col-xl-12 p-0">
              <div class="tab-container p-3">
                <div v-show="anzeige === 0">
                  <kontaktreiter
                    :person="id"
                    :key="kontaktkey"
                    :editable="editable"
                    :shown="anzeige == 0 ? true : false"
                    @added="mailAdresseHinzufuegen"
                    v-on:updatekontakte="kontaktegeaendert"
                  />
                </div>

                <div v-show="anzeige === 7">
                  <mailliste
                    :person="id"
                    :mailadressen="mailadressen"
                    :editable="editable"
                    :shown="anzeige == 7 ? true : false"
                    :key="mailkey"
                    @confirmed="maillisteUpdated"
                  />
                </div>

                <div v-show="anzeige === 11">
                  <notizen-liste
                    :person="id"
                    :editable="editable"
                    :shown="anzeige == 11 ? true : false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <unsaved-changes-modal
        @discard="handleDiscard"
        @stay="handleStay"
        :saveFunction="invalid == true ? null : speichern"
      />
    </ValidationObserver>

    <!-- MODALS -->

    <loeschen-modal
      id="geschaeftspartner-loeschen-modal"
      @confirmed="geschaeftspartnerLoeschen"
    />

    <messagebox
      id="dupletten-msg-box"
      ref="duplettenMsgBox"
      :header-text="$t('global.duplicatesfound')"
      ok
      cancel
      repeat
      :repeat-text="$t('global.openduplicate')"
      @ok="speichern(true)"
      @repeat="oeffneDuplette"
    >
      <p>
        {{ $t("global.personexistsalready") }}
      </p>
      <div class="row"></div>
    </messagebox>

    <pdf-layer
      id="pdf-layer-abklaerungsbericht"
      ref="pdfLayerAbklaerungsbericht"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
      :files="pdfFiles"
    ></pdf-layer>

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";
import CarboneApi from "@/CarboneApi";
import server from "@/server";

import Personalangaben from "@/components/Geschaeftspartner/Personalangaben.vue";
import BerufUndAusbildung from "@/components/Geschaeftspartner/BerufUndAusbildung.vue";
import Zusatzangaben from "@/components/Geschaeftspartner/Zusatzangaben.vue";
import Kommunikationsangaben from "@/components/Geschaeftspartner/Kommunikationsangaben.vue";
import RechnungAdresse from "@/components/Geschaeftspartner/RechnungAdresse.vue";
import KommunikationsAdresse from "@/components/Geschaeftspartner/KommunikationsAdresse.vue";
import Portalzugaenge from "@/components/Geschaeftspartner/Portalzugaenge.vue";
import KundenspezifischeFelder from "@/components/KundenspezifischeFelder.vue";

import Kontaktreiter from "@/pages/Geschaeftspartner/Kontaktreiter";
import BankverbindungReiter from "@/pages/Geschaeftspartner/BankverbindungReiter";

import Korrespondenzliste from "@/components/Reiter/Korrespondenzliste";
//import Notenliste from "@/components/Reiter/Notenliste";
import Mailliste from "@/components/Reiter/Mailliste";
import Dokumentenliste from "@/components/Reiter/Dokumentenliste";
import Anmeldungsliste from "@/components/Reiter/Anmeldungsliste";
import JournalEintragListe from "@/components/Reiter/JournalEintragListe";
import QualifikationenReiter from "@/pages/Geschaeftspartner/QualifikationenReiter.vue";
import SubventionenReiter from "@/pages/Geschaeftspartner/SubventionenReiter.vue";
import BeziehungReiter from "@/pages/Geschaeftspartner/BeziehungReiter.vue";
import RechnungAusgangliste from "@/components/Reiter/RechnungAusgangliste.vue";
import Praesenzjournalliste from "@/components/Reiter/Praesenzjournalliste.vue";
import LohnansatzListe from "@/components/Reiter/Lohnansaetze.vue";
import NotizenListe from "@/components/Reiter/Notizenliste.vue";
import TorListe from "@/components/Reiter/Torliste.vue";
import PersonPendenzen from "@/components/Reiter/Aktivitaetenliste.vue";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import { pdfFileName } from "@/models/Beleg";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import Api from "@/Api";
import store from "@/store";

import { LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER } from "@/store/geschaeftspartner/actions.type";
import FileDownload from "js-file-download";

import page from "@/mixins/Page";
import ChangeWatcherNew from "@/mixins/ChangewatcherNew";
import UnsavedChangesModal from "@/components/Modals/UnsavedChangesModal.vue";
export default {
  name: "Geschaeftspartner",
  components: {
    HeadMenu,
    Navbar,
    Personalangaben,
    BerufUndAusbildung,
    Zusatzangaben,
    RechnungAdresse,
    KommunikationsAdresse,
    Kommunikationsangaben,
    Portalzugaenge,
    JournalEintragListe,
    BeziehungReiter,
    Kontaktreiter,
    Korrespondenzliste,
    Mailliste,
    Dokumentenliste,
    //Notenliste,
    BankverbindungReiter,
    QualifikationenReiter,
    SubventionenReiter,
    RechnungAusgangliste,
    LohnansatzListe,
    Anmeldungsliste,
    NotizenListe,
    TorListe,
    LoeschenModal,
    Praesenzjournalliste,
    KundenspezifischeFelder,
    PersonPendenzen,
    UnsavedChangesModal,
  },
  mixins: [page, ChangeWatcherNew],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
    rolleProp: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      editable: false,
      pdfFileName: "",
      pdfPath: "",
      pdfFiles: [],
      person: {
        //typ: { id: "17EF8844E6B", bezeichnung: "Person" },
        typ: {},
        firma: {},

        funktionsbereich: [],
        interesse: [],
        name: null,

        versand: [],
        anrede: { id: "174BF5D05F2", bezeichnung: "Formell" },
        aktivitaetsstatus: {
          id: "1737FA777AA",
          status: 0,
          bezeichnung: "Aktiv",
        },
        mitpersonmail: true,
      },
      kontaktkey: 0,
      mailkey: 0,
      anmeldungkey: 0,
      personalAngabenKey: 0,

      roles: {
        isStudent: false,
        isDozent: false,
        isMitarbeiter: false,
        isInteressent: false,
        isAlumni: false,
        isDiplomarbeiten: false,
        isReferent: false,
        isKursteilnehmer: false,
        isLieferant: false,
        isTraegerschaft: false,
        isTeilnehmerInfoabend: false,
        isArbeitgeber: false,
        isJobCoach: false,
        isPraxisbildner: false,
        isIVBerater: false,
        isNotfallkontakt: false,
        isAnstaltsdirektor: false,
        isNichtZugeordnet: false,
      },

      nummer: null,
      isAdmin: false,
      hatDozentenBenutzergruppe: false,

      rolle: this.rolleProp ? [this.rolleProp] : [],

      adressen: [],
      mailadressen: [],
      bankverbindungen: [],
      qualifikationen: [],

      dupletten: [],
      componentKey: 0,

      fromRoute: null,
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.person)
        return this.person.id ? `Person ${this.person.nummer}` : "Neue Person";
      else return "Neue Person";
    },

    rolleObj() {
      if (this.person.rolle && this.partnerrollen) {
        return this.partnerrollen.find((r) => r.id === this.person.rolle[0]);
      }

      return null;
    },

    partnerrollen: {
      get() {
        return this.$store.state.geschaeftspartner.partnerrollen;
      },
    },

    pdfFilename() {
      if (!this.selectedBeleg) return "";
      return pdfFileName(this.selectedBeleg);
    },
  },
  watch: {
    id: {
      handler: function (val) {
        if (val) {
          this.ladeGeschaeftspartner(val);
        } else this.editable = this.berechtigungen.m_geschaeftspartner.create;
      },
      immediate: true,
    },
    mailadressen: {
      handler(newVal, oldVal) {
        // Loop through the array to find the changed property
        const newHauptadresse = newVal.find((adresse) => adresse.hauptadresse);
        if (this.person.mitpersonmail)
          this.person.kommunikationsmail = newHauptadresse;
      },
      deep: true, // This will watch for changes in nested properties of the array elements
    },
    /*
    ["person.bild"]: {
      handler: function (val) {
        if (val) {
          this.personalAngabenKey++;
        }
      },
      immediate: true,
    },
    */
  },
  created() {
    this.fromRoute = this.$route.params.fromRoute;

    if (
      this.gruppen.find(
        (gruppe) =>
          gruppe.id == this.$CONST("GRUPPEN").INSTRUCTOR ||
          gruppe.id == "1774DD1D796" // Dozentengruppe
      )
    )
      this.hatDozentenBenutzergruppe = true;
  },
  mounted: function () {
    if (this.partnerrollen.length == 0)
      this.$store.dispatch(
        `geschaeftspartner/${LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER}`
      );

    this.isAdmin =
      this.gruppen.findIndex(
        (gruppe) => gruppe.id == this.$CONST("GRUPPEN").ADMIN
      ) == -1
        ? false
        : true;
  },
  methods: {
    abacusExport() {
      this.loading = true;
      Api.post(
        "export/xml/adresse/",
        {},
        {
          params: { id: this.id },
        }
      )
        .then((response) => {
          FileDownload(
            response.data,
            `Abacus-Adresse-Export_${this.person.vorname}_${this.person.name}.xml`
          );
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: "Adresse erfolgreich exportiert.",
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    maillisteUpdated() {
      this.mailkey++;
      this.anmeldungkey++;
      this.kontaktkey++;
    },
    mailAdresseHinzufuegen(mailadressen) {
      this.mailadressen = mailadressen;
    },
    computeRoles(rollen) {
      this.person.rolle = rollen;

      if (rollen) {
        let roles = {
          isStudent: false,
          isDozent: false,
          isMitarbeiter: false,
          isInteressent: false,
          isAlumni: false,
          isDiplomarbeiten: false,
          isReferent: false,
          isKursteilnehmer: false,
          isLieferant: false,
          isTraegerschaft: false,
          isTeilnehmerInfoabend: false,
          isArbeitgeber: false,
          isJobCoach: false,
          isPraxisbildner: false,
          isIVBerater: false,
          isNotfallkontakt: false,
          isAnstaltsdirektor: false,
          isNichtZugeordnet: false,
        };

        let SETUP_ROLLEN = this.$CONST("ROLLEN");

        rollen.forEach(function (selectedRole) {
          roles.isStudent =
            SETUP_ROLLEN.STUDENTEN.findIndex((role) => role == selectedRole) !=
            -1
              ? true
              : roles.isStudent;
          roles.isTeilnehmerInfoabend =
            SETUP_ROLLEN.TEILNEHMER_INFOEVENT.findIndex(
              (role) => role == selectedRole
            ) != -1
              ? true
              : roles.isTeilnehmerInfoabend;
          roles.isDozent =
            SETUP_ROLLEN.DOZENTEN.findIndex((role) => role == selectedRole) !=
            -1
              ? true
              : roles.isDozent;
          roles.isMitarbeiter =
            SETUP_ROLLEN.MITARBEITER.findIndex(
              (role) => role == selectedRole
            ) != -1
              ? true
              : roles.isMitarbeiter;
          roles.isInteressent =
            SETUP_ROLLEN.INTERESSENTEN.findIndex(
              (role) => role == selectedRole
            ) != -1
              ? true
              : roles.isInteressent;
          roles.isAlumni =
            SETUP_ROLLEN.ALUMNIS.findIndex((role) => role == selectedRole) != -1
              ? true
              : roles.isAlumni;
          roles.isKursteilnehmer =
            SETUP_ROLLEN.KURSTEILNEHMER.findIndex(
              (role) => role == selectedRole
            ) != -1
              ? true
              : roles.isKursteilnehmer;
          roles.isDiplomarbeiten =
            SETUP_ROLLEN.DIPLOMARBEIT.findIndex(
              (role) => role == selectedRole
            ) != -1
              ? true
              : roles.isDiplomarbeiten;
          roles.isTraegerschaft =
            SETUP_ROLLEN.TRAEGERSCHAFT.findIndex(
              (role) => role == selectedRole
            ) != -1
              ? true
              : roles.isTraegerschaft;
          roles.isKunde =
            SETUP_ROLLEN.KUNDEN.findIndex((role) => role == selectedRole) != -1
              ? true
              : roles.isKunde;
          roles.isLieferant =
            SETUP_ROLLEN.LIEFERANTEN.findIndex(
              (role) => role == selectedRole
            ) != -1
              ? true
              : roles.isLieferant;
          roles.isReferent =
            SETUP_ROLLEN.REFERENTEN.findIndex((role) => role == selectedRole) !=
            -1
              ? true
              : roles.isReferent;
          roles.isArbeitgeber =
            SETUP_ROLLEN.ARBEITGEBER.findIndex(
              (role) => role == selectedRole
            ) != -1
              ? true
              : roles.isArbeitgeber;
          roles.isJobCoach =
            SETUP_ROLLEN.JOBCOACH.findIndex((role) => role == selectedRole) !=
            -1
              ? true
              : roles.isJobCoach;
          roles.isPraxisbildner =
            SETUP_ROLLEN.PRAXISBILDNER.findIndex(
              (role) => role == selectedRole
            ) != -1
              ? true
              : roles.isPraxisbildner;
          roles.isIVBerater =
            SETUP_ROLLEN.IVBERATER.findIndex((role) => role == selectedRole) !=
            -1
              ? true
              : roles.isIVBerater;
          roles.isNotfallkontakt =
            SETUP_ROLLEN.NOTFALLKONTAKT.findIndex(
              (role) => role == selectedRole
            ) != -1
              ? true
              : roles.isNotfallkontakt;
          roles.isAnstaltsdirektor =
            SETUP_ROLLEN.ANSTALTSDIREKTOR.findIndex(
              (role) => role == selectedRole
            ) != -1
              ? true
              : roles.isAnstaltsdirektor;
          roles.isNichtZugeordnet =
            SETUP_ROLLEN.NICHTZUGEORDNET.findIndex(
              (role) => role == selectedRole
            ) != -1
              ? true
              : roles.isNichtZugeordnet;
        });

        this.roles = roles;
      }
    },

    commChanged(data) {
      this.person.telefon_m = data.m;
      this.person.telefon_p = data.p;
      this.person.telefon_g = data.g;
    },

    invoiceToChanged(data) {
      this.person.rechnungan = data.firma;
      this.person.rechnunganohneperson = data.ohnePerson;
    },

    commToChanged(data) {
      this.person.viafirma = data.firma;
      this.person.kommunikationsadresse = data.kommunikationsadresse;
      this.person.mitpersonmail = data.mitpersonmail;
      this.person.kommunikationsmail = data.kommunikationsmail;
    },

    kontaktegeaendert(data) {
      if (!data) return;
      this.mailadressen = data.mailadressen;
      this.adressen = data.adressen;

      //this.flagchanged();
    },

    bankverbindungeaendert(data) {
      if (!data) return;
      this.bankverbindungen = data.bankverbindungen;

      //this.flagchanged();
    },

    qualifikationenGeaendert(data) {
      if (!data) return;

      this.qualifikationen = data.qualifikationen;

      //this.flagchanged();
    },

    ladeGeschaeftspartner(id) {
      this.loading = true;

      Api.get("geschaeftspartner/", {
        params: { id: id },
      }).then((response) => {
        this.initializeGeschaeftspartner(response.data);
      });
    },

    neueMail() {
      let json = {
        empfaenger: [this.person],
        zeitpunkt: new Intl.DateTimeFormat("de-CH").format(new Date()),
      };

      Api.post("mail/", json, { params: {} }).then((response) => {
        this.$router.push({
          name: "mail",
          params: {
            id: response.data.id,
            anzeige: 0,
          },
        });
      });
    },
    neueKorrespondenz() {
      let json = {
        versand: {
          erstelltvon: this.angemeldeterMitarbeiter.person,
          datum: new Intl.DateTimeFormat("ch-DE").format(new Date()),
          betreff: "Neue Korrespondenz",
          unterschrift1: {
            person: { personName: this.$t("global.notlinked") },
          },
          unterschrift2: {
            person: { personName: this.$t("global.notlinked") },
          },
          inhalt: "",
        },
        empfaenger: [this.id],
        person: [this.id],
        //anmeldung: this.anmeldung,
      };

      Api.post("korrespondenz/", json).then((response) => {
        this.$router.push({
          name: "korrespondenz",
          params: {
            id: response.data.id,
            anzeige: 0,
          },
        });
      });
    },
    abbrechen() {
      this.$router.push({
        name: this.fromRoute || "personen-liste",
      });
    },

    neueAnmeldung() {
      this.$router.push({
        name: "anmeldung.person",
        params: {
          personid: this.id,
        },
      });
    },

    getBildUrl() {
      if (this.person.bild ? this.person.bild.includes("files") : false) {
        return this.$CONST("CONFIG").BASEURL + this.person.bild;
      } else {
        return null;
      }
    },

    initializeGeschaeftspartner(person) {
      if (person) {
        if (
          person.aktivitaetsstatus.id ==
          this.$CONST("AKTIVITAETSSTATI").ARCHIVIERT
        )
          this.editable = false;

        this.person = person;
        if (!this.mailadressen || this.mailadressen.length == 0) {
          this.mailadressen = person.mailadressen;
        }
        //this.person.personeninformation = person.personeninformation;
        this.person.bildUrl = this.getBildUrl();

        // if (person.personeninformation.nationalitaet != null && person.personeninformation != null) {
        this.person.isSchweizer =
          this.person?.nationalitaet?.id === this.$CONST("LAENDER").DEFAULT
            ? true
            : false;
        //}

        let roles = this.person.rolle.map((rolle) => {
          return rolle.id;
        });

        this.computeRoles(roles);
      } else {
        console.error("Geschäftspartner ist null");
      }

      this.editable = this.berechtigungen.m_geschaeftspartner.update;

      //this.initializeChangewatcher(["person"]);
      this.loading = false;
    },

    speichern(force) {
      if (this.loading) return;

      this.loading = true;

      var json = Object.assign({}, this.person);

      if (this.mailadressen != null)
        json.mailadressen = this.mailadressen.map((ma) => {
          return {
            id: ma.id,
            bezeichnung: ma.bezeichnung,
            mailtyp: ma.mailtyp?.id,
            delete: ma.delete,
            hauptadresse: ma.hauptadresse,
          };
        });

      if (this.adressen != null)
        json.adressen = this.adressen.map((a) => {
          return {
            id: a.id,
            adresse: a.adresse,
            adressverwendungstyp: a.adressverwendungen.map((adv) => adv.id),
            delete: a.delete,
          };
        });

      if (this.bankverbindungen != null)
        json.bankverbindungen = this.bankverbindungen.map((bv) => {
          return {
            id: bv.id,
            kontonummer: bv.kontonummer,
            bankverbindung: bv.bankverbindung,
            bc_nr: bv.bc_nr,
            iban: bv.iban,

            delete: bv.delete,
          };
        });

      if (this.qualifikationen != null) {
        json.qualifikationen = this.qualifikationen.map((q) => {
          return {
            id: q.id,
            qualifikationtyp: q.qualifikationtyp?.id,
            niveau: q.niveau?.id,
            person: this.id,
            ort: q.ort,
            datum: q.datum,
            bemerkung: q.bemerkung,
            delete: q.delete,
          };
        });
      }

      if (this.person.aktivitaetsstatus != null)
        json.aktivitaetsstatus = this.person.aktivitaetsstatus.id
          ? this.person.aktivitaetsstatus.id
          : this.person.aktivitaetsstatus;

      //if (this.person != null) {
      if (this.person.geschlecht != null)
        json.geschlecht = this.person.geschlecht.id
          ? this.person.geschlecht.id
          : this.person.geschlecht;

      if (this.person.angestellt_bei != null)
        json.angestellt_bei = this.person.angestellt_bei.id
          ? this.person.angestellt_bei.id
          : this.person.angestellt_bei;

      if (this.person.relationstyp != null)
        json.relationstyp = this.person.relationstyp.id
          ? this.person.relationstyp.id
          : this.person.relationstyp;

      if (this.person.relation_mit != null)
        json.relation_mit = this.person.relation_mit.id
          ? this.person.relation_mit.id
          : this.person.relation_mit;

      if (this.person.aufenthaltsbewilligung != null)
        json.aufenthaltsbewilligung = this.person.aufenthaltsbewilligung.id
          ? this.person.aufenthaltsbewilligung.id
          : this.person.aufenthaltsbewilligung;

      if (this.person.zivilstand != null)
        json.zivilstand = this.person.zivilstand.id
          ? this.person.zivilstand.id
          : this.person.zivilstand;

      if (this.person.anrede != null)
        json.anrede = this.person.anrede.id
          ? this.person.anrede.id
          : this.person.anrede;

      if (this.person.muttersprache != null)
        json.muttersprache = this.person.muttersprache.id
          ? this.person.muttersprache.id
          : this.person.muttersprache;

      if (this.person.nationalitaet != null)
        json.nationalitaet = this.person.nationalitaet.id
          ? this.person.nationalitaet.id
          : this.person.nationalitaet;

      if (this.person.akademischertitel != null)
        json.akademischertitel = this.person.akademischertitel.id
          ? this.person.akademischertitel.id
          : this.person.akademischertitel;

      if (this.person.anstellung != null)
        json.anstellung = this.person.anstellung.id
          ? this.person.anstellung.id
          : this.person.anstellung;

      if (this.person.kommunikationsadresse != null)
        json.kommunikationsadresse = this.person.kommunikationsadresse.id
          ? this.person.kommunikationsadresse.id
          : this.person.kommunikationsadresse;

      /*
      json.fach = this.person.fach.map((f) => {
        return f.id;
      });
      */

      if (this.person.funktionsbereich != null)
        json.funktionsbereich = this.person.funktionsbereich.map((f) => {
          return f.id ? f.id : f;
        });

      if (this.person.unterrichtssprache != null)
        json.unterrichtssprache = this.person.unterrichtssprache.map((u) => {
          return u.id ? u.id : u;
        });

      if (this.person.fach != null)
        json.fach = this.person.fach.map((f) => {
          return f.id ? f.id : f;
        });

      if (this.person.interesse != null)
        json.interesse = this.person.interesse.map((i) => {
          return i.id ? i.id : i;
        });
      // }
      if (this.person.firma != null) {
        if (this.person.firma.relationstyp != null)
          json.firma.relationstyp = this.person.firma.relationstyp.id
            ? this.person.firma.relationstyp.id
            : this.person.firma.relationstyp;

        if (this.person.firma.relation_mit != null)
          json.firma.relation_mit = this.person.firma.relation_mit.id
            ? this.person.firma.relation_mit.id
            : this.person.firma.relation_mit;
      }

      json.versand = this.person.versand.map((v) => {
        return v.id ? v.id : v;
      });

      this.dupletten = [];

      if (!this.id) {
        Api.post("geschaeftspartner/", json, {
          params: { force: force },
        })
          .then((response) => {
            this.initializeGeschaeftspartner(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.businesssavedsuccess"),
            });

            this.$router.replace({
              name: "geschaeftspartner",
              params: { id: response.data.id, anzeige: 0 },
            });
          })
          .catch((e) => {
            switch (e.response.status) {
              case 409:
                this.dupletten = e.response.data.dupletten;
                this.$refs.duplettenMsgBox.openModal();
                break;
              default:
                this.$notify(apiErrorToAlert(e));
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("geschaeftspartner/", json, {
          params: { id: this.person.id, force: force },
        })
          .then((response) => {
            this.initializeGeschaeftspartner(response.data);
            this.kontaktkey++;
            this.adressen = []; // Bewusst leer machen damit neue Adressen nicht dupliziert werden

            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.businesssavedsuccess"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    oeffneDuplette() {
      if (this.dupletten.length > 0) {
        let id = this.dupletten[0].id;
        let route = this.$router.resolve({
          name: "GeschaeftspartnerMitIdUndAnzeige",
          params: { id: id, anzeige: 0 },
        });
        let win = window.open(route.href, "_blank");
        win.focus();
      }
    },

    updateDokumentenliste() {
      this.componentKey++;
    },

    geschaeftspartnerLoeschen() {
      Api.delete("geschaeftspartner/", {
        data: [this.id],
      }).then(() => {
        this.$router.push({ name: "personen-liste" });
      });
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "geschaeftspartner-loeschen-modal");
    },
    carbone(category, templateID, endpoint) {
      CarboneApi.get(
        endpoint +
          this.id +
          "&category=" +
          category +
          "&templateId=" +
          templateID
      )
        .then((response) => {
          if (this.isDisplayableFile(response.data[0]?.url) === "isWordFile") {
            $;
            response.data.forEach((file) => {
              this.saveFile(
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                file.url,
                file.name,
                ".docx"
              );
            });
          } else if (
            this.isDisplayableFile(response.data[0]?.url) === "isExcelFile"
          ) {
            response.data.forEach((file) => {
              this.saveFile(
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                file.url,
                file.name,
                ".xlsx"
              );
            });
          } else {
            this.pdfFiles = response.data.map((item) => ({
              url: server.url + item.url,
              name: item.name,
              count: item.count,
              size: item.name,
            }));

            this.pdfFiles.length > 0
              ? this.$refs.pdfLayerAbklaerungsbericht.show()
              : this.$notify({
                  type: "warn",
                  title: this.$t("global.actionfailed"),
                  text: "Es ist ein Fehler aufgetreten",
                });
          }
        })
        .catch((error) => {
          console.error("Error fetching PDF files:", error);
        })
        .finally(() => {
          this.$refs.pdfLayerAbklaerungsbericht.loading = false;
        });
    },
    isDisplayableFile(url) {
      const fileExtension = this.getFileExtension(url);
      if (["docx", "doc"].includes(fileExtension)) {
        return "isWordFile";
      } else if (["xls", "xlsx", "xlsm"].includes(fileExtension)) {
        return "isExcelFile";
      }
      return "isDisplayable";
    },
    getFileExtension(url) {
      const parts = url?.split(".");
      return parts[parts.length - 1].toLowerCase();
    },
    async saveFile(contentType, url, fileName, type) {
      if (!contentType || !url) return;

      if (!fileName) fileName = "Report";
      fileName = fileName + type;

      const response = await fetch(Api.defaults.defaultURL + url, {
        headers: {
          "Content-Type": contentType,
        },
      });

      let file = await response.blob();
      FileDownload(file, fileName);
    },
  },
};
</script>

<style></style>
