<template>
  <div class="row">
    <div :class="[colClass]" class="block block-kopf">
      <span class="primary-headline-text">{{ headline }}</span>
    </div>
    <div class="pb-xl-1" :class="['col-xl-' + (12 - col)]">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContainerHeadline",
  props: {
    headline: {
      type: String,
      required: true,
    },
    col: {
      type: Number,
      required: false,
      default: 2,
    },
  },
  computed: {
    colClass: function () {
      if (this.col) {
        return "col-xl-" + this.col + " col-lg-" + this.col  ;
      } else {
        return "col-xl-2 col-lg-2";
      }
    },
  },
};
</script>

<style>
</style>
