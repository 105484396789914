<template>
  <div class="list">
    <notifications></notifications>
    <navbar :docsName="docsName" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu-filter>

            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.zahlungsnummer"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                        :tabIndex="1"
                      />
                      <label>{{ $t("global.paymentnum") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.saldoVon"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                        :tabIndex="5"
                      />
                      <label>{{ $t("global.openbalancefrom") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.saldoBis"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                        :tabIndex="6"
                      />
                      <label>{{ $t("global.openbalanceupto") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.verbuchungVon"
                        field="verbuchungVon"
                        @updatefull="setFilterDate"
                        :tabIndex="3"
                      />
                      <label>{{ $t("global.postingdatefrom") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.verbuchungBis"
                        field="verbuchungBis"
                        @updatefull="setFilterDate"
                        :tabIndex="4"
                      />
                      <label>{{ $t("global.postingdateuntil") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="filters.zahlungseingang"
                        :options="zahlungseingangstati"
                        :reduce="(t) => t.id"
                        label="bezeichnung"
                        :placeholder="$t('global.incomingpaymentstatus')"
                        :tabIndex="2"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.nostatusfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.incomingpaymentstatus") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.zahlungstatus"
                        :options="zahlungstati"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.paymentstatus')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.nostatusfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.paymentstatus") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.valutadatumVon"
                        field="valutadatumVon"
                        @updatefull="setFilterDate"
                        :tabIndex="3"
                      />
                      <label>{{ $t("global.valuedatefrom") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.valutadatumBis"
                        field="valutadatumBis"
                        @updatefull="setFilterDate"
                        :tabIndex="4"
                      />
                      <label>{{ $t("global.valuedateuntil") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.betragVon"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                        :tabIndex="5"
                      />
                      <label>{{ $t("global.amountfrom") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.betragBis"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                        :tabIndex="6"
                      />
                      <label>{{ $t("global.amountupto") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters['zahlschuld.firma.name']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                        :tabIndex="7"
                      />
                      <label>{{ $t("global.company") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters['zahlschuld.person.vorname']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                        :tabIndex="7"
                      />
                      <label>{{ $t("global.personfirstname") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters['zahlschuld.person.name']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                        :tabIndex="8"
                      />
                      <label>{{ $t("global.personsurname") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                        :tabIndex="9"
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.incomingpayment')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
              :gesamtanzahl="gesamtanzahl"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row mt-2 d-flex justify-content-between">
                  <div class="ml-4 mr-2">
                    <router-link
                      v-if="berechtigungen.m_finanzen.create"
                      class="btn btn-success mr-2"
                      :to="{ name: 'Zahlungseingang', params: { anzeige: 0 } }"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.manualpayment") }}
                    </router-link>
                    <button
                      v-if="berechtigungen.m_finanzen.update"
                      class="btn btn-primary mr-2"
                      @click="oeffneEsrUploadModal"
                    >
                      <font-awesome-icon
                        icon="fa-regular fa-arrow-alt-from-top"
                        class="mr-2"
                      />
                      {{ $t("global.importesrfile") }}
                    </button>
                    <button
                      v-if="berechtigungen.m_finanzen.update"
                      class="btn btn-primary mr-2"
                      @click="oeffneQrrUploadModal"
                    >
                      <font-awesome-icon
                        icon="fa-regular fa-arrow-alt-from-top"
                        class="mr-2"
                      />
                      {{ $t("global.readQRRfile") }}
                    </button>
                    <button
                      v-if="berechtigungen.m_finanzen.update"
                      class="btn btn-primary mr-2"
                      @click="zahlungseingangBuchen(selectedIds)"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-book"
                        class="mr-2"
                      />
                      {{ $t("global.book") }}
                    </button>
                    <button
                      class="btn btn-primary mr-2"
                      @click="exportToExcel(listData, 'Zahlungseingaenge')"
                    >
                      <span class="mr-2"
                        ><font-awesome-icon
                          icon="fa-regular fa-file-spreadsheet"
                      /></span>
                      {{ $t("global.export") }}
                    </button>
                  </div>
                  <div class="mr-4">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_finanzen.delete"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />
                      <span>{{ $t("global.delete") }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-compare="sortDates"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                    thead-tr-class="pointer"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td
                        class="no-pointer"
                        v-for="field in fields"
                        :key="field.key"
                      >
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelectedLocal(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <esr-upload-modal :id="EsrUploadModalId"></esr-upload-modal>
    <qrr-upload-modal :id="QrrUploadModalId"></qrr-upload-modal>
    <single-select-zahlungseingang
      :id="this.id"
      :betrag="betrag"
      @confirmed="loadTable"
      remove
    />
    <loeschen-modal
      id="zahlung-loeschen-modal"
      @confirmed="loeschen"
      :multiple="true"
    />

    <messagebox-count
      :headerText="$t('global.paymentbooked')"
      id="count-buchen-alert-msgbox"
      :ok="true"
      :cancel="false"
      :okText="$t('global.ok')"
      :cancelText="$t('global.cancel')"
      @ok="reload"
    />
    <messagebox-error
      :headerTitel="$t('global.paymentbookingfailed')"
      :errorText="$t('global.paymentbookingfailedmsg')"
      id="error-buchen-alert-msgbox"
      @ok="reload"
    />

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";

import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";
import excelExport from "@/mixins/ExcelExport";

import numeral from "numeral";

import MessageboxError from "@/components/Modals/MessageboxError.vue";
import DatePicker from "@/components/Datepicker";
import LoeschenModal from "@/components/Modals/LoeschenModal";

import { LADE_AUSWAHLWERTE_ZAHLUNGSEINGANG } from "@/store/zahlungseingang/actions.type";
import { LADE_AUSWAHLWERTE_DEBITOR } from "@/store/debitor/actions.type";

import EsrUploadModal from "../../components/Modals/EsrUploadModal.vue";
import QrrUploadModal from "../../components/Modals/QrrUploadModal.vue";
import SingleSelectZahlungseingang from "@/pages/Finanzen/Modals/SingleSelectZahlungseingang.vue";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

export default {
  name: "Zahlungen",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    EsrUploadModal,
    QrrUploadModal,
    SingleSelectZahlungseingang,
    DatePicker,
    LoeschenModal,
    MessageboxError,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.payments");
      },
    };
  },
  mixins: [page, dashboard, filter, excelExport],
  props: {},
  store,
  data() {
    return {
      id: null,
      betrag: 0,
      isDisabled: true,
      zahlungCount: 0,
      EsrUploadModalId: "esr-modal",
      QrrUploadModalId: "qrr-modal",
      SingleSelectZahlungseingangModalId: "single-select-modal",
      selectedObjects: [],
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "zahlungsnummer",
          sortable: true,
          label: this.$t("global.paymentnum"),
        },
        {
          key: "auftrag.auftragsnummer",
          sortable: true,
          searchable: true,
          label: this.$t("global.ordernum"),
          formatter: (value) => {
            if (value) {
              return value;
            } else {
              return "-";
            }
          },
        },
        {
          key: "zahlungseingangstatus.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.incomingpaymentstatus"),
        },
        {
          key: "zahlschuld.zahlungstatus.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.paymentstatus"),
          formatter: (value) => {
            if (value) {
              return value;
            } else {
              return "-";
            }
          },
        },
        {
          key: "valutadatum",
          sortable: true,
          label: this.$t("global.valuedate"),
        },
        {
          key: "verbucht",
          searchable: true,
          sortable: true,
          label: this.$t("global.postingdate"),
        },
        {
          key: "betrag",
          sortable: true,
          label: this.$t("global.amount"),
          formatter: (value) => {
            if (value) {
              return numeral(value).format("#,##0.00");
            } else {
              return "-";
            }
          },
          tdClass: "text-right",
        },
        {
          key: "zahlschuld.offener_saldo",
          searchable: true,
          sortable: true,
          label: this.$t("global.openbalance"),
          formatter: (value) => {
            if (value) {
              return numeral(value).format("#,##0.00");
            } else {
              return "-";
            }
          },
          tdClass: "text-right",
        },
        {
          key: "zahlschuld.firma.name",
          sortable: true,
          label: this.$t("global.company"),
        },
        {
          key: "zahlschuld.person.personName",
          sortable: true,
          label: this.$t("global.person"),
        },
        {
          key: "zahlschuld.rechnungan",
          searchable: false,
          sortable: true,
          label: this.$t("global.invoiceto"),
        },
      ];
    },
    queryParams: function () {
      var params = {};

      params.filterZahlungseingang = this.setQueryParamList(
        this.filters.zahlungseingang
      );
      params.filterZahlungstatus = this.setQueryParamList(
        this.filters.zahlungstatus
      );
      params.filterValutadatum = this.setQueryParam(this.filters.valutadatum);
      params.filterBetrag = this.setQueryParam(this.filters.betrag);
      //params.filterFirma = this.filters.firma;
      params.filterPerson = this.setQueryParam(
        this.filters["zahlschuld.person.personName"]
      );
      params.filterFirma = this.setQueryParam(
        this.filters["zahlschuld.firma.name"]
      );
      params.filterPersonName = this.setQueryParam(
        this.filters["zahlschuld.person.name"]
      );
      params.filterPersonVorname = this.setQueryParam(
        this.filters["zahlschuld.person.vorname"]
      );
      params.filterZahlungsNr = this.setQueryParam(this.filters.zahlungsnummer);
      params.filterAuftragNr = this.setQueryParam(
        this.filters["auftrag.nummer"]
      );
      params.filterVerbuchungsdatum = this.setQueryParam(this.filters.verbucht);
      params.filterVerbuchungVon = this.setQueryParam(
        this.filters.verbuchungVon
      );
      params.filterVerbuchungBis = this.setQueryParam(
        this.filters.verbuchungBis
      );
      params.filterValutadatumVon = this.setQueryParam(
        this.filters.valutadatumVon
      );
      params.filterValutadatumBis = this.setQueryParam(
        this.filters.valutadatumBis
      );
      params.filterBetragVon = this.setQueryParam(this.filters.betragVon);
      params.filterBetragBis = this.setQueryParam(this.filters.betragBis);
      params.filterSaldo = this.setQueryParam(
        this.filters["zahlschuld.offener_saldo"]
      );
      params.filterSaldoVon = this.setQueryParam(this.filters.saldoVon);
      params.filterSaldoBis = this.setQueryParam(this.filters.saldoBis);

      // TODO

      //params.filterAuftragNo = this.filters['auftrag.nummer']
      //params.filterZESBez = this.filters['zahlungseingangstatus.bezeichnung']
      //params.filterZSBez = this.filters['zahlschuld.zahlungstatus.bezeichnung']
      //params.filterRgAn = this.filters['zahlschuld.rechnung_an']

      if (this.filters.count != "") params.filterCount = this.filters.count;

      return params;
    },

    zahlungseingangstati: {
      get() {
        return this.$store.state.zahlungseingang.zahlungseingangstati;
      },
    },
    zahlungstati: {
      get() {
        return this.$store.state.debitor.zahlungstati;
      },
    },
  },

  created() {
    this.initFilter("zahlungen-liste", "finanzen/zahlungseingang/", true);
    this.resetAfterLanguageLoad();
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
    if (this.zahlungseingangstati.length == 0)
      this.$store.dispatch(
        `zahlungseingang/${LADE_AUSWAHLWERTE_ZAHLUNGSEINGANG}`
      );

    if (this.zahlungstati.length == 0)
      this.$store.dispatch(`debitor/${LADE_AUSWAHLWERTE_DEBITOR}`);
  },
  methods: {
    resetAfterLanguageLoad() {
      setTimeout(
        function () {
          if (this.$store.state.langtexts.loaded == true) {
            this.fields[1].label =
              this.$store.state.langtexts.texts[
                this.$i18n.locale
              ].global.paymentnum;

            this.fields[2].label =
              this.$store.state.langtexts.texts[
                this.$i18n.locale
              ].global.ordernum;

            this.fields[3].label =
              this.$store.state.langtexts.texts[
                this.$i18n.locale
              ].global.incomingpaymentstatus;

            this.fields[4].label =
              this.$store.state.langtexts.texts[
                this.$i18n.locale
              ].global.paymentstatus;

            this.fields[5].label =
              this.$store.state.langtexts.texts[
                this.$i18n.locale
              ].global.valuedate;

            this.fields[6].label =
              this.$store.state.langtexts.texts[
                this.$i18n.locale
              ].global.postingdate;

            this.fields[7].label =
              this.$store.state.langtexts.texts[
                this.$i18n.locale
              ].global.amount;

            this.fields[8].label =
              this.$store.state.langtexts.texts[
                this.$i18n.locale
              ].global.openbalance;

            this.fields[9].label =
              this.$store.state.langtexts.texts[
                this.$i18n.locale
              ].global.company;

            this.fields[10].label =
              this.$store.state.langtexts.texts[
                this.$i18n.locale
              ].global.person;

            this.fields[11].label =
              this.$store.state.langtexts.texts[
                this.$i18n.locale
              ].global.invoiceto;
          } else {
            this.resetAfterLanguageLoad();
          }
        }.bind(this),
        500
      );
    },
    zahlungseingangBuchen(selectedIds) {
      if (this.selectedIds.length > 0) {
        if (
          this.selectedObjects.find((obj) => obj.zahlschuld == null) ==
          undefined
        ) {
          this.loading = true;
          Api.post("finanzen/zahlungseingang/buchung/", {
            params: selectedIds,
          }).then((response) => {
            if (response.data.errors.length < 1) {
              this.resetLoading(this);
              this.$notify({
                type: "success",
                title: this.$t("global.actionsuccessful"),
                text: "Zahlungseingänge erfolgreich verbucht",
              });
              this.$bus.$emit("openMessageBoxCount", {
                id: "count-buchen-alert-msgbox",
                successText: this.$t("global.successful") + ": ",
                successCount: response.data.success.length,
              });
            } else {
              this.$notify({
                type: "warn",
                title: this.$t("global.actioncanceled"),
                text: "Fehler beim Buchen der Zahlungseingänge",
              });
              this.$bus.$emit("openMessageboxError", {
                data: response.data.errors,
                id: "error-buchen-alert-msgbox",
              });
            }
            this.selectedIds = [];
            this.loading = false;
          });
        } else {
          this.$notify({
            type: "warn",
            title: this.$t("global.actioncanceled"),
            text: "mindestens ein Zahlungseingang war nicht verknüpft mit einem Debitor",
          });
        }
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actioncanceled"),
          text: "Keine Zahlungseingänge ausgewählt",
        });
      }
    },

    details(zahlung) {
      this.$router.push({
        name: "Zahlungseingang",
        params: { id: zahlung.id, anzeige: 0 },
      });
    },

    loeschen() {
      Api.delete("finanzen/zahlungseingang/", { data: this.selectedIds }).then(
        (response) => {
          this.listData = this.listData.filter(
            (v) => !response.data.success.includes(v.id)
          );
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: "Zahlung(en) erfolgreich gelöscht",
          });
        }
      );
    },

    loadTable() {
      this.resetLoading(this);
    },

    oeffneZahlungseingang() {
      if (this.id) {
        this.$router.push({
          path: "/finanzen/Zahlungseingang/" + this.id,
        });
      }
    },

    oeffneEsrUploadModal() {
      $("#esr-modal").modal("show");
    },

    oeffneQrrUploadModal() {
      $("#qrr-modal").modal("show");
    },

    schliesseEsrUploadModal() {
      $("#esr-modal").modal("hide");
      this.resetLoading(this);
      this.$notify({
        type: "success",
        title: this.$t("global.actionsuccessful"),
        text: "ESR-File erfolgreich eingelesen",
      });
    },

    schliesseQrrUploadModal() {
      $("#qrr-modal").modal("hide");
      this.resetLoading(this);
      this.$notify({
        type: "success",
        title: this.$t("global.actionsuccessful"),
        text: "QRR-File erfolgreich eingelesen",
      });
    },

    oeffneSingleSelectModal() {
      if (this.id) {
        $("#zahlungseingang-zuweisen-modal").modal("show");
        this.$bus.$emit("zahlungseingang-zuweisen-modal", null);
      } else {
        this.$notify({
          type: "warn",
          title: "Aktion nicht durchführbar",
          text: "Bitte ein Zahlungseingang wählen",
        });
      }
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "zahlung-loeschen-modal");
    },

    onRowSelectedLocal(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
        this.selectedObjects.push(row.item);
        this.selectedIds = Array.from(new Set(this.selectedIds));
        this.selectedObjects = Array.from(new Set(this.selectedObjects));
        if (
          this.selectedIds.length != 0 &&
          this.selectedObjects.find(
            (obj) => obj.zahlungseingangstatus.bezeichnung == "Verbucht"
          ) == undefined
        ) {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
        this.anzahlDaten = this.selectedIds.length;
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
        this.selectedObjects = this.selectedObjects.filter(
          (object) => object != row.item
        );
        if (
          this.selectedIds.length != 0 &&
          this.selectedObjects.find(
            (obj) => obj.zahlungseingangstatus.bezeichnung == "Verbucht"
          ) == undefined
        ) {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
        this.anzahlDaten = this.selectedIds.length;
      }
    },
    reload() {
      this.resetLoading(this);
    },
  },
};
</script>

<style></style>
