<template>
  <div :id="id" class="modal fade">
    <div 
      class="modal-dialog modal-xl"
    >
      <div class="modal-content">
        <ValidationObserver v-slot="{ invalid }" :disabled="true">
          <div class="modal-header">
            <modal-close-button @confirmed="closeModal" />
            <h4 class="modal-title">
              {{ $t("global.gradeoverview") }}
            </h4>
          </div>
          <div class="modal-body table-wrapper">
            <div v-if="!configurationOn" class="p-4">
              <div class="txtTrsf mb-5">
                {{ $t("global.hereyoucangenerateandpublishthegradesoverview") }}
                <br/>{{ $t("global.documentpublicationcancanbemanagedlater") }}
                <br/><br/>
                <div class="row">
                  <div class="col-1 pr-0">
                    <font-awesome-icon
                      icon="fa-solid fa-circle-info"
                      size="sm"
                    />
                  </div>
                  <div class="col-10 pl-0">
                    {{ $t("global.youcanconfigurethegradesoverview") }}<br>
                  </div>
                </div>
              </div>
              <div class="row ml-2 d-flex justify-content-between align-items-center">
                <div class="col-3">
                  <b-button
                    class="btn btnTrsf"
                    variant="outline-primary"
                    @click="generateNotenuebersichten(null)"
                  >
                    {{ $t("global.showgradesoverview") }}
                    <font-awesome-icon
                      v-if="configurationAvailable"
                      class="btnConfig ml-1"
                      icon="fa-regular fa-sliders-simple"
                      size="sm"
                    />
                  </b-button>
                </div>
                <div class="col-3">
                  <b-button
                    class="btn btnTrsf"
                    variant="outline-primary"
                    @click="generateNotenuebersichten(false)"
                  >
                    {{ $t("global.assigngradesoverview") }}
                    <font-awesome-icon
                      v-if="configurationAvailable"
                      class="btnConfig ml-1"
                      icon="fa-regular fa-sliders-simple"
                      size="sm"
                    />
                  </b-button>
                </div>
                <div class="col-4">
                  <b-button
                    class="btn btnTrsf"
                    variant="outline-primary"
                    @click="generateNotenuebersichten(true)"
                  >
                    {{ $t("global.assignandpublishgradesoverview") }}
                    <font-awesome-icon
                      v-if="configurationAvailable"
                      class="btnConfig ml-1"
                      icon="fa-regular fa-sliders-simple"
                      size="sm"
                    />
                  </b-button>
                </div>
                <div class="col-1 ms-auto">
                  <b-button
                    class="btnTrsf px-0"
                    variant="outline-primary"
                    @click="setConfigurationOn(true)"
                    v-tooltip.hover="{ text: $t('global.cofiguregradesoverview') }"
                  >
                    <font-awesome-icon
                      icon="fa-regular fa-sliders-simple"
                      size="lg"
                    />
                  </b-button>
                </div>
              </div>
            </div>
            <div v-if="configurationOn">
              <div class="row">
                <div class="col-6">
                  <button
                    class="btn btnBack"
                    @click="setConfigurationOn(false)"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-arrow-left"
                      size="lg"
                    />
                    <span> {{ $t("global.back") }}</span>
                  </button>
                </div>
                <div class="col-6">
                    <b-button
                      class="float-right mr-1"
                      variant="outline-primary"
                      @click="switchSetAll()"
                    >
                      <span> {{ setAll ? $t("global.deselectall") : $t("global.selectall") }}</span>
                    </b-button>
                </div>
              </div>
              <div
                v-for="(klasse, index) in notenkonfig.klassenliste"
                :key="index"
              >
                <container-headline
                  class="col-12"
                  style="margin-left: -0.9rem; margin-bottom: -0.5rem;"
                  :headline="klasse.bezeichnung"
                  :col="3"
                ></container-headline>
                <div class="row px-3">
                  <div class="col-12 block belegliste mb-3">
                    <b-table
                      hover
                      responsive
                      sticky-header
                      show-empty
                      :items="klasse.faecherliste"
                      :fields="fields"
                      :busy="isBusy"
                      tbody-tr-class="item"
                      ref="selectableTable"
                      small
                    >
                      <template v-slot:cell(anzeigen)="row">
                        <b-form-checkbox
                          v-model="row.item.anzeigen"
                          @input="setChildRows(row.item)"
                        ></b-form-checkbox>
                      </template>
                      <template #cell(details)="row">
                        <b-button
                          @click="row.toggleDetails"
                          class="m-0"
                          variant="outline-primary"
                          size="sm"
                        >
                          <font-awesome-icon
                            :icon="
                              row.detailsShowing
                                ? 'fa-chevron-up'
                                : 'fa-chevron-down'
                            "
                          />
                        </b-button>
                      </template>
                      <template #row-details="row">
                        <div class="my-1 px-2">
                          <b-table
                            thead-class="z"
                            tbody-tr-class="item"
                            small
                            responsive
                            sticky-header
                            :items="row.item.pruefungsliste"
                            :fields="detailFields"
                            :busy="isBusy"
                            show-empty
                            sort-icon-left
                            selectable
                            select-mode="range"
                            thead-tr-class="no-bg"
                          >
                            <template v-slot:cell(anzeigen)="row">
                              <b-form-checkbox
                                v-model="row.item.anzeigen"
                                :disabled="row.item.fachAnzeigen === false "
                              ></b-form-checkbox>
                            </template>
                            <template #table-busy>
                              <div class="text-center text-primary my-2">
                                <b-spinner class="align-middle"></b-spinner>
                              </div>
                            </template>

                            <template #empty>
                              <div
                                v-if="ladeFehler"
                                class="text-center text-danger my-2"
                              >
                                <strong>{{
                                  $t("global.errorwhileloading")
                                }}</strong>
                              </div>
                              <div
                                v-if="!ladeFehler"
                                class="text-center text-primary my-2"
                              >
                                <strong>{{
                                  $t("global.nodataavailable")
                                }}</strong>
                              </div>
                            </template>
                          </b-table>
                        </div>
                      </template>
                      <template #table-busy>
                        <div class="text-center text-primary my-2">
                          <b-spinner class="align-middle"></b-spinner>
                        </div>
                      </template>
                      <template #empty>
                        <div
                          v-if="ladeFehler"
                          class="text-center text-danger my-2"
                        >
                          <strong>{{ $t("global.errorwhileloading") }}</strong>
                        </div>
                        <div
                          v-if="!ladeFehler"
                          class="text-center text-primary my-2"
                        >
                          <strong>{{ $t("global.nodataavailable") }}</strong>
                        </div>
                      </template>
                    </b-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              v-if="configurationOn"
              type="button"
              class="btn btn-primary"
              :disabled="invalid"
              @click="saveConfig"
            >
              {{ $t("global.save") }}
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
    <pdf-layer
      id="pdf-layer-notenuebersicht"
      ref="pdfLayerNotenuebersicht"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
      :files="pdfFiles"
    ></pdf-layer>
    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>
  
  <script>
import Api from "@/Api";
import AppApi from "@/AppApi";
import CarboneApi from "@/CarboneApi";
import server from "@/server";
import page from "@/mixins/Page";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import ModalCloseButton from "@/components/global/ModalCloseButton.vue";
import ContainerHeadline from "@/components/ContainerHeadline";


export default {
  name: "KonfigNotenuebersichtModal",
  components: {
    ModalCloseButton,
    ContainerHeadline
  },
  mixins: [page],
  props: {
    id: {
      type: String,
      required: true,
    },
    lehrgangId: {
      type: String,
      required: false,
    },
    veranstaltungsId: {
      type: String,
      required: false,
    },
    editable: {
      type: Boolean,
    },
  },
  data() {
    return {
      notenkonfig: {},
      configurationAvailable: false,
      setAll: false,
      isBusy: false,
      shown: false,
      lehrgang: { bezeichnung: this.$t("global.notlinked") },
      configurationOn: false,
      pdfPath: "",
      pdfFiles: [],
    };
  },
  computed: {
    fields() {
      return [
        {
          key: "bezeichnung",
          label: this.$t("global.dashboard_subjects"),
        },
        {
          key: "anzeigen",
          label: this.$t("global.Selected"),
          thStyle: "width: 20%;",
          class: "text-center",
        },
        {
          key: "details",
          label: "",
          thStyle: "width: 10%;",
        },
      ];
    },
    detailFields() {
      return [
        {
          key: "bezeichnung",
          label: this.$t("global.exams"),
        },
        {
          key: "anzeigen",
          label: this.$t("global.Selected"),
          thStyle: "width: 20%;",
          class: "text-center",
        },
      ];
    },
  },
  watch: {},
  mounted() {
    this.$bus.$on("openKonfigModal", (data) => {
      if (!data || data.length == 0) return;
      this.lehrgang = data;

      if (!this.shown) this.openModal();
    });
  },
  methods: {
    openModal() {
      $("#" + this.id).modal({
        backdrop: "static",
        keyboard: false,
      });
      this.getConfigData()
    },

    closeModal() {
      //this.leereEingaben();
      this.configurationAvailable = false;
      this.configurationOn = false;

      this.shown = false;
      $("#" + this.id).modal("hide");
    },

    getConfigData() {
      if (this.lehrgangId || this.veranstaltungsId) {
        this.loading = true;

        AppApi.get("noten-konfig/", {
          params: {
            lehrgangId: this.lehrgangId,
            veranstaltungsId: this.veranstaltungsId
          }
        }).then((response) => {
          this.notenkonfig = response.data

          if (this.notenkonfig.id != null) this.configurationAvailable = true
          else this.configurationAvailable = false
        }).catch((e) => {
          this.$notify(apiErrorToAlert(e));
        }).finally(() => {
          this.loading = false;
        });
      } else this.console.log("keine ID vorhanden")
    },

    setChildRows(value) {
      value.pruefungsliste.forEach((pruefung) => {
        pruefung.anzeigen = value.anzeigen;
        pruefung.fachAnzeigen = value.anzeigen;
      });
    },

    saveConfig() {
      if (this.lehrgangId || this.veranstaltungsId) {
        this.loading = true;

        var json = this.notenkonfig

        if (this.notenkonfig.id == null) {
          AppApi.post("noten-konfig/", json)
          .then((response) => {
            this.notenkonfig = response.data

            if (this.notenkonfig.id != null) this.configurationAvailable = true
            else this.configurationAvailable = false
          }).catch((e) => {
            this.$notify(apiErrorToAlert(e));
          }).finally(() => {
            this.loading = false;
          });
        } else {
          AppApi.put("noten-konfig/", json)
          .then((response) => {
            this.notenkonfig = response.data

            if (this.notenkonfig.id != null) this.configurationAvailable = true
            else this.configurationAvailable = false
          }).catch((e) => {
            this.$notify(apiErrorToAlert(e));
          }).finally(() => {
            this.loading = false;
          });
        }
        
      } else this.console.log("keine ID vorhanden")

        this.configurationAvailable = true;
        this.configurationOn = false
    },

    generateNotenuebersichten(publish) {
        //TODO: carbone GET evtl. noch anpassen
      CarboneApi.get("carbone-notenuebersicht/", {
        params: {
          lehrgangId: this.lehrgangId,
          veranstaltungsId: this.veranstaltungsId,
          publish: publish,
          config: this.notenkonfig.id,
        },
      }).then((response) => {
        this.pdfFiles = response.data.map((item) => ({
          url: server.url + item.url,
          name: item.name,
          count: item.count,
          size: item.name,
        }));

        this.pdfFiles.length > 0
          ? this.$refs.pdfLayerNotenuebersicht.show()
          : this.$notify({
              type: "warn",
              title: this.$t("global.actionfailed"),
              text: "Es ist ein Fehler aufgetreten",
            });
        })
        .catch((error) => {
          console.error("Error fetching PDF files:", error);
        })
        .finally(() => {
          this.$refs.pdfLayerNotenuebersicht.loading = false;
        });
    },

    switchSetAll() {
        this.setAll ? this.setAll = false : this.setAll = true;

        if (this.setAll) {
          this.notenkonfig.klassenliste.forEach(klasse => {
            klasse.anzeigen = true;
            klasse.faecherliste.forEach(fach => {
                fach.anzeigen = true;
                this.setChildRows(fach)
            });
          })
            
        } else {
          this.notenkonfig.klassenliste.forEach(klasse => {
            klasse.anzeigen = false;
            klasse.faecherliste.forEach(fach => {
                fach.anzeigen = false;
                this.setChildRows(fach)
            });
          })
        }
    },

    setConfigurationOn(value) {
      this.configurationOn = value;
    },
  },
};
</script>
  
<style></style>
  