<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.registrations') + ' (' + anmeldungCount + ')'"
        :col="3"
      >
        <div class="row mt-2 d-flex justify-content-between">
          <div class="ml-4">
            <button
              class="btn btn-success mr-2"
              @click="neueAnmeldung"
              v-if="editable"
            >
              <font-awesome-icon icon="fa-duotone fa-ticket" class="mr-2" />
              {{ $t("global.newregistration") }}
            </button>
            <button
              v-if="showUebertragen && editable"
              class="btn btn-primary mr-2"
              @click="showAnmeldungUebertragen"
            >
              <font-awesome-icon icon="fa-solid fa-sign-out-alt" class="mr-2" />
              {{ $t("global.transfer") }}
            </button>

            <button
              v-if="showRechnung && editable"
              class="btn btn-primary mr-2"
              @click="oeffneRechnungErstellenModal"
            >
              <font-awesome-icon
                icon="fa-duotone fa-file-invoice-dollar"
                class="mr-2"
              />
              {{ $t("global.createinvoice") }}
            </button>

            <b-dropdown variant="primary mr-2" v-if="veranstaltung">
              <template slot="button-content">
                <font-awesome-icon icon="fa-duotone fa-print" class="mr-2" />
                <em>{{ $t("global.output") }}</em>
              </template>
              <b-dropdown-item
                @click="
                  openSpracheWaehlenModal('teilnehmerliste-ohne-foto-modal')
                "
              >
                {{ $t("global.courseparticipantslistnopic") }}
              </b-dropdown-item>
              <b-dropdown-item
                @click="
                  openSpracheWaehlenModal('teilnehmerliste-mit-foto-modal')
                "
              >
                {{ $t("global.courseparticipantslistwithpic") }}
              </b-dropdown-item>
              <b-dropdown-item
                @click="
                  openSpracheWaehlenModal(
                    'teilnehmerliste-mit-foto-ohne-kontakt-modal'
                  )
                "
              >
                {{ $t("global.courseparticipantslistwithpicwithoutcontact") }}
              </b-dropdown-item>
              <b-dropdown-item @click="druckeTischkarten">
                {{ $t("global.placecards") }}
              </b-dropdown-item>
              <b-dropdown-item @click="druckeAdressEtiketten">
                {{ $t("global.addresslabels") }}
              </b-dropdown-item>
              <b-dropdown-item @click="druckeNamensschilder">
                {{ $t("global.nametags") }}
              </b-dropdown-item>
              <b-dropdown-item
                v-if="showZertifikatButton"
                @click="showZertifikatPdfModal"
              >
                Zertifikate
              </b-dropdown-item>
              <!--Carbone-->
              <b-dropdown-item
                v-for="carboneprint in validCarbonePrintouts(
                  $CONST('CARBONEPRINTOUTS')
                )"
                :key="carboneprint.id"
                @click="
                  carbone(
                    carboneprint.category.bezeichnung,
                    carboneprint.template,
                    'carbone-teilnehmerbeurteilung/?ids='
                  )
                "
              >
                {{ carboneprint.value }}
              </b-dropdown-item>
              <!--Ende Carbone-->
            </b-dropdown>

            <b-dropdown v-if="editable" variant="primary">
              <template slot="button-content">
                <font-awesome-icon icon="fa-duotone fa-print" class="mr-2" />
                {{ $t("global.communication") }}
              </template>
              <b-dropdown-item @click="neueMail">
                <font-awesome-icon
                  icon="fa-duotone fa-envelope-open-text"
                  class="mr-2"
                />
                {{ $t("global.sendemail") }}
              </b-dropdown-item>
              <b-dropdown-item @click="neueKorrespondenz">
                <font-awesome-icon
                  icon="fa-duotone fa-typewriter"
                  class="mr-2"
                />
                {{ $t("global.createletter") }}
              </b-dropdown-item>
              <b-dropdown-item @click="massenmailErstellen" v-if="!person">
                <font-awesome-icon icon="fa-regular fa-envelope" class="mr-2" />
                {{ $t("global.createmassmail") }}
              </b-dropdown-item>
              <b-dropdown-item
                @click="massenkorrespondenzErstellen"
                v-if="!person"
              >
                <font-awesome-icon
                  icon="fa-duotone fa-typewriter"
                  class="mr-2"
                />
                {{ $t("global.createserialletter") }}
              </b-dropdown-item>
            </b-dropdown>
            <!-- massenmutation bildungsprotfolio -->
            <massenmutation-button
              class="mr-2"
              style="display: inline-flex"
              :customAddition="
                lehrgang != null
                  ? veranstaltungSelection
                      .filter((v) => v.selected)
                      .map((e) => e.id)
                  : null
              "
              :selectedIds="this.selectedIds"
              :propEntities="propEntities"
              :modalId="massmutationModalId"
              @mutated="getAnmeldungen()"
            >
              <!-- Named slot content for the modal -->
              <template #preModalContent>
                <div class="modal-body" style="margin-bottom: -30px">
                  <div>
                    <p class="mb-2">
                      {{
                        $t(
                          "global.wouldyouliketoupdatethechosenregistrationsforfurtherevents"
                        )
                      }}
                    </p>
                    <button
                      type="button"
                      class="btn btn-sm btn-primary mb-4"
                      @click="showEvents = !showEvents"
                    >
                      {{
                        !showEvents
                          ? $t("global.showevents")
                          : $t("global.hideevents")
                      }}
                    </button>
                  </div>
                  <div class="row ml-0 mr-0 mb-1" v-if="showEvents">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-12">
                          <container-headline
                            :headline="$t('global.events')"
                            :col="6"
                          ></container-headline>
                          <div class="row mb-3">
                            <div class="col-xl-12 block br-t-l-0">
                              <div class="eingabe-panel-kopf p-3">
                                <button
                                  type="button"
                                  class="btn btn-sm btn-primary mb-2"
                                  @click="selectAllEvents"
                                >
                                  {{ $t("global.selectall") }}
                                </button>
                                <div
                                  class="row"
                                  v-if="veranstaltungSelection.length > 0"
                                >
                                  <div class="col-xl-12">
                                    <div class="form-group">
                                      <label>{{ $t("global.classes") }}</label>
                                      <div
                                        v-for="(
                                          item, index
                                        ) in veranstaltungSelection"
                                        :key="'klasse-' + index"
                                      >
                                        <b-form-checkbox
                                          v-if="
                                            item.veranstaltungstyp.istklasse
                                          "
                                          v-model="item.selected"
                                          class="form-checkbox-input"
                                        >
                                          {{ item.kuerzel }}
                                          {{ item.bezeichnung }}
                                        </b-form-checkbox>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="row"
                                  v-if="veranstaltungSelection.length > 0"
                                >
                                  <div class="col-xl-12">
                                    <div class="form-group">
                                      <label>{{ $t("global.subjects") }}</label>
                                      <div
                                        v-for="(
                                          item, index
                                        ) in veranstaltungSelection"
                                        :key="'fach-' + index"
                                      >
                                        <b-form-checkbox
                                          v-if="
                                            !item.veranstaltungstyp.istklasse
                                          "
                                          v-model="item.selected"
                                          class="form-checkbox-input"
                                        >
                                          {{ item.kuerzel }}
                                          {{ item.bezeichnung }}
                                        </b-form-checkbox>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </massenmutation-button>
          </div>
          <div class="mr-4">
            <button
              class="btn btn-danger"
              @click="oeffneLoeschenModal"
              v-if="editable"
            >
              <font-awesome-icon class="mr-1" icon="fa-duotone fa-trash" />
              <span>{{ $t("global.delete") }}</span>
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <div>
              <b-table
                ref="selectableTable"
                tbody-tr-class="item"
                style="max-height: 540px"
                responsive
                sticky-header
                :items="anmeldungen"
                :fields="computedFields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-compare="sortDates"
                :busy="isBusy"
                show-empty
                sort-icon-left
                selectable
                select-mode="range"
                @row-dblclicked="details"
                thead-tr-class="pointer"
              >
                <template v-slot:head(selected)="row">
                  <b-form-checkbox
                    v-model="row.selected"
                    @input="selectAllRows(row)"
                  ></b-form-checkbox>
                </template>

                <template v-slot:cell(selected)="row">
                  <b-form-checkbox
                    v-model="row.item.selected"
                    @input="onRowSelected(row)"
                  ></b-form-checkbox>
                </template>

                <template v-slot:cell(anmeldungsdatum)="row">
                  <div v-if="row.item.anmeldestatus.id != '174E40DBF2A'">
                    {{ row.item.anmeldungsdatum || row.item.warteliste || "-" }}
                  </div>
                  <div v-else>
                    {{ row.item.anmeldungsdatum + " - " + row.item.abgemeldet }}
                  </div>
                </template>

                <template v-slot:cell(nicht_subventioniert)="row">
                  <div v-if="row.item.nicht_subventioniert === true">
                    <font-awesome-icon icon="fa-solid fa-check" />
                  </div>
                  <!-- <div v-if="row.item.nicht_subventioniert == false">
                    <font-awesome-icon icon="fa-regular fa-xmark" />
                  </div> -->
                </template>

                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>

                <template #empty>
                  <div v-if="ladeFehler" class="text-center text-danger my-2">
                    <strong>{{ $t("global.errorwhileloading") }}</strong>
                  </div>
                  <div v-if="!ladeFehler" class="text-center text-primary my-2">
                    <strong>{{ $t("global.nodataavailable") }}</strong>
                  </div>
                </template>

                <template v-slot:cell(veranstaltungstypBezeichnung)="row">
                  <div
                    v-if="row.item.veranstaltung"
                    :style="veranstaltungstypHintergrundfarbe(row.item)"
                  >
                    {{
                      row.item.veranstaltung.veranstaltungstyp
                        ? row.item.veranstaltung.veranstaltungstyp.bezeichnung
                        : "-"
                    }}
                  </div>
                </template>

                <template v-slot:cell(anmeldetypBezeichnung)="row">
                  <div :style="anmeldetypHintergrundfarbe(row.item)">
                    {{ row.item.anmeldetyp.bezeichnung }}
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="anmeldungen-loeschen-modal"
      @confirmed="anmeldungenLoeschen"
      :selectedIds="selectedIds"
    />

    <anmeldung-uebertragen-modal
      :id="AnmeldungUebertragenModalId"
      :anmeldungen="selectedIds"
      :filterTyp="filterTyp"
      :allowCopy="true"
      @copied="anmeldungGeaendert"
      @changed="anmeldungGeaendert"
    ></anmeldung-uebertragen-modal>

    <rechnung-erstellen-modal
      id="rechnung-erstellen-modal"
      ref="rechnungErstellenModal"
      :anmeldungen="selectedIds"
      :veranstaltung="veranstaltung"
      :angemeldeterMitarbeiter="angemeldeterMitarbeiter"
      @changed="getAnmeldungen"
    />

    <pdf-layer
      id="pdf-layer-anmeldung-reiter"
      ref="pdfLayerAnmeldungReiter"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
      :files="pdfFiles"
    ></pdf-layer>
    <loading-overlay v-show="loading"></loading-overlay>
    <zertifikat-pdf-modal
      id="zertifikat-pdf-modal"
      :ausgabeZertifikate="ausgabeZertifikate"
      @dateChanged="updateDate"
    />
    <sprache-waehlen-modal
      :id="'teilnehmerliste-ohne-foto-modal'"
      :headerText="$t('global.configureparticipantlist')"
      :okText="$t('global.viewpdf')"
      :selectDate="false"
      :languages="['DE', 'FR']"
      @ok="openParticipantsListNoPic"
    />
    <sprache-waehlen-modal
      :id="'teilnehmerliste-mit-foto-modal'"
      :headerText="$t('global.configureparticipantlist')"
      :okText="$t('global.viewpdf')"
      :selectDate="false"
      :languages="['DE', 'FR']"
      @ok="openParticipantsListWithPic"
    />
    <sprache-waehlen-modal
      :id="'teilnehmerliste-mit-foto-ohne-kontakt-modal'"
      :headerText="$t('global.configureparticipantlist')"
      :okText="$t('global.viewpdf')"
      :selectDate="false"
      :languages="['DE', 'FR']"
      @ok="openParticipantsListWithPicWithoutContact"
    />
    <datum-pdf-modal
      id="ameldungsreiter-datum-pdf-modal"
      @ok="generiereCarboneReport"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import CarboneApi from "@/CarboneApi";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";

import RechnungErstellenModal from "@/components/Rechnungslegung/Modals/RechnungErstellenModal";
import AnmeldungUebertragenModal from "@/components/Modals/AnmeldungUebertragenModal";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import MassenmutationButton from "@/components/Buttons/MassenmutationButton";
import SpracheWaehlenModal from "@/components/Modals/SpracheWaehlenModal.vue";
import FileDownload from "js-file-download";
import DatumPdfModal from "@/components/Modals/DatumPdfModal.vue";
import ZertifikatPdfModal from "../Modals/ZertifikatPDFModal.vue";

import reiter from "@/mixins/Reiter";
import dashboard from "@/mixins/Dashboard";
import page from "@/mixins/Page";
import server from "@/server";
import { LADE_AUSWAHLWERTE_ANMELDUNG } from "@/store/anmeldung/actions.type";

export default {
  name: "Anmeldungsliste",
  components: {
    ContainerHeadline,
    AnmeldungUebertragenModal,
    RechnungErstellenModal,
    LoeschenModal,
    BSpinner,
    ZertifikatPdfModal,
    MassenmutationButton,
    SpracheWaehlenModal,
    DatumPdfModal,
  },
  mixins: [reiter, page, dashboard],
  props: {
    veranstaltung: {
      type: String,
      required: false,
    },
    klassenkuerzel: {
      type: String,
      required: false,
    },
    showUebertragen: {
      type: Boolean,
      default: true,
    },
    showRechnung: {
      type: Boolean,
      default: true,
    },
    person: {
      type: String,
      required: false,
    },
    filterTyp: {
      type: String,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    mailadressen: {
      type: Array,
      required: false,
    },
    lehrgang: {
      type: Object,
      required: false,
      default: null,
    },
    semester: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      showZertifikatButton: false,
      page: 0,
      anzahlProPage: 200,
      sortBy: "veranstaltung.kuerzel",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      kategorieProp: this.kategorieProp,
      modus: null,
      modi: [],
      zielklasse: null,
      zielklassen: [],
      status: null,
      anmeldestatus: [],
      anmeldungen: [],
      verrechnungPosKey: 0,
      anmeldungCount: 0,
      //selected: [],
      selectedIds: [],
      AnmeldungUebertragenModalId: "anmeldung-uebertragen-modal",
      pdfPath: "",
      anmeldungObj: {},
      date: new Intl.DateTimeFormat("ch-DE").format(new Date()),
      pdfFiles: [],

      propEntities: [
        {
          tbl_name: "bxc_anmeldungen",
          selectedIds: [],
        },
      ],
      veranstaltungSelection: [],
      showEvents: false,
      massmutationModalId: "massenmutationsmodal-anmeldereiter",
      category: null,
      templateID: null,
      endpoint: null,
      carboneDate: null,
    };
  },
  watch: {
    shown(val) {
      if (val && this.anmeldungen.length === 0) this.getAnmeldungen();
    },
    /*  showEvents(newVal) {
      if (!newVal) {
        this.selectedVeranstaltungenIds = [];
      } else {
        this.selectedVeranstaltungenIds = [];
        this.veranstaltungSelection.forEach((event) => {
          if (event.selected) {
            this.selectedVeranstaltungenIds.push(event.id);
          }
        });
      }
    }, */
  },
  computed: {
    personFields() {
      return [
        { key: "selected", label: "" },
        {
          key: "veranstaltung.kuerzel",
          sortable: true,
          label: this.$t("global.shortdesignation"),
        },
        {
          key: "veranstaltung.bezeichnung",
          sortable: true,
          label: this.$t("global.event"),
        },
        {
          key: "veranstaltung.lehrgang.startdatum",
          sortable: true,
          label: this.$t("global.startdate"),
        },
        {
          key: "anmeldestatus.bezeichnung",
          sortable: true,
          label: this.$t("global.statusregistration"),
        },
        {
          key: "anmeldungsdatum",
          sortable: true,
          label: this.$t("global.registration"),
        },
        {
          key: "rechnungsdatum",
          sortable: true,
          label: this.$t("global.invoicedate"),
        },
        /*{
          key: "anmeldeposition",
          sortable: true,
          label: "Anmeldeposition",
        },*/
        {
          key: "anmeldetypBezeichnung",
          sortable: true,
          label: this.$t("global.registrationtype"),
        },
        {
          key: "nicht_subventioniert",
          sortable: true,
          label: this.$t("global.notsubsidized"),
          class: "text-center",
        },
        {
          key: "veranstaltungstypBezeichnung",
          sortable: true,
          label: this.$t("global.type"),
        },
      ];
    },
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "person.personName",
          sortable: true,
          label: this.$t("global.person"),
        },
        {
          key: "person.firmaAdresse",
          sortable: true,
          label: this.$t("global.address"),
        },
        {
          key: "anmeldestatus.bezeichnung",
          sortable: true,
          label: this.$t("global.statusregistration"),
        },
        {
          key: "anmeldetypBezeichnung",
          sortable: true,
          label: this.$t("global.registrationtype"),
        },
        {
          key: "nicht_subventioniert",
          sortable: true,
          label: this.$t("global.notsubsidized"),
          class: "text-center",
        },
        {
          key: "abschluss.bezeichnung",
          sortable: true,
          label: this.$t("global.degree"),
        },
        {
          key: "anmeldungsdatum",
          sortable: true,
          label: this.$t("global.registration"),
        },
        {
          key: "rechnungsdatum",
          sortable: true,
          label: this.$t("global.invoicedate"),
        },
        {
          key: "veranstaltungstypBezeichnung",
          sortable: true,
          label: this.$t("global.type"),
        },
      ];
    },
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.suche) params.suche = this.suche;

      return params;
    },
    computedFields() {
      if (this.person) return this.personFields;
      else return this.fields;
    },
    selectedVeranstaltungenIds() {
      if (!this.showEvents) return [];
      return this.veranstaltungSelection
        .filter((event) => {
          return event.selected;
        })
        .map((event) => {
          return event.id;
        });
    },
    anmeldestati: {
      get() {
        return this.$store.state.anmeldung.anmeldestati;
      },
    },
  },
  mounted() {
    if (this.anmeldestati.length === 0)
      this.$store.dispatch(`anmeldung/${LADE_AUSWAHLWERTE_ANMELDUNG}`);

    this.$bus.$on("open-modal", (id) => {
      if (id === this.massmutationModalId) {
        if (!this.lehrgang) return;
        Api.get("veranstaltungen/", {
          params: { lehrgang: this.lehrgang.id },
        }).then((response) => {
          this.veranstaltungSelection = response.data;
          this.veranstaltungSelection.forEach((event) => {
            this.$set(event, "selected", false);
          });
        });
      }
    });
    //TODO: Mandantenspezifisches FrontEnd sauber loesen
    if (location.href.includes("healthspot")) {
      this.showZertifikatButton = true;
    }
    if (this.shown && this.anmeldungen.length === 0) this.getAnmeldungen();
  },
  beforeDestroy() {
    this.$bus.$off("open-massmutation-modal");
  },
  created() {},
  methods: {
    oeffnePrintoutDatumModal(hasDate, category, templateID, endpoint) {
      if (this.selectedIds.length === 0) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noregistrationselected"),
        });

        return;
      }

      this.category = category;
      this.templateID = templateID;
      this.endpoint = endpoint;

      if (!hasDate) {
        this.carbone();
      } else {
        this.$bus.$emit("open-modal", "ameldungsreiter-datum-pdf-modal");
      }
    },
    generiereCarboneReport(date) {
      this.carboneDate = date;
      this.carbone();
    },
    validCarbonePrintouts(constant) {
      const validCarbonePrintouts = [];

      for (const key in constant) {
        const carboneprint = constant[key];
        if (this.isValidCarbonePrintout(carboneprint)) {
          validCarbonePrintouts.push(carboneprint);
        }
      }

      return validCarbonePrintouts;
    },
    carbone(category, templateID, endpoint) {
      if (this.selectedIds?.length === 0) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nopersonselected"),
        });
      } else {
        CarboneApi.get(
          endpoint +
            this.selectedIds +
            "&category=" +
            category +
            "&templateId=" +
            templateID
        )
          .then((response) => {
            if (
              this.isDisplayableFile(response.data[0]?.url) === "isWordFile"
            ) {
              $;
              response.data.forEach((file) => {
                this.saveFile(
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                  file.url,
                  file.name,
                  ".docx"
                );
              });
            } else if (
              this.isDisplayableFile(response.data[0]?.url) === "isExcelFile"
            ) {
              response.data.forEach((file) => {
                this.saveFile(
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  file.url,
                  file.name,
                  ".xlsx"
                );
              });
            } else {
              this.pdfFiles = response.data.map((item) => ({
                url: server.url + item.url,
                name: item.name,
                count: item.count,
                size: item.name,
              }));

              this.pdfFiles.length > 0
                ? this.$refs.pdfLayerAnmeldungReiter.show()
                : this.$notify({
                    type: "warn",
                    title: this.$t("global.actionfailed"),
                    text: "Es ist ein Fehler aufgetreten",
                  });
            }
          })
          .catch((error) => {
            console.error("Error fetching PDF files:", error);
          })
          .finally(() => {
            this.$refs.pdfLayerAnmeldungReiter.loading = false;
          });
      }
    },
    isDisplayableFile(url) {
      const fileExtension = this.getFileExtension(url);
      if (["docx", "doc"].includes(fileExtension)) {
        return "isWordFile";
      } else if (["xls", "xlsx", "xlsm"].includes(fileExtension)) {
        return "isExcelFile";
      }
      return "isDisplayable";
    },
    getFileExtension(url) {
      const parts = url?.split(".");
      return parts[parts.length - 1].toLowerCase();
    },
    async saveFile(contentType, url, fileName, type) {
      if (!contentType || !url) return;

      if (!fileName) fileName = "Report";
      fileName = fileName + type;

      const response = await fetch(Api.defaults.defaultURL + url, {
        headers: {
          "Content-Type": contentType,
        },
      });

      let file = await response.blob();
      FileDownload(file, fileName);
    },
    getAnmeldungen() {
      this.isBusy = true;

      Api.get("anmeldung/", {
        params: {
          veranstaltung: this.veranstaltung,
          person: this.person,
          page: this.page,
          count: this.anzahlProPage,
        },
      })
        .then((response) => {
          this.anmeldungen = response.data;
          this.anmeldungCount = this.anmeldungen.length;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    details(anmeldungen) {
      if (this.berechtigungen.m_anmeldungen.read)
        this.$router.push({
          name: "anmeldung",
          params: { anmeldungid: anmeldungen.id, anzeige: 0 },
        });
    },

    ausgebenTeilnehmerliste() {
      let ids = this.anmeldungen.map((a) => a.id);
      let filename = new Intl.DateTimeFormat("ch").format(new Date());

      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=Teilnehmerliste_${
          this.klassenkuerzel
        }_${filename}.pdf&report=/pdfgenerator/teilnehmerliste-pdf.htm&ids=${
          this.veranstaltung
        }&sgid=${true}`;
      this.$refs.pdfLayerAnmeldungReiter.show();
    },
    openSpracheWaehlenModal(id) {
      this.$bus.$emit("open-modal", id);
    },
    openParticipantsListNoPic(json) {
      const reportName = "teilnehmerliste-" + json.language;
      let filename = new Intl.DateTimeFormat("ch").format(new Date());

      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=Teilnehmerliste_${
          this.klassenkuerzel
        }_${filename}.pdf&report=/pdfgenerator/${reportName}.htm&ids=${
          this.veranstaltung
        }&date=${this.date}&sgid=${true}`;
      this.$refs.pdfLayerAnmeldungReiter.show();
    },

    openParticipantsListWithPic(json) {
      const reportName = "teilnehmerliste-foto-" + json.language;
      let filename = new Intl.DateTimeFormat("ch").format(new Date());

      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=Teilnehmerliste_mit_Bild_${this.klassenkuerzel}_${filename}.pdf&report=/pdfgenerator/${reportName}.htm&ids=${this.veranstaltung}&date=${this.date}`;
      this.$refs.pdfLayerAnmeldungReiter.show();
    },
    openParticipantsListWithPicWithoutContact(json) {
      const reportName = "teilnehmerliste-foto-ohneKontakt-" + json.language;
      let filename = new Intl.DateTimeFormat("ch").format(new Date());
      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=Teilnehmerliste_mit_Bild_${this.klassenkuerzel}_${filename}.pdf&report=/pdfgenerator/${reportName}.htm&ids=${this.veranstaltung}&date=${this.date}`;
      this.$refs.pdfLayerAnmeldungReiter.show();
    },
    druckeTischkarten() {
      if (this.selectedIds.length === 0) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noregistrationselected"),
        });

        return;
      }

      let personenIDs = [];

      this.anmeldungen.forEach((anmeldung) => {
        if (this.selectedIds.includes(anmeldung.id)) {
          personenIDs.push(anmeldung.person.id);
        }
      });

      if (personenIDs.length > 0) {
        let ids = personenIDs.join(",");

        let filename = this.$t("global.placecards") + "_" + this.klassenkuerzel;

        this.pdfPath =
          server.url +
          `/pdfgenerator/pdf.act?filename=${filename}.pdf&report=/pdfgenerator/tischkarten-pdf.htm&landscape=true&ids=${ids}`;
        this.$refs.pdfLayerAnmeldungReiter.show();
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noregistrationselected"),
        });
      }
    },
    druckeAdressEtiketten() {
      if (this.selectedIds.length === 0) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noregistrationselected"),
        });

        return;
      }

      let personenIDs = [];

      this.anmeldungen.forEach((anmeldung) => {
        if (this.selectedIds.includes(anmeldung.id)) {
          personenIDs.push(anmeldung.person.id);
        }
      });

      if (personenIDs.length > 0) {
        let ids = personenIDs.join(",");

        let filename =
          this.$t("global.addresslabels") + "_" + this.klassenkuerzel;

        this.pdfPath =
          server.url +
          `/pdfgenerator/pdf.act?filename=${filename}.pdf&report=/pdfgenerator/adress-etiketten-pdf.htm&ids=${ids}`;
        this.$refs.pdfLayerAnmeldungReiter.show();
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noregistrationselected"),
        });
      }
    },
    druckeNamensschilder() {
      if (this.selectedIds.length === 0) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noregistrationselected"),
        });

        return;
      }

      let personenIDs = [];

      this.anmeldungen.forEach((anmeldung) => {
        if (this.selectedIds.includes(anmeldung.id)) {
          personenIDs.push(anmeldung.person.id);
        }
      });

      if (personenIDs.length > 0) {
        let ids = personenIDs.join(",");

        let filename = this.$t("global.nametags") + "_" + this.klassenkuerzel;

        this.pdfPath =
          server.url +
          `/pdfgenerator/pdf.act?filename=${filename}.pdf&report=/pdfgenerator/namensschilder-pdf.htm&ids=${ids}`;
        this.$refs.pdfLayerAnmeldungReiter.show();
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noregistrationselected"),
        });
      }
    },
    showZertifikatPdfModal() {
      if (this.selectedIds?.length > 0) {
        this.$bus.$emit("open-modal", "zertifikat-pdf-modal");
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.hint"),
          text: this.$t("global.nopersonsselected"),
        });
      }
    },

    updateDate(selectedDate) {
      this.date = selectedDate;
    },

    ausgabeZertifikate() {
      this.selectedObjects = this.anmeldungen.filter((item) => item.selected);

      this.pdfFiles = [];

      if (this.selectedObjects?.length > 0) {
        this.selectedObjects.forEach((anmeldung) => {
          if (!this.anmeldungObj[anmeldung.person?.id]) {
            this.anmeldungObj[anmeldung.person?.id] = {
              personName: anmeldung.person?.personName,
              personID: anmeldung.person.id,
              anmeldungenIds: anmeldung.id,
              anzahlAnmeldungen: 1,
              veranstaltungName: anmeldung.veranstaltung.bezeichnung,
            };
          } else {
            this.anmeldungObj[anmeldung.person?.id].anmeldungenIds +=
              "," + anmeldung.id;
            this.anmeldungObj[anmeldung.person?.id].anzahlAnmeldungen++;
          }
        });

        //Momentan noch keine Begrenzung fuer Anzahl Zertifikate. Einbauen anhand von anzahlZertifikate und size anpassen, falls gefordert
        Object.values(this.anmeldungObj).forEach((entry, index) => {
          this.generatePDF(index, entry);
        });
        this.$refs.pdfLayerAnmeldungReiter.show();
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nolecturerdeploymentselected"),
        });
      }
    },

    generatePDF(index, anmeldung) {
      let json = {
        idlist: anmeldung.anmeldungenIds,
      };
      let personID = anmeldung.personID;
      Api.post("printcache/", json, { params: {} }).then((response) => {
        let cacheId = response.data["cache-id"];
        let filename = anmeldung?.personName?.trim();
        filename += new Intl.DateTimeFormat("ch").format(new Date());
        filename = filename.trim();
        let size = anmeldung?.personName;
        size = size.trim();

        let pdfPath =
          "pdfgenerator/pdfcache.act?filename=Zertifikat" +
          filename +
          ".pdf&report=/api/pdfgenerator/zertifikat.htm&cache=" +
          cacheId +
          "&date=" +
          this.date;

        Api.get(pdfPath).then((response) => {
          let retValue = {
            index: index,
            url: server.url + response.data.filepath,
            withCredentials: true,
            count: Object.entries(this.anmeldungObj).length,
            size: size,
          };

          this.pdfFiles.push(retValue);
          let zertifikatName = "Zertifikat " + anmeldung.veranstaltungName;
          Api.post("dokument/", json, {
            params: {
              id: personID,
              fileID: response.data.fileID,
              fileName: zertifikatName,
            },
          }).then(() => {});
        });
      });
    },

    isValidCarbonePrintout(printout) {
      let isValid = false;
      if (!this.lehrgang) return isValid;
      let institution = printout.appkey;
      let index = institution.indexOf("_");
      if (index !== -1) {
        institution = institution.substring(0, index);
      }

      if (
        this.lehrgang.institution &&
        this.lehrgang.institution.kuerzel.toLowerCase() ===
          institution.toLowerCase()
      ) {
        isValid = true;
      }

      if (printout.appkey.includes("bericht")) {
        isValid = true;
      }

      let isImmatrikulationsbestaetigung = printout.appkey.includes(
        "immatrikulationsbestaetigung"
      );
      let isZeugnis = printout.appkey.includes("zeugnis");
      let isDiplom = printout.appkey.includes("diplom");

      let printoutSemester = printout.appkey.split("_")[2];

      if (
        (isImmatrikulationsbestaetigung || isZeugnis || isDiplom) &&
        isValid
      ) {
        if (
          !this.semester ||
          (this.semester.toString() !== printoutSemester &&
            Number(printoutSemester))
        ) {
          isValid = false;
        }
      }

      if (!isImmatrikulationsbestaetigung) {
        this.$set(printout, "hasDate", true);
      } else {
        this.$set(printout, "hasDate", false);
      }

      return isValid;
    },

    veranstaltungstypHintergrundfarbe(item) {
      let veranstaltungstyp = item.veranstaltung.veranstaltungstyp;

      if (veranstaltungstyp) {
        //Fach:
        if (veranstaltungstyp.id == "174D91487D4") {
          return { backgroundColor: "#EAE8AC", textAlign: "center" };
        }
        //Bildungsgang (Semester):
        else if (veranstaltungstyp.id == "174D91487DA") {
          return { backgroundColor: "#84D0E2", textAlign: "center" };
        }
        //Kurs:
        else if (veranstaltungstyp.id == "174D91487E8") {
          return { backgroundColor: "#C3C4E2", textAlign: "center" };
        } else {
          return { backgroundColor: "#DEEDF6", textAlign: "center" };
        }
      } else {
        return { textAlign: "center" };
      }
    },

    anmeldetypHintergrundfarbe(item) {
      let anmeldetyp = item.anmeldetyp;

      if (anmeldetyp) {
        if (anmeldetyp.id == "18346A7BFB5") {
          return { backgroundColor: "#F2D585", textAlign: "center" };
        } else {
          return { textAlign: "center" };
        }
      }
    },

    neueMail() {
      //let index = this.mailadressen.findIndex((mailadresse) => )
      if (this.selectedIds && this.selectedIds.length == 0) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.oneregistrationselected"),
        });
      } else {
        this.loading = true;
        let json = {
          anmeldung: this.selectedIds[0],
          inhalt: "",
          betreff: this.$t("global.emailprepared"),
          zeitpunkt: new Intl.DateTimeFormat("ch-DE").format(new Date()),
        };

        if (this.veranstaltung) {
          let anmeldung = this.anmeldungen.find(
            (anmeldung) => anmeldung.id == this.selectedIds[0]
          );

          json.empfaenger = [anmeldung.person];
        } else {
          json.empfaenger = [this.person];
        }

        Api.post("mail/", json, { params: {} })
          .then((response) => {
            this.$router.push({
              name: "mail",
              params: {
                id: response.data.id,
                anzeige: 0,
              },
            });
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    neueKorrespondenz() {
      if (this.selectedIds && this.selectedIds.length == 0) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.oneregistrationselected"),
        });
      } else {
        this.loading = true;
        let json = {
          anmeldung: this.selectedIds[0],
          inhalt: "",
          versand: {
            erstelltvon: this.angemeldeterMitarbeiter.person,
            datum: new Intl.DateTimeFormat("ch-DE").format(new Date()),
          },
          betreff: this.$t("global.correspondenceprepared"),
        };

        if (this.veranstaltung) {
          let anmeldung = this.anmeldungen.find(
            (anmeldung) => anmeldung.id == this.selectedIds[0]
          );

          json.empfaenger = [anmeldung.person.id]; // Unsauberkeit im IO
          json.person = [anmeldung.person.id];
        } else {
          json.empfaenger = [this.person]; // Unsauberkeit im IO
          json.person = [this.person];
        }

        Api.post("korrespondenz/", json, { params: {} })
          .then((response) => {
            this.$router.push({
              name: "korrespondenz",
              params: {
                id: response.data.id,
                anzeige: 0,
              },
            });
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    massenkorrespondenzErstellen() {
      if (this.selectedIds.length > 0) {
        this.loading = true;
        let json = {
          empfaenger: [],
          person: [], // ist de facto person. Noch unsauber im Backend
          isMassKorrespondenz: true,
          anmeldung: this.selectedIds[0], // Die erste Anmeldung wird für die Verknüpfung mitgegeben, damit die Serienfelder gehen
          versand: {
            erstelltvon: this.angemeldeterMitarbeiter,
            datum: new Intl.DateTimeFormat("ch-DE").format(new Date()),
            ort: "",
            betreff: this.$t("global.newserialletter"),
          },
          inhalt: "",
          zeitpunkt: new Intl.DateTimeFormat("ch-DE").format(new Date()),
        };

        Array.from(new Set(this.selectedIds)).forEach((id) => {
          json.empfaenger.push(
            this.anmeldungen.find((anmeldung) => anmeldung.id == id).person.id
          );
        });

        json.person = json.empfaenger;

        if (json.empfaenger.length == 0) {
          this.$notify({
            type: "warn",
            title: this.$t("global.actionfailed"),
            text: this.$t("global.oneregistrationselected"),
          });
          return;
        }

        Api.post("korrespondenz/", json, { params: {} }).then((response) => {
          this.loading = true;
          Api.post("korrespondenz/empfaenger/", json, {
            params: {
              korrespondenz: response.data.id,
              isPerson: true,
              isMassCorr: true,
            },
          })
            .then((empfresponse) => {
              this.loading = false;
              this.$router.push({
                name: "korrespondenz",
                params: {
                  id: response.data.id,
                  anzeige: 0,
                },
              });
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              this.loading = false;
            });
        });
      } else
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nopersonselected"),
        });
    },

    massenmailErstellen() {
      if (this.selectedIds.length > 0) {
        this.loading = true;
        let json = {
          empfaenger: [],
          isMassMail: true,
          anmeldung: this.selectedIds[0], // Die erste Anmeldung wird für die Verknüpfung mitgegeben, damit die Serienfelder gehen
          inhalt: "",
          zeitpunkt: new Intl.DateTimeFormat("ch").format(new Date()),
        };

        Array.from(new Set(this.selectedIds)).forEach((id) => {
          json.empfaenger.push(
            this.anmeldungen.find((anmeldung) => anmeldung.id == id).person.id
          );
        });

        Api.post("mail/", json, { params: {} })
          .then((response) => {
            this.$router.push({
              name: "mail",
              params: {
                id: response.data.id,
                anzeige: 0,
              },
            });
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.loading = false;
          });
      } else
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noregistrationselected"),
        });
    },

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },

    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
        this.selectedIds = Array.from(new Set(this.selectedIds));
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },

    showAnmeldungUebertragen() {
      if (this.selectedIds.length == 0) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noregistrationselected"),
        });
        return;
      }
      this.$bus.$emit("openAnmeldungUebertragenModal", this.selectedIds);
    },

    anmeldungGeaendert(response) {
      if (response.status == 200) {
        this.$notify({
          type: "success",
          title: this.$t("global.actionsuccessful"),
          text: this.$t("global.registrationssuccessfullytransferred"),
        });
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.registrationtransferfailed"),
        });
      }
    },

    oeffneRechnungErstellenModal() {
      if (this.selectedIds.length > 0) {
        this.verrechnungPosKey++;
        this.$bus.$emit("open-modal", "rechnung-erstellen-modal");
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noregistrationselected"),
        });
      }
    },

    neueAnmeldung() {
      if (this.person) {
        this.$router.push({
          name: "anmeldung.person",
          params: {
            personid: this.person,
          },
        });
      } else {
        this.$router.push({
          name: "anmeldung.veranstaltung",
          params: {
            veranstaltungid: this.veranstaltung,
          },
        });
      }

      /*let now = new Date();

      Api.post("anmeldung/", {
        veranstaltung: this.veranstaltung,
        person: this.person,
        anmeldestatus: anmeldungStatusAngemeldet,
        anmeldetyp: anmeldungTypRegulaer,
        zahlungsplan: zahlungsPlanEinmalig,
        anmeldungsdatum:
          now.getDate() + "." + (now.getMonth() + 1) + "." + now.getFullYear(),
        anmeldungsdatum_uhrzeit: now.getHours() + ":" + now.getMinutes(),
      }).then((response) => {
        this.$router.push({
          name: "anmeldung",
          params: {
            anmeldungid: response.data.id,
            anzeige: 0,
          },
        });
      });*/
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "anmeldungen-loeschen-modal");
      else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noregistrationselecteddeletion"),
        });
      }
    },

    anmeldungenLoeschen() {
      this.isBusy = true;

      Api.delete("anmeldung/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.getAnmeldungen();
      });
    },

    refresh() {
      this.selectedIds = [];
      this.getAnmeldungen();
    },
    selectAllEvents() {
      this.veranstaltungSelection.forEach((event) => {
        event.selected = true;
      });
    },
  },
};
</script>
