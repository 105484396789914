<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.documents')"
        :col="2"
      >
        <div class="row mt-2 d-flex justify-content-between">
          <div class="ml-4">
            <button
              class="btn btn-primary ml-2"
              @click="openPublicationModal"
              :disabled="!selectedIds"
            >
              <font-awesome-icon icon="fa-regular fa-upload" class="mr-2" />
              {{ $t("global.setpublication") }}
            </button>
          </div>
          <div class="ml-auto mr-2">
            <b-button
              class="float-right ml-2"
              variant="outline-primary"
              @click="switchSetAll()"
            >
              <span>
                {{ setAll ? $t("global.deselectall") : $t("global.selectall") }}
              </span>
            </b-button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-12 block belegliste br-t-l-0">
          <b-table
            hover
            responsive
            sticky-header
            show-empty
            :items="dokumente"
            :fields="fields"
            :busy="isBusy"
            tbody-tr-class="item"
            ref="selectableTable"
            style="max-height: 430px"
          >
            <template v-slot:cell(publiziert)="row">
              <b-form-checkbox
                v-model="row.item.publiziert"
                @input="setChildRows(row.item)"
              ></b-form-checkbox>
            </template>
            <template #cell(details)="row">
              <b-button
                @click="row.toggleDetails"
                class="m-0"
                variant="outline-primary"
                size="sm"
              >
                <font-awesome-icon
                  :icon="
                    row.detailsShowing ? 'fa-chevron-up' : 'fa-chevron-down'
                  "
                />
              </b-button>
            </template>
            <template #row-details="row">
              <div class="my-1 px-2">
                <b-table
                  thead-class="z"
                  tbody-tr-class="item"
                  small
                  responsive
                  sticky-header
                  :items="row.item.personenListe"
                  :fields="detailFields"
                  :busy="isBusy"
                  show-empty
                  sort-icon-left
                  selectable
                  select-mode="range"
                  thead-tr-class="no-bg"
                >
                  <template v-slot:cell(publiziert)="row">
                    <b-form-checkbox
                      v-model="row.item.publiziert"
                      :disabled="row.item.dokumentPublizieren === false"
                    ></b-form-checkbox>
                  </template>
                  <template #table-busy>
                    <div class="text-center text-primary my-2">
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                  </template>

                  <template #empty>
                    <div v-if="ladeFehler" class="text-center text-danger my-2">
                      <strong>{{ $t("global.errorwhileloading") }}</strong>
                    </div>
                    <div
                      v-if="!ladeFehler"
                      class="text-center text-primary my-2"
                    >
                      <strong>{{ $t("global.nodataavailable") }}</strong>
                    </div>
                  </template>
                </b-table>
              </div>
            </template>
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #empty>
              <div v-if="ladeFehler" class="text-center text-danger my-2">
                <strong>{{ $t("global.errorwhileloading") }}</strong>
              </div>
              <div v-if="!ladeFehler" class="text-center text-primary my-2">
                <strong>{{ $t("global.nodataavailable") }}</strong>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
import Api from "@/Api";
import App from "@/AppApi";

import ContainerHeadline from "@/components/ContainerHeadline";

export default {
  name: "Dokumentenuebersicht",
  components: {
    ContainerHeadline,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
    },
  },
  data() {
    return {
      configurationAvailable: false,
      selectedIds: [],
      setAll: false,
      isBusy: false,
      shown: false,
      lehrgang: { bezeichnung: this.$t("global.notlinked") },
      dokumente: [
        {
          id: "dokumentID1",
          erstellungsdatum: "01.01.2024",
          bezeichnung: "dokument1.1",
          dokumenttyp: "Rechnung",
          publiziert: true,
          personenListe: [
            {
              id: "teilnehmerID1",
              name: "name1",
              abschluss: "abschluss1",
              anmeldestatus: "anmeldestatus1",
              anmeldetyp: "anmeldetyp1",
              publiziert: true,
              publikationszeitraum: "01.01.2024 - 31.01.2024",
            },
            {
              id: "teilnehmerID2",
              name: "name2",
              abschluss: "abschluss2",
              anmeldestatus: "anmeldestatus2",
              anmeldetyp: "anmeldetyp2",
              publiziert: true,
              publikationszeitraum: "-",
            },
          ],
        },
        {
          id: "dokumentID2",
          erstellungsdatum: "01.02.2024",
          bezeichnung: "dokument1.2",
          dokumenttyp: "Leistungsnachweis",
          publiziert: false,
          personenListe: [
            {
              id: "teilnehmerID1",
              name: "name1",
              abschluss: "abschluss1",
              anmeldestatus: "anmeldestatus1",
              anmeldetyp: "anmeldetyp1",
              publiziert: false,
              publikationszeitraum: "-",
            },
            {
              id: "teilnehmerID2",
              name: "name2",
              abschluss: "abschluss2",
              anmeldestatus: "anmeldestatus2",
              anmeldetyp: "anmeldetyp2",
              publiziert: false,
              publikationszeitraum: "01.02.2024 - 28.02.2024",
            },
          ],
        },
      ],
      configurationOn: false,
    };
  },
  computed: {
    fields() {
      return [
        {
          key: "bezeichnung",
          label: this.$t("global.document"),
        },
        {
          key: "erstellungsdatum",
          label: this.$t("global.creationdate"),
        },
        {
          key: "dokumenttyp",
          label: this.$t("global.documenttype"),
        },
        {
          key: "publiziert",
          label: this.$t("global.Publish"),
          thStyle: "width: 10%;",
          class: "text-center",
        },
        {
          key: "details",
          label: "",
          thStyle: "width: 5%;",
        },
      ];
    },
    detailFields() {
      return [
        {
          key: "name",
          label: this.$t("global.person"),
        },
        {
          key: "anschluss",
          label: this.$t("global.degree"),
        },
        {
          key: "anmeldestatus",
          label: this.$t("global.statusregistration"),
        },
        {
          key: "anmeldetyp",
          label: this.$t("global.registrationtype"),
        },
        {
          key: "publikationszeitraum",
          label: this.$t("global.timeperiod"),
        },
        {
          key: "publiziert",
          label: this.$t("global.Publish"),
          thStyle: "width: 10%;",
          class: "text-center",
        },
      ];
    },
  },
  watch: {},
  mounted() {},
  methods: {
    getDocuments() {},

    openPublicationModal() {},

    setChildRows(value) {
      value.personenListe.forEach((person) => {
        person.publiziert = value.publiziert;
        person.dokumentPublizieren = value.publiziert;
      });
    },

    saveConfig() {
      //TODO: POST/PUT des Konfigdatensatzes
    },

    switchSetAll() {
      this.setAll ? (this.setAll = false) : (this.setAll = true);

      if (this.setAll) {
        this.dokumente.forEach((dokument) => {
            dokument.publiziert = true;
          this.setChildRows(dokument);
        });
      } else {
        this.dokumente.forEach((dokument) => {
            dokument.publiziert = false;
          this.setChildRows(dokument);
        });
      }
    },

    setConfigurationOn(value) {
      this.configurationOn = value;
    },
  },
};
</script>
    
  <style></style>
    