<template>
  <div>
    <notifications></notifications>

    <navbar :docsName="docsName" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12 block br-t-l-0 mb-3">
          <head-menu
            :col="12"
            class="d-xl-flex"
            :headline="
              lehrgang.bezeichnung
                ? $t('global.finalgradeoverview') + ' ' + lehrgang.bezeichnung
                : $t('global.finalgradeoverview')
            "
          ></head-menu>
          <div class="row mt-2 d-flex justify-content-between">
            <div class="ml-4">
              <button
                class="btn btn-success"
                @click="speichern"
                @shortkey="speichern"
                v-shortkey.once="['ctrl', 's']"
              >
                <span>{{ $t("global.save") }}</span>
              </button>

              <button class="ml-2 btn btn-primary" @click="erstelleExcel">
                <font-awesome-icon icon="fa-duotone fa-print" class="mr-2" />
                <em>{{ $t("global.createexcel") }}</em>
              </button>

              <button class="ml-2 btn btn-primary" @click="endnotenBerechnen">
                <font-awesome-icon
                  icon="fa-duotone fa-calculator"
                  class="mr-2"
                />
                <em>{{ $t("global.calculatefinalgrades") }}</em>
              </button>
              <button class="ml-2 btn btn-primary" @click="openConfigGradesOverview">
                <font-awesome-icon
                  icon="fa-duotone fa-file-certificate"
                  class="mr-2"
                />
                <span>{{ $t("global.gradeoverview") }}</span>
              </button>

              <!-- TODO: Bedingung reports vorhanden hinzufügen-->
              <b-dropdown
                variant="primary ml-2"
                v-if="
                  validCarbonePrintouts($CONST('CARBONEPRINTOUTS')).length > 0
                "
              >
                <template slot="button-content">
                  <font-awesome-icon icon="fa-duotone fa-print" class="mr-2" />
                  <em>{{ $t("global.output") }}</em>
                </template>

                <!--Carbone-->

                <b-dropdown-item
                  v-for="carboneprint in validCarbonePrintouts(
                    $CONST('CARBONEPRINTOUTS')
                  )"
                  :key="carboneprint.id"
                  @click="
                    oeffnePrintoutDatumModal(
                      carboneprint.category.bezeichnung,
                      carboneprint.template,
                      'carbone/?ids='
                    )
                  "
                >
                  {{ carboneprint.value }}
                </b-dropdown-item>
              </b-dropdown>
            </div>

            <div class="mr-2">
              <button class="mr-3 btn btn-primary" @click="abbrechen">
                <span>{{ $t("global.back") }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 block belegliste">
          <b-table
            hover
            responsive
            sticky-header
            show-empty
            :busy="isBusy"
            no-border-collapse
            tbody-tr-class="item"
            thead-tr-class="pointer"
            ref="selectableTable"
            small
            :items="filteredNotenpromotionen"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
          >
            <template v-slot:head(selected)="row">
              <b-form-checkbox
                v-model="row.selected"
                @input="selectAllAnmeldungen(row)"
              ></b-form-checkbox>
            </template>

            <template v-slot:cell(selected)="row">
              <b-form-checkbox
                v-model="row.item.selected"
                @input="onAnmeldungSelected(row)"
              ></b-form-checkbox>
            </template>

            <template v-slot:cell(gesamtanwesenheit)="row">
              {{ row.item.gesamtanwesenheit + "%" }}
            </template>

            <template #cell(nr)="data">
              {{ data.index + 1 }}
            </template>
            <template slot="top-row" slot-scope="{ fields }">
              <td v-for="field in fields" :key="field.key">
                <b-input
                  v-if="
                    field.key != 'selected' &&
                    field.searchable == true &&
                    field.select != true
                  "
                  size="sm"
                  v-model="filters[field.key]"
                  :placeholder="field.label"
                />

                <v-select
                  v-if="field.select == true"
                  v-model="filters[removeOptionLabel(field.key)]"
                  :getOptionLabel="
                    (obj) =>
                      obj.bezeichnung ? obj.bezeichnung : obj.Bezeichnung
                  "
                  :options="field.options"
                  :placeholder="field.label"
                >
                  <span slot="no-options">{{ field.label }}</span>
                </v-select>
              </td>
            </template>
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>

            <template #empty>
              <div v-if="ladeFehler" class="text-center text-danger my-2">
                <strong>{{ $t("global.errorwhileloading") }}</strong>
              </div>
              <div v-if="!ladeFehler" class="text-center text-primary my-2">
                <strong>{{ $t("global.nodataavailable") }}</strong>
              </div>
            </template>

            <template
              #cell(show_details)="row"
            >
            <div class="d-flex align-items-center">
              <div class="d-flex pl-2">
                <div>
                  <button
                    type="button"
                    size="sm"
                    @click="row.toggleDetails"
                    class="btn btn-primary btn-sm"
                  >
                    {{
                      row.detailsShowing ? $t("global.hide") : $t("global.show")
                    }}
                  </button>
                </div>
                <div class="p-1">
                  <font-awesome-icon
                    v-if="row.item.notenpromotion.bemerkung"
                    class="m-1"
                    icon="fa-regular fa-file-alt"
                  />
                </div>
              </div>
            </div>
            </template>

            <template
              #cell(notenpromotion_promotionsstatus_definitiv_bezeichnung)="row"
            >
              <div class="form-group text-normal m-0 p-0">
                <v-select
                  v-model="row.item.notenpromotion.promotionsstatus_definitiv"
                  class="konferenzentscheidDropdown my-3"
                  :getOptionLabel="
                    (obj) =>
                      obj.bezeichnung ? obj.bezeichnung : obj.Bezeichnung
                  "
                  :options="row.field.options"
                  :placeholder="$t('global.promotionstatus')"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
              </div>
            </template>

            <template #row-details="row">
              <b-card class="cursor-default">
                <div class="row m-2 mb-4">
                  <div
                    class="btn mr-2"
                    @click="zeigePromotionsrelevanteSektionsgruppen = true"
                    :class="
                      zeigePromotionsrelevanteSektionsgruppen
                        ? 'btn-primary'
                        : 'btn-light'
                    "
                  >
                    Promotionsrelevante Sektionsgruppen
                  </div>

                  <div
                    class="btn"
                    @click="zeigePromotionsrelevanteSektionsgruppen = false"
                    :class="
                      zeigePromotionsrelevanteSektionsgruppen
                        ? 'btn-light'
                        : 'btn-primary'
                    "
                  >
                    Nicht-promotionsrelevante Sektionsgruppen
                  </div>
                </div>
                <div
                  v-if="
                    getAnzuzeigendeSektionsgruppen(row.item.sektionsgruppen)
                      .length > 0
                  "
                >
                  <div class="row m-2">
                    <div
                      class="col-12 p-0 d-flex text-bold border-bottom border-2 border-primary"
                    >
                      <div class="col-4 p-0 d-flex">
                        <div
                          class="p-0 pl-2 text-overflow-ellipsis"
                          :class="getBezeichnungWidth"
                        >
                          Sektionsgruppen
                        </div>
                        <div
                          class="col-2 p-0 text-center text-overflow-ellipsis"
                          v-if="zeigeGewichtungen"
                        >
                          Gewichtung
                        </div>
                        <div
                          class="col-2 p-0 text-center text-overflow-ellipsis"
                          v-if="zeigeAnwesenheiten"
                        >
                          Anwesenheit
                        </div>
                        <div class="col-3 p-0 text-center">Note</div>
                      </div>
                      <div class="col-4 p-0 d-flex">
                        <div
                          class="p-0 pl-2 text-overflow-ellipsis"
                          :class="getBezeichnungWidth"
                        >
                          Sektionen
                        </div>
                        <div
                          class="col-2 p-0 text-center text-overflow-ellipsis"
                          v-if="zeigeGewichtungen"
                        >
                          Gewichtung
                        </div>
                        <div
                          class="col-2 p-0 text-center text-overflow-ellipsis"
                          v-if="zeigeAnwesenheiten"
                        >
                          Anwesenheit
                        </div>
                        <div class="col-3 p-0 text-center">Note</div>
                      </div>
                      <div class="col-4 p-0 d-flex text-overflow-ellipsis">
                        <div class="p-0 pl-2" :class="getBezeichnungWidth">
                          Sektionsfelder
                        </div>
                        <div
                          class="col-2 p-0 text-center text-overflow-ellipsis"
                          v-if="zeigeGewichtungen"
                        >
                          Gewichtung
                        </div>
                        <div
                          class="col-2 p-0 text-center text-overflow-ellipsis"
                          v-if="zeigeAnwesenheiten"
                        >
                          Anwesenheit
                        </div>
                        <div class="col-3 p-0 text-center">Note</div>
                      </div>
                    </div>
                    <div
                      class="col-12 p-0 d-flex border-bottom border-primary"
                      v-for="(
                        sektionsgruppe, sektionsgruppenindex
                      ) in getAnzuzeigendeSektionsgruppen(
                        row.item.sektionsgruppen
                      )"
                      :key="'sektionsgruppe-' + sektionsgruppenindex"
                      :style="setBackgroundcolor(sektionsgruppenindex)"
                    >
                      <!-- SPALTE SEKTIONSGRUPPEN -->
                      <div
                        class="col-4 p-0 d-flex border-right border-2 border-primary"
                      >
                        <div
                          class="p-0 pl-2 d-flex h-100-p align-items-center text-overflow-ellipsis text-normal"
                          :class="getBezeichnungWidth"
                          v-tooltip.hover
                          :title="sektionsgruppe.bezeichnung"
                        >
                          <span class="text-overflow-ellipsis text-normal">{{
                            sektionsgruppe.bezeichnung
                          }}</span>
                        </div>
                        <div
                          class="col-2 p-0 pl-2 d-flex h-100-p align-items-center justify-content-center"
                          v-if="zeigeGewichtungen"
                        >
                          {{
                            sektionsgruppe.gewichtung
                              ? sektionsgruppe.gewichtung
                              : "-"
                          }}
                        </div>
                        <div
                          class="col-2 p-0 pl-2 d-flex h-100-p align-items-center justify-content-center"
                          v-if="zeigeAnwesenheiten"
                        >
                          {{
                            sektionsgruppe.anwesenheitsrate
                              ? sektionsgruppe.anwesenheitsrate + "%"
                              : "-"
                          }}
                        </div>
                        <div
                          class="col-3 p-0 pl-2 d-flex h-100-p align-items-center justify-content-center text-center word-break-all"
                          v-html="getNotenanzeige(sektionsgruppe)"
                        ></div>
                      </div>
                      <!-- SPALTE SEKTIONEN UND SEKTIONSFELDER -->
                      <div class="col-8 p-0 d-flex flex-column">
                        <div
                          class="d-flex"
                          :class="
                            !(
                              sektionsindex ===
                              sektionsgruppe.sektionen.length - 1
                            )
                              ? 'border-bottom border-primary'
                              : ''
                          "
                          v-for="(
                            sektion, sektionsindex
                          ) in sektionsgruppe.sektionen"
                          :key="'sektion-' + sektionsindex"
                        >
                          <!-- SEKTIONEN -->
                          <div
                            class="col-6 p-0 py-3 border-right border-2 border-primary d-flex align-items-center"
                          >
                            <div
                              class="p-0 pl-2 text-overflow-ellipsis text-normal"
                              :class="getBezeichnungWidth"
                              v-tooltip.hover
                              :title="sektion.bezeichnung"
                            >
                              <span
                                class="text-overflow-ellipsis text-normal"
                                >{{ sektion.bezeichnung }}</span
                              >
                            </div>
                            <div
                              class="col-2 p-0 text-center"
                              v-if="zeigeGewichtungen"
                            >
                              {{
                                sektion.gewichtung ? sektion.gewichtung : "-"
                              }}
                            </div>
                            <div
                              class="col-2 p-0 text-center"
                              v-if="zeigeAnwesenheiten"
                            >
                              {{
                                sektion.anwesenheitsrate
                                  ? sektion.anwesenheitsrate + "%"
                                  : "-"
                              }}
                            </div>
                            <div
                              class="col-3 p-0 text-center word-break-all"
                              v-html="getNotenanzeige(sektion)"
                            ></div>
                          </div>

                          <!-- SEKTIONSFELDER -->
                          <div
                            class="col-6 p-0 d-flex flex-column justify-content-center"
                          >
                            <div
                              v-for="(
                                sektionsfeld, sektionsfeldindex
                              ) in sektion.sektionsfelder"
                              :key="'sektionsfeld-' + sektionsfeldindex"
                            >
                              <div
                                class="py-2 d-flex"
                                :class="
                                  !(
                                    sektionsfeldindex ===
                                    sektion.sektionsfelder.length - 1
                                  )
                                    ? 'border-bottom border-primary'
                                    : ''
                                "
                              >
                                <div
                                  class="p-0 pl-2 text-overflow-ellipsis text-normal"
                                  :class="getBezeichnungWidth"
                                  v-tooltip.hover
                                  :title="sektionsfeld.bezeichnung"
                                >
                                  <span
                                    class="text-overflow-ellipsis text-normal"
                                    >{{ sektionsfeld.bezeichnung }}</span
                                  >
                                </div>
                                <div
                                  class="col-2 p-0 text-center"
                                  v-if="zeigeGewichtungen"
                                >
                                  {{
                                    sektionsfeld.gewichtung
                                      ? sektionsfeld.gewichtung
                                      : "-"
                                  }}
                                </div>
                                <div
                                  class="col-2 p-0 text-center"
                                  v-if="zeigeAnwesenheiten"
                                >
                                  {{
                                    sektionsfeld.anwesenheitsrate
                                      ? sektionsfeld.anwesenheitsrate + "%"
                                      : "-"
                                  }}
                                </div>
                                <div
                                  class="col-3 p-0 text-center word-break-all"
                                  v-html="getNotenanzeige(sektionsfeld)"
                                  v-tooltip.hover
                                  :title="getPruefungen(sektionsfeld)"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row m-2">
                    <div class="col-12 p-0 d-flex">
                      <!-- PROMOTIONSSTATUS UND SCHNITT-->
                      <div
                        class="col-4 p-0 d-flex text-bold"
                        v-if="zeigePromotionsrelevanteSektionsgruppen"
                      >
                        <div class="col-8 p-0 pl-2 d-flex align-items-center">
                          {{
                            row.item.notenpromotion.promotionsstatus.bezeichnung
                          }}
                        </div>
                        <div
                          class="col-2 p-0 text-center d-flex justify-content-center align-items-center"
                        >
                          {{
                            row.item.notenpromotion.gesamt_schnitt &&
                            row.item.notenpromotion.gesamt_schnitt != "0.0"
                              ? row.item.notenpromotion.gesamt_schnitt
                              : "-"
                          }}
                        </div>
                      </div>
                      <!-- TOGGLE BUTTONS -->
                      <div class="col ml-auto d-flex">
                        <div
                          class="btn ml-auto mr-2"
                          @click="zeigeAnwesenheiten = true"
                          v-if="!zeigeAnwesenheiten"
                        >
                          Zeige Anwesenheiten
                        </div>
                        <div
                          class="btn btn-primary ml-auto mr-2"
                          @click="zeigeAnwesenheiten = false"
                          v-if="zeigeAnwesenheiten"
                        >
                          Zeige Anwesenheiten
                        </div>
                        <div
                          class="btn mr-2"
                          @click="zeigeGewichtungen = true"
                          v-if="!zeigeGewichtungen"
                        >
                          Zeige Gewichtungen
                        </div>
                        <div
                          class="btn btn-primary mr-2"
                          @click="zeigeGewichtungen = false"
                          v-if="zeigeGewichtungen"
                        >
                          Zeige Gewichtungen
                        </div>
                        <div
                          class="btn"
                          @click="zeigePromotionsregeln = true"
                          v-if="!zeigePromotionsregeln && lehrgang.regelsatz"
                        >
                          Zeige Promotionsregeln
                        </div>
                        <div
                          class="btn btn-primary"
                          @click="zeigePromotionsregeln = false"
                          v-if="zeigePromotionsregeln && lehrgang.regelsatz"
                        >
                          Zeige Promotionsregeln
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    getAnzuzeigendeSektionsgruppen(row.item.sektionsgruppen)
                      .length == 0
                  "
                >
                  <div class="row m-2">
                    <div
                      class="col-12 text-center font-italic"
                      v-if="zeigePromotionsrelevanteSektionsgruppen"
                    >
                      Keine promotionsrelevanten Sektionsgruppen vorhanden.
                    </div>
                    <div
                      class="col-12 text-center font-italic"
                      v-if="!zeigePromotionsrelevanteSektionsgruppen"
                    >
                      Keine nicht-promotionsrelevanten Sektionsgruppen
                      vorhanden.
                    </div>
                  </div>
                </div>
                <!-- PROMOTIONSREGELN -->
                <div
                  class="row m-2 my-4 border-top border-bottom py-3"
                  v-if="zeigePromotionsregeln && lehrgang.regelsatz"
                >
                  <div class="col-12 p-0 mb-2 text-bold">
                    Promotionsregel
                    {{
                      lehrgang.regelsatz.bezeichnung
                        ? lehrgang.regelsatz.bezeichnung
                        : ""
                    }}
                  </div>
                  <div
                    class="col-12 mb-4"
                    v-if="promotionsregelnButtons.length > 1"
                  >
                    <div
                      class="btn mr-2"
                      :class="button.active ? 'btn-primary' : 'btn'"
                      v-for="button in promotionsregelnButtons"
                      :key="button.key"
                      @click="setPromotionsregelbuttonActive(button)"
                    >
                      {{ button.label }}
                    </div>
                  </div>
                  <div class="col-12">
                    <div
                      v-for="button in promotionsregelnButtons"
                      :key="button.key + 'value'"
                    >
                      <div
                        v-if="button.active"
                        v-html="
                          getPromotionsregelText(lehrgang.regelsatz[button.key])
                        "
                      />
                    </div>
                  </div>
                </div>

                <!-- BEMERKUNGEN -->
                <div class="row mx-2 mt-3 mb-0">
                  <div class="col-12 p-0 mb-2 text-bold">
                    <span>{{ $t("global.comment") }}</span>
                  </div>
                  <div class="col-12 p-0">
                    <textarea
                      class="col-12 input-lg"
                      type="text"
                      :placeholder="$t('global.comment')"
                      v-model="row.item.notenpromotion.bemerkung"
                    />
                  </div>
                </div>
              </b-card>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <pdf-layer
      id="pdf-layer"
      ref="pdfLayerNotenuebersicht"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
      :files="pdfFiles"
    ></pdf-layer>
    <datum-pdf-modal id="datum-pdf-modal" @ok="generiereCarboneReport" />
    <konfig-notenuebersicht-modal
      :id="'konfig-notenuebersicht-modal'"
      :lehrgangId="lehrgangid"
      :editable="true"
    />
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";

import Api from "@/Api";
import App from "@/AppApi";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import page from "@/mixins/Page";
import server from "@/server";

import FileDownload from "js-file-download";

import { LADE_AUSWAHLWERTE_NOTEN } from "@/store/noten/actions.type";
import CarboneApi from "@/CarboneApi";
import { BSpinner } from "bootstrap-vue";

import DatumPdfModal from "@/components/Modals/DatumPdfModal.vue";
import KonfigNotenuebersichtModal from "@/components/Modals/KonfigNotenuebersichtModal.vue";


export default {
  name: "Endnotenuebersicht",
  components: {
    HeadMenu,
    Navbar,
    BSpinner,
    DatumPdfModal,
    KonfigNotenuebersichtModal
  },

  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.finalgradeoverview");
      },
    };
  },
  mixins: [page],
  props: {
    lehrgangid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      excelFile: {},
      date: new Intl.DateTimeFormat("ch-DE").format(new Date()),
      pdfPath: "",
      pdfFiles: [],
      bearbeitungGesperrt: false,
      notenpromotionen: [],
      selectedAnmeldungsIds: [],
      allAnmeldungenSelected: null,
      ladeFehler: false,
      isBusy: false,
      sortBy: "person.nachname",
      sortDesc: false,
      lehrgang: {
        bezeichnung: null,
      },
      filters: {},
      backgroundcolors: [
        "#F0F3FA",
        "#F2FAF9",
        "#F4F0EC",
        "#F8F3F9",
        "#F0F2EB",
        "#E7EBEA",
        "#F9F6F7",
        "#FAF5F2",
        "#F2F8F3",
        "#F9F7F5",
        "#F8F4E9",
        "#EEF1F0",
        "#F5F0F6",
        "#FAF2E9",
        "#F5E6E8",
      ],
      zeigeAnwesenheiten: false,
      zeigeGewichtungen: false,
      zeigePromotionsregeln: false,
      zeigePromotionsrelevanteSektionsgruppen: true,
      notenberechnung: null,
      promotionsregelnButtons: [],
      category: null,
      templateID: null,
      endpoint: null,
      carboneDate: null,
    };
  },
  computed: {
    promotionstati: {
      get() {
        return this.$store.state.noten.promotionstati;
      },
    },
    filteredNotenpromotionen() {
      if (this.notenpromotionen.length === 0) return this.notenpromotionen;

      return this.notenpromotionen.filter((item) => {
        return Object.keys(this.filters).every((key) => {
          if (!this.filters[key]) return true;
          const filterValue = this.filters[key];
          const itemValue = this.getNestedValue(item, key);

          if (typeof filterValue === "object") {
            return itemValue.id === filterValue.id;
          } else {
            return itemValue
              .toString()
              .toLowerCase()
              .includes(filterValue.toString().toLowerCase());
          }
        });
      });
    },
    fields() {
      return [
        { key: "selected", label: "" },
        "nr",
        {
          key: "person.nachname",
          searchable: true,
          label: this.$t("global.surname"),
          sortable: true,
        },
        {
          key: "person.vorname",
          searchable: true,
          label: this.$t("global.firstname"),
          sortable: true,
        },
        {
          key: "person.typ",
          label: this.$t("global.type"),
          sortable: true,
        },
        {
          key: "person.abschluss",
          label: this.$t("global.degree"),
          sortable: true,
        },
        {
          key: "notenpromotion.gesamt_schnitt",
          sortable: true,
          label: "∅",
          tdClass: (value, key, item) => {
            return this.setNotenColorDurchschnittsnote(item);
          },
          thClass: "text-center",
        },
        {
          key: "gesamtanwesenheit",
          sortable: true,
          label: this.$t("global.presence"),
          tdClass: (value, key, item) => {
            return this.setNotenColorDurchschnittsanwesenheit(item);
          },
          thClass: "text-center",
        },
        {
          key: "notenpromotion.promotionsstatus.bezeichnung",
          select: true,
          options: this.promotionstati,
          searchable: true,
          sortable: true,
          label: this.$t("global.promotionstatus"),
          tdClass: (value, key, item) => {
            return this.setPromotionsstatusColor(item);
          },
        },
        {
          key: "notenpromotion_promotionsstatus_definitiv_bezeichnung",
          select: true,
          options: this.promotionstati,
          searchable: true,
          sortable: true,
          label: this.$t("global.conferencedecision"),
          tdClass: (value, key, item) => {
            return this.setPromotionsstatusColor(item);
          },
        },
        {
          key: "show_details",
          label: this.$t("global.details"),
        },
      ];
    },
    getBezeichnungWidth() {
      if (this.zeigeAnwesenheiten && this.zeigeGewichtungen) {
        return "col-5";
      } else if (this.zeigeAnwesenheiten || this.zeigeGewichtungen) {
        return "col-7";
      }
      return "col-9";
    },
  },
  watch: {},
  created() {
    this.$store.dispatch(`noten/${LADE_AUSWAHLWERTE_NOTEN}`);
    this.getNotenpromotion();
  },
  mounted() {},
  methods: {
    getPromotionsregelText(regelsatz) {
      let text = "";

      /* Mindestanwesenheit */
      if (regelsatz.anwesenheit != 0) {
        text += `Mindestanwesenheit: <strong>${regelsatz.anwesenheit}%</strong><br>`;
      } else {
        text += "<strong>Keine</strong> Mindestanwesenheit definiert<br>";
      }
      /* Anzahl ungenügende */
      if (regelsatz.anzahl_ungenuegende != 0) {
        text += `Maximale Anzahl ungenügender Noten: <strong>${regelsatz.anzahl_ungenuegende}</strong><br>`;
      } else {
        text +=
          "<strong>Keine</strong> maximale Anzahl ungenügender Noten definiert<br>";
      }
      /* Tiefste Note */
      if (regelsatz.min_note != 0) {
        text += `Keine Note unter <strong>${regelsatz.min_note}</strong><br>`;
      } else {
        text += "<strong>Keine</strong> minimale Note definiert<br>";
      }
      /* Notendurchschnitt */
      if (regelsatz.notendurchschnitt != 0) {
        text += `Notendurchschnitt > <strong>${regelsatz.notendurchschnitt}</strong><br>`;
      } else {
        text +=
          "<strong>Kein</strong> minimaler Notendurchschnitt definiert<br>";
      }
      /* Textwahlwert */
      if (
        regelsatz.textwahl_wert &&
        regelsatz.textwahl_wert.bezeichnung &&
        regelsatz.textwahl_wert.bezeichnung != ""
      ) {
        text += `Der Textwahlwert <strong>${regelsatz.textwahl_wert.bezeichnung}</strong> wird als bestandene Promotion gewertet<br>`;
      } else {
        text +=
          "<strong>Kein</strong> Textwahlwert für bestandene Promotion definiert definiert<br>";
      }

      return text;
    },
    setPromotionsregelbuttonActive(button) {
      this.promotionsregelnButtons.forEach((btn) => {
        btn.active = false;
      });

      button.active = true;
    },
    selectAllAnmeldungen(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((item) => {
          item.selected = true;
        });
        this.selectedAnmeldungsIds = this.$refs.selectableTable.items.map(
          (item) => item.anmeldung_id
        );
      } else {
        this.$refs.selectableTable.items.forEach((a) => {
          a.selected = false;
        });
        this.selectedAnmeldungsIds = [];
      }

      this.allAnmeldungenSelected = Date.now();
    },
    onAnmeldungSelected() {
      if (this.allAnmeldungenSelected) {
        let timeDiff = Date.now() - this.allAnmeldungenSelected;
        if (timeDiff < 100) return;
      }

      this.selectedObjects = this.$refs.selectableTable.items.filter(
        (item) => item.selected
      );

      this.selectedAnmeldungsIds = this.$refs.selectableTable.items
        .filter((item) => item.selected)
        .map((item) => item.anmeldung_id);
    },
    endnotenBerechnen() {
      this.isBusy = true;

      App.post(
        "lehrgang/endnotenberechnung/",
        {},
        {
          params: { id: this.lehrgangid },
        }
      )
        .then((response) => {
          if (response.status === 200) {
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.gradecalculationperformed"),
            });
          }
          this.ladeFehler = false;
          this.getNotenpromotion();
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    getPruefungen(sektionsfeld) {
      if (sektionsfeld.pruefungsnoten) {
        let tooltip = "";
        sektionsfeld.pruefungsnoten.forEach((noteneingabe, index) => {
          if (noteneingabe.textwahl_wert && noteneingabe.textwahl_wert != "-") {
            tooltip +=
              noteneingabe.bezeichnung + ": " + noteneingabe.textwahl_wert;
          } else if (noteneingabe.note && noteneingabe.note != "0.0") {
            tooltip += noteneingabe.bezeichnung + ": " + noteneingabe.note;
          } else {
            tooltip += noteneingabe.bezeichnung + ": -";
          }

          if (index != sektionsfeld.pruefungsnoten.length - 1) tooltip += ", ";
        });
        return tooltip;
      }

      return null;
    },
    getNotenanzeige(bewertung) {
      const textwahl_wert = bewertung.textwahl_wert;
      const note = bewertung.note;

      if (textwahl_wert && textwahl_wert != "-") {
        if (textwahl_wert && note && note != "0.0") {
          return textwahl_wert + "<br>" + note;
        } else {
          return textwahl_wert;
        }
      } else if (note && note != "0.0") {
        return note;
      }

      return "-";
    },
    setBackgroundcolor(sektionsgruppenindex) {
      const colorIndex = sektionsgruppenindex % this.backgroundcolors.length;
      return { backgroundColor: this.backgroundcolors[colorIndex] };
    },
    getAnzuzeigendeSektionsgruppen(sektionsgruppen) {
      if (this.zeigePromotionsrelevanteSektionsgruppen) {
        return sektionsgruppen.filter(
          (sektionsgruppe) => sektionsgruppe.relevanz_berechnung === true
        );
      } else {
        return sektionsgruppen.filter(
          (sektionsgruppe) => sektionsgruppe.relevanz_berechnung === false
        );
      }
    },
    getNotenpromotion() {
      if (this.lehrgangid) {
        this.isBusy = true;

        App.get("lehrgang/endnotenberechnung/", {
          params: {
            id: this.lehrgangid,
          },
        })
          .then((response) => {
            this.lehrgang = response.data?.veranstaltung;
            this.notenpromotionen = response.data.notenpromotionen;
            this.notenberechnung = response.data;
            this.setPromotionsregelnButtons();
            this.ladeFehler = false;
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
            this.ladeFehler = true;
          })
          .finally(() => {
            this.isBusy = false;
          });
      } else {
        this.ladeFehler = true;
      }
    },
    setPromotionsregelnButtons() {
      if (!this.lehrgang.regelsatz) return;

      if (this.lehrgang.regelsatz.notenpromotion_regel) {
        this.promotionsregelnButtons.push({
          key: "notenpromotion_regel",
          label: "Notenpromotion",
          active: false,
        });
      }
      if (this.lehrgang.regelsatz.sektionsgruppe_regel) {
        this.promotionsregelnButtons.push({
          key: "sektionsgruppe_regel",
          label: "Sektionsgruppen",
          active: false,
        });
      }
      if (this.lehrgang.regelsatz.sektions_regel) {
        this.promotionsregelnButtons.push({
          key: "sektions_regel",
          label: "Sektion",
          active: false,
        });
      }
      if (this.lehrgang.regelsatz.sektionsfeld_regel) {
        this.promotionsregelnButtons.push({
          key: "sektionsfeld_regel",
          label: "Sektionsfeld",
          active: false,
        });
      }
      if (this.lehrgang.regelsatz.noteneingabe_regel) {
        this.promotionsregelnButtons.push({
          key: "noteneingabe_regel",
          label: "Noteneingaben",
          active: false,
        });
      }

      this.promotionsregelnButtons[0].active = true;
    },
    abbrechen() {
      this.$router.push({
        name: "bildungsgang",
        params: { lehrgangid: this.lehrgangid, anzeige: 0 },
      });
    },
    getNestedValue(obj, key) {
      return key.split(".").reduce((o, k) => (o || {})[k], obj);
    },
    removeOptionLabel(key) {
      return key.replace(/\.bezeichnung$/, "");
    },

    speichern() {
      if (this.isBusy) return;

      this.isBusy = true;

      let json = Object.assign({}, this.notenberechnung);

      App.put("veranstaltung/notenberechnung/", json)
        .then((response) => {
          this.lehrgang = response.data?.veranstaltung;
          this.notenpromotionen = response.data.notenpromotionen;
          this.notenberechnung = response.data;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    erstelleExcel() {
      Api.post(
        "/noten/notenexport/",
        {
          notenuebersichtdata: this.notenberechnung,
        },
        { responseType: "blob" }
      )
        .then((response) => {
          this.excelFile = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          FileDownload(
            this.excelFile,
            `${
              this.lehrgang?.bezeichnung
            }_${new Date().toLocaleDateString()}.xlsx`
          );
          this.loading = false;
        })
        .catch(() => {
          setTimeout(() => {
            this.$notify({
              type: "error",
              title: this.$t("global.actionfailed"),
              text: this.$t("global.errorwhilecreatingtheexcelfile"),
            });
          }, 2000);
          this.loading = false;
        });
    },
    validCarbonePrintouts(constant) {
      const validCarbonePrintouts = [];
      for (const key in constant) {
        const carboneprint = constant[key];
        if (this.isValidCarbonePrintout(carboneprint)) {
          validCarbonePrintouts.push(carboneprint);
        }
      }
      return validCarbonePrintouts;
    },
    isValidCarbonePrintout(printout) {
      let isValid = false;
      if (!this.lehrgang) return isValid;

      let institution = printout.appkey;
      let index = institution.indexOf("_");
      if (index !== -1) {
        institution = institution.substring(0, index);
      }

      if (
        this.lehrgang.institution &&
        this.lehrgang.institution.kuerzel.toLowerCase() ===
          institution.toLowerCase()
      ) {
        isValid = true;
      } else {
        return false;
      }

      let isHandelsdiplom = printout.appkey.includes("handelsdiplom");

      if (isHandelsdiplom) {
        isValid = true;
      } else {
        isValid = false;
      }

      let isZeugnis = printout.appkey.includes("zeugnis");

      if (isZeugnis) {
        isValid = false;
      }

      return isValid;
    },
    oeffnePrintoutDatumModal(category, templateID, endpoint) {
      if (this.selectedAnmeldungsIds.length === 0) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nopersonselected"),
        });

        return;
      }

      this.category = category;
      this.templateID = templateID;
      this.endpoint = endpoint;

      this.$bus.$emit("open-modal", "datum-pdf-modal");
    },
    generiereCarboneReport(date) {
      this.carboneDate = date;
      this.carbone();
    },
    carbone() {
      let api =
        this.endpoint +
        this.selectedAnmeldungsIds +
        "&category=" +
        this.category +
        "&templateId=" +
        this.templateID +
        "&date=" +
        this.carboneDate;

      CarboneApi.get(api)
        .then((response) => {
          this.pdfFiles = response.data.map((item) => ({
            url: server.url + item.url,
            name: item.name,
            count: item.count,
            size: item.name,
          }));

          this.pdfFiles.length > 0
            ? this.$refs.pdfLayerNotenuebersicht.show()
            : this.$notify({
                type: "warn",
                title: this.$t("global.actionfailed"),
                text: "Es ist ein Fehler aufgetreten",
              });
        })
        .catch((error) => {
          console.error("Error fetching PDF files:", error);
        })
        .finally(() => {
          this.$refs.pdfLayerNotenuebersicht.loading = false;
        });
    },
    setNotenColorDurchschnittsnote(item) {
      const promotionsregelGesamtdurchschnitt = this.lehrgang?.regelsatz
        ?.notenpromotion_regel?.notendurchschnitt
        ? this.lehrgang?.regelsatz?.notenpromotion_regel?.notendurchschnitt
        : this.lehrgang?.regelsatz?.sektionsgruppe_regel?.notendurchschnitt;

      let textColorClass = "text-center text-bold";

      if (promotionsregelGesamtdurchschnitt) {
        if (
          item.notenpromotion?.gesamt_schnitt >=
          promotionsregelGesamtdurchschnitt
        ) {
          textColorClass = textColorClass.concat(" ", "text-success");
        } else {
          textColorClass = textColorClass.concat(" ", "text-danger");
        }
      }

      return textColorClass;
    },
    setNotenColorDurchschnittsanwesenheit(item) {
      const promotionsregelGesamtanwesenheit = this.lehrgang?.regelsatz
        ?.notenpromotion_regel?.anwesenheit
        ? this.lehrgang?.regelsatz?.notenpromotion_regel?.anwesenheit
        : this.lehrgang?.regelsatz?.sektionsgruppe_regel?.anwesenheit;

      let textColorClass = "text-center text-bold";

      if (promotionsregelGesamtanwesenheit) {
        if (item.gesamtanwesenheit >= promotionsregelGesamtanwesenheit) {
          textColorClass = textColorClass.concat(" ", "text-success");
        } else {
          textColorClass = textColorClass.concat(" ", "text-danger");
        }
      }

      return textColorClass;
    },
    setPromotionsstatusColor(item) {
      const PROMOVIERT = "1758911D3C2";
      const BEDINGT_PROMOVIERT = "1758911D3C4";
      const NICHT_PROMOVIERT = "1758911D3C6";

      let textColorClass = "text-bold";

      switch (item.notenpromotion?.promotionsstatus?.id) {
        case PROMOVIERT:
          textColorClass = textColorClass.concat(" ", "text-success");
          break;
        case BEDINGT_PROMOVIERT:
          textColorClass = textColorClass.concat(" ", "text-warning");
          break;
        case NICHT_PROMOVIERT:
          textColorClass = textColorClass.concat(" ", "text-danger");
      }

      return textColorClass;
    },

    openConfigGradesOverview() {
      this.$bus.$emit("openKonfigModal", this.lehrgang);
    }
  },
};
</script>

<style scoped>
.fixed-table {
  table-layout: fixed;
  width: 100%;
}
</style>
