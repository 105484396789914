<template>
  <div class="list">
    <notifications></notifications>
    <navbar :docsName="docsName" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>

            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters['auftrag.auftragsnummer']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.ordernum") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :show-icon="true"
                        :initial="filters.mahndatumVon"
                        field="mahndatumVon"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.dunningdatefrom") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :show-icon="true"
                        :initial="filters.mahndatumBis"
                        field="mahndatumBis"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.dunningdateuntil") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.bruttobetragVon"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.grossamountfrom") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.bruttobetragBis"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.amountupto") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.mahnstufestatus"
                        :options="mahnstufestati"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.dunninglevelstatus')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.nostatusfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.dunninglevelstatus") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.zahlungstatus"
                        :options="zahlungstati"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.paymentstatus')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.nostatusfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.statuspayment") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.reminders')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
              :gesamtanzahl="gesamtanzahl"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row mt-2 d-flex justify-content-between">
                  <div class="ml-4">
                    <b-dropdown variant="primary mr-2">
                      <template slot="button-content">
                        <font-awesome-icon
                          icon="fa-duotone fa-print"
                          class="mr-2"
                        />
                        {{ $t("global.printselected") }}
                      </template>
                      <b-dropdown-item
                        @click="ausgebenMahnungPdfCache('MAHNUNG1', true)"
                        v-if="$CONST('PRINTOUTS').MAHNUNG1 != null"
                      >
                        {{ $CONST("PRINTOUTS").MAHNUNG1_LABEL }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="ausgebenMahnungPdfCache('MAHNUNG2', true)"
                        v-if="$CONST('PRINTOUTS').MAHNUNG2 != null"
                      >
                        {{ $CONST("PRINTOUTS").MAHNUNG2_LABEL }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="ausgebenMahnungPdfCache('MAHNUNG3', true)"
                        v-if="$CONST('PRINTOUTS').MAHNUNG3 != null"
                      >
                        {{ $CONST("PRINTOUTS").MAHNUNG3_LABEL }}
                      </b-dropdown-item>
                    </b-dropdown>
                    <button
                      class="btn btn-primary"
                      @click="exportToExcel(listData, 'Mahnungen')"
                    >
                      <span class="mr-2"
                        ><font-awesome-icon
                          icon="fa-regular fa-file-spreadsheet"
                      /></span>
                      {{ $t("global.export") }}
                    </button>
                  </div>
                  <div class="ml-4 mr-4">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_finanzen.delete"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-1"
                      />
                      {{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-compare="sortDates"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="openMahnung"
                    thead-tr-class="pointer"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td
                        class="no-pointer"
                        v-for="field in fields"
                        :key="field.key"
                      >
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>

            <div id="modal-loeschen" class="modal fade">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4>
                      <font-awesome-icon
                        icon="fa-regular fa-warning"
                        class="mr-2 text-warning"
                      />
                      {{ $t("global.deleteincomingpayment") }}
                    </h4>
                  </div>
                  <div class="modal-body">
                    {{ $t("global.doyouwantdeletereminders") }}
                  </div>
                  <div class="modal-footer">
                    <button
                      class="btn btn-default"
                      @click="schliesseLoeschenModal"
                    >
                      {{ $t("global.cancel") }}
                    </button>
                    <button class="btn btn-primary" @click="loeschen">
                      {{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <esr-upload-modal :id="EsrUploadModalId"></esr-upload-modal>
    <single-select-zahlungseingang
      :id="this.id"
      :betrag="betrag"
      @confirmed="loadTable"
    />

    <pdf-layer
      id="pdf-layer"
      ref="pdfLayer"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
      :files="pdfFiles"
    ></pdf-layer>

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";

import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";
import excelExport from "@/mixins/ExcelExport";

import server from "@/server";
import numeral from "numeral";
import EsrUploadModal from "../../components/Modals/EsrUploadModal.vue";
import SingleSelectZahlungseingang from "@/pages/Finanzen/Modals/SingleSelectZahlungseingang.vue";

import { LADE_AUSWAHLWERTE_DEBITOR } from "@/store/debitor/actions.type";

import DatePicker from "@/components/Datepicker";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

export default {
  name: "Mahnungen",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    EsrUploadModal,
    DatePicker,
    SingleSelectZahlungseingang,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.reminders");
      },
    };
  },
  mixins: [page, dashboard, filter, excelExport],
  props: {},
  store,
  data() {
    return {
      id: null,
      betrag: 0,
      pdfPath: "",
      pdfFiles: [],

      EsrUploadModalId: "esr-modal",
      SingleSelectZahlungseingangModalId: "single-select-modal",
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "auftrag.auftragsnummer",
          sortable: true,
          label: this.$t("global.ordernum"),
        },
        {
          key: "mahndatum",
          sortable: true,
          label: this.$t("global.dunningdate"),
        },
        {
          key: "mahnadresse",
          searchable: true,
          sortable: false,
          label: this.$t("global.dunningaddress"),
        },
        {
          key: "bruttobetrag",
          sortable: true,
          label: this.$t("global.grossamount"),
          formatter: (value) => {
            if (value) {
              return numeral(value).format("#,##0.00");
            } else {
              return "-";
            }
          },
        },
        {
          key: "mahnstufestatus.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.dunninglevelstatus"),
        },
        {
          key: "zahlungstatus.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.paymentstatus"),
        },
      ];
    },
    mahnstufestati: {
      get() {
        return this.$store.state.debitor.mahnstufestati;
      },
    },
    zahlungstati: {
      get() {
        return this.$store.state.debitor.zahlungstati;
      },
    },
    queryParams: function () {
      var params = {};

      params.filterBetrag = this.filters.bruttobetrag;
      params.filterBetragVon = this.filters.bruttobetragVon;
      params.filterBetragBis = this.filters.bruttobetragBis;
      params.filterAuftragnr = this.filters["auftrag.auftragsnummer"];
      params.filterMahnstufestatus = this.setQueryParamList(
        this.filters.mahnstufestatus
      );
      params.filterZahlungstatus = this.setQueryParamList(
        this.filters.zahlungstatus
      );
      params.filterMahnadresse = this.filters.mahnadresse;
      params.filterMahndatum = this.filters.mahndatum;

      if (this.filters.mahndatumVon && this.filters.mahndatumVon != "..")
        params.filterMahndatumVon = this.filters.mahndatumVon;

      if (this.filters.mahndatumBis && this.filters.mahndatumBis != "..")
        params.filterMahndatumBis = this.filters.mahndatumBis;

      if (this.filters.count != "") params.filterCount = this.filters.count;

      return params;
    },
  },
  created() {
    this.initFilter("mahnungen-liste", "finanzen/mahnungen/", true);
  },
  mounted() {
    this.filterSearch();
    this.$bus.$emit("searchWithFilter");
    if (this.mahnstufestati.length == 0)
      this.$store.dispatch(`debitor/${LADE_AUSWAHLWERTE_DEBITOR}`);
  },
  methods: {
    ausgebenMahnungPdfCache(mahnungKey, selected) {
      this.pdfFiles = [];

      let printIds = selected
        ? this.$refs.selectableTable.items
            .filter((item) => item.selected)
            .map((item) => item.id)
        : this.$refs.selectableTable.items.map((item) => item.id);

      if (printIds.length == 0) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noreminderselected"),
        });
        return;
      }

      if (printIds.length > 2000) {
        this.$notify({
          type: "warn",
          title: this.$t("global.requestcouldnotbeexecuted"),
          text: this.$t("global.toomanyrecordsselected"),
        });
        return;
      }

      if (printIds > 100) {
        let index = 1,
          counter = parseInt(printIds.length / 100, 10);

        if (parseInt(printIds.length / 100, 10) != printIds.length / 100)
          counter++;

        for (var i = 0; i < printIds.length; i += 100) {
          let subList = printIds.slice(i, i + 100);
          let ids = subList.join(",");

          this.generatePDF(mahnungKey, index, ids, counter, subList.length);
          index++;
        }
      } else {
        let ids = printIds.join(",");
        this.generatePDF(mahnungKey, 1, ids, 1, printIds.length);
      }

      this.$refs.pdfLayer.show();
    },

    generatePDF(mahnungKey, index, ids, counter, size) {
      let json = {
        idlist: ids,
      };

      Api.post("printcache/", json, { params: {} }).then((response) => {
        let cacheId = response.data["cache-id"];
        let filename = new Intl.DateTimeFormat("de-ch").format(new Date());

        let pdfPath =
          "pdfgenerator/pdfcache.act?filename=ZE" +
          filename +
          ".pdf&report=/api" +
          this.$CONST("PRINTOUTS")[mahnungKey] +
          "&cache=" +
          cacheId;

        Api.get(pdfPath).then((response) => {
          let retValue = {
            index: index,
            size: size,
            url:
              (server.url && server.url != "" ? server.url : "") +
              response.data.filepath,
            withCredentials: true,
            count: counter,
          };

          this.pdfFiles.push(retValue);
          console.log(retValue);
        });
      });
    },

    zahlungseingangBuchen(selectedIds) {
      Api.post("", {
        params: selectedIds,
      }).then(() => {
        this.$notify({
          type: "success",
          title: this.$t("global.actionsuccessful"),
          text: this.$t("global.incomingpaymentssuccessfullyposted"),
        });
      });
    },

    openMahnung(mahnungen) {
      this.$router.push({
        name: "mahnung",
        params: { mahnungid: mahnungen.id, anzeige: 0 },
      });
    },

    loadTable() {
      // this.resetLoading(this);
    },

    oeffneZahlungseingang() {
      if (this.id) {
        this.$router.push({
          path: "/finanzen/Zahlungseingang/" + this.id,
        });
      }
    },

    ausgebenMahnungen: function () {
      if (this.selectedIds.length > 0) {
        this.selectedIds = Array.from(new Set(this.selectedIds));
        let filename = new Intl.DateTimeFormat("ch").format(new Date());

        this.pdfPath =
          server.url +
          `/pdfgenerator/pdf.act?filename=MG${filename}.pdf&report=/pdfgenerator/mahnung.htm&ids=${this.selectedIds}`;
        this.$refs.pdfLayer.show();
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noreminderselected"),
        });
      }
    },

    ausgebenMahnungMitEZ: function () {
      if (this.selectedIds.length > 0) {
        let filename = new Intl.DateTimeFormat("ch").format(new Date());
        this.selectedIds = Array.from(new Set(this.selectedIds));

        (this.pdfPath =
          server.url +
          `/pdfgenerator/pdf.act?filename=MG${filename}.pdf&report=/pdfgenerator/mahnung-ez.htm&ids=${this.selectedIds}`),
          this.$refs.pdfLayer.show();
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noreminderselected"),
        });
      }
    },

    oeffneEsrUploadModal() {
      $("#esr-modal").modal("show");
    },

    schliesseEsrUploadModal() {
      $("#esr-modal").modal("hide");
      this.resetLoading(this);
      this.$notify({
        type: "success",
        title: this.$t("global.actionsuccessful"),
        text: this.$t("global.esrfilereadinsuccessfully"),
      });
    },

    oeffneSingleSelectModal() {
      if (this.id) {
        $("#zahlungseingang-zuweisen-modal").modal("show");
        this.$bus.$emit("zahlungseingang-zuweisen-modal", null);
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionnotexecutable"),
          text: this.$("global.pleasechooseanincomingpayment"),
        });
      }
    },

    schliesseLoeschenModal() {
      $("#modal-loeschen").modal("hide");
    },

    oeffneLoeschenModal() {
      $("#modal-loeschen").modal("show");
    },

    loeschen() {
      Api.delete("finanzen/mahnungen/", { data: this.selectedIds }).then(() => {
        this.schliesseLoeschenModal();
        this.$notify({
          type: "success",
          title: this.$t("global.actionsuccessful"),
          text: this.$t("global.remindersdeleted"),
        });
        this.listData = this.listData.filter(
          (v) => !this.selectedIds.includes(v.id)
        );
      });
    },
  },
};
</script>

<style></style>
