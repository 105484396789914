<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :docsName="docsName" />

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-if="editable"
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>

                  <button
                    class="btn btn-primary mr-2"
                    :disabled="invalid || !id"
                    @click="ausgebenKorrespondenzPdfCache(false)"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-print"
                      class="mr-2"
                    />
                    {{ $t("global.print") }}
                  </button>

                  <button
                    v-if="massSelected > 0"
                    class="btn btn-primary mr-2"
                    :disabled="invalid"
                    @click="ausgebenKorrespondenzPdfCache(true)"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-print"
                      class="mr-2"
                    />
                    Einzeldruck ({{ massSelected }})
                  </button>

                  <button
                    class="btn btn-primary mr-2"
                    @click="vorlageWaehlen"
                    v-if="editable"
                  >
                    {{ $t("global.selecttemplate") }}
                  </button>

                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>
                  <button
                    class="btn btn-primary mr-2"
                    @click="goToHelpPage"
                    v-tooltip
                    :title="$t('global.textcodeshelppage')"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-circle-info"
                      class="mr-2"
                    />
                    {{ $t("global.help") }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.m_korrespondenz.delete"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-trash"
                      class="mr-2"
                    />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <empfaenger
          v-if="korrespondenz.isMassKorrespondenz"
          :korrespondenz="korrespondenz"
          :key="'empfaenger' + empfaengerKey"
          :editable="editable"
          :langs="langs"
          @updated="updatedEmpfaenger"
          @korrespondenz-teilnehmer-update="setTeilnehmer"
          @updatedTeilnehmer="setTeilnehmer"
        />

        <korrespondenz-empfaenger-absender
          v-if="!korrespondenz.isMassKorrespondenz"
          :korrespondenz="korrespondenz"
          :key="'empfaenger' + empfaengerKey"
          :editable="editable"
          :langs="langs"
          @updated="updatedEmpfaenger"
        />

        <!--<massen-korrespondenz-empfaenger-absender
          v-if="korrespondenz.isMassKorrespondenz"
          :korrespondenz="korrespondenz"
          :key="'empfaenger' + empfaengerKey"
          :editable="editable"
          @updated="updatedSelected"
        />-->
        <div v-if="!korrespondenz.versand.isVorlage" class="col-xl-12 p-0">
          <div class="col-xl-12 p-0 d-flex">
            <div class="col-2 p-0 mr-1">
              <div class="col p-0">
                <div class="block block-kopf bg-medium-blue">
                  <span class="col primary-headline-text p-0">
                    {{ $t("global.content") }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <korrespondenz-inhalt
            :key="inhaltKey"
            :korrespondenz="korrespondenz"
            :editable="editable"
            @unterschrift1updated="unterschrift1Updated"
            @unterschrift2updated="unterschrift2Updated"
          />
        </div>
        <div v-if="korrespondenz.versand.isVorlage" class="col-xl-12 p-0">
          <div class="col-xl-12 p-0 d-flex">
            <div
              v-for="(lang, index) in langs"
              :key="'headline-' + index"
              class="col-2 p-0 mr-1"
            >
              <div class="col clickable p-0" @click="setActiveLang(index)">
                <div
                  class="block block-kopf bg-medium-blue"
                  :class="activeLang === index ? '' : 'bg-grayscale-5 hover'"
                >
                  <span class="col primary-headline-text p-0">
                    {{ $t("global.content") + " " + lang.kuerzel }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-12 p-0">
            <div
              v-for="(lang, index) in langs"
              :key="index"
              class="col-xl-12 p-0"
            >
              <korrespondenz-inhalt
                v-if="activeLang === index"
                :key="inhaltKey"
                :lang="lang"
                :korrespondenz="korrespondenz"
                :editable="editable"
                @unterschrift1updated="unterschrift1Updated"
                @unterschrift2updated="unterschrift2Updated"
              />
            </div>
          </div>
        </div>
      </div>
      <unsaved-changes-modal
        @discard="handleDiscard"
        @stay="handleStay"
        :saveFunction="invalid == true ? null : speichern"
      />
    </ValidationObserver>

    <loeschen-modal
      id="korrespondenz-loeschen-modal"
      @confirmed="korrespondenzLoeschen"
    />

    <korrespondenz-vorlage-modal @confirmed="vorlageSetzen" />
    <pdf-layer
      id="pdf-layer"
      ref="pdfLayer"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
      :files="pdfFiles"
    ></pdf-layer>
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";
import Api from "@/Api";
import store from "@/store";

import { LADE_AUSWAHLWERTE_KORRESPONDENZ } from "@/store/korrespondenz/actions.type";
import { LADE_AUSWAHLWERTE_EINSTELLUNGEN } from "@/store/einstellungendashboard/actions.type";

import page from "@/mixins/Page";

import LoeschenModal from "@/components/Modals/LoeschenModal";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import server from "@/server";

import KorrespondenzEmpfaengerAbsender from "@/components/Kommunikation/KorrespondenzEmpfaengerAbsender.vue";
import MassenKorrespondenzEmpfaengerAbsender from "@/components/Kommunikation/MassenKorrespondenzEmpfaengerAbsender.vue";
import KorrespondenzInhalt from "@/components/Kommunikation/KorrespondenzInhalt.vue";
import Empfaenger from "@/components/Kommunikation/Empfaenger.vue";
import KorrespondenzVorlageModal from "@/components/Modals/KorrespondenzVorlageModal.vue";
import ChangeWatcherNew from "@/mixins/ChangewatcherNew";
import UnsavedChangesModal from "@/components/Modals/UnsavedChangesModal.vue";

export default {
  name: "Korrespondenz",
  components: {
    HeadMenu,
    Navbar,
    LoeschenModal,
    KorrespondenzEmpfaengerAbsender,
    KorrespondenzInhalt,
    KorrespondenzVorlageModal,
    //MassenKorrespondenzEmpfaengerAbsender,
    Empfaenger,
    UnsavedChangesModal,
  },
  mixins: [page, ChangeWatcherNew],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      korrespondenz: {
        person: [{ personName: this.$t("global.notlinked") }],
        firma: [{ firmaName: this.$t("global.notlinked") }],
        versand: {
          erstelltvon: {},
          datum: new Intl.DateTimeFormat("de").format(new Date()),
          betreff: "",
          ort: "",
          unterschrift1: {
            person: { personName: this.$t("global.notlinked") },
          },
          unterschrift2: {
            person: { personName: this.$t("global.notlinked") },
          },
          inhalt: "",
          translation: {
            de: { betreff: "", text: "" },
            en: { betreff: "", text: "" },
            fr: { betreff: "", text: "" },
            it: { betreff: "", text: "" },
          },
          isVorlage: false,
          defaultSprache: "",
        },
      },
      teilnehmer: [],
      selectedIds: [],
      pdfPath: "",
      pdfFiles: [],
      inhaltKey: 0,
      empfaengerKey: 0,
      massSelected: 0,
      loading: false,
      editable: true,
      activeLang: 0,
    };
  },
  computed: {
    langs: {
      get() {
        return this.$store.state.einstellungendashboard.sprachen;
      },
    },
    navbarTitel: function () {
      return this.$t("global.correspondence");
    },
    verarbeitungsstati: {
      get() {
        return this.$store.state.korrespondenz.verarbeitungsstati;
      },
    },
    defaultSprache() {
      return this.langs.find((lang) => {
        return lang.id === this.korrespondenz.versand.defaultSprache;
      });
    },
    inhaltVorhanden() {
      if (
        this.korrespondenz.versand.translation[this.defaultSprache.kuerzel]
          .text &&
        this.korrespondenz.versand.translation[this.defaultSprache.kuerzel]
          .betreff
      ) {
        return true;
      } else return false;
    },
  },
  watch: {
    kategorie(val) {
      if (val && !this.nummer && !this.id) {
        Api.get("bildungskategorien/naechste_nummer/", {
          params: { kategorie: val },
        }).then((response) => {
          this.nummer = response.data.nummer;
        });
      }
    },
  },

  created() {
    if (this.id != null) {
      Api.get("korrespondenz/", { params: { id: this.id } }).then(
        (response) => {
          this.initializeKorrespondenz(response.data);
        }
      );
    } else {
      this.initializeKorrespondenz(this.korrespondenz);
    }
  },

  mounted: function () {
    if (this.langs.length == 0) {
      this.$store.dispatch(
        `einstellungendashboard/${LADE_AUSWAHLWERTE_EINSTELLUNGEN}`
      );
    }
    if (this.verarbeitungsstati.length == 0)
      this.$store.dispatch(`korrespondenz/${LADE_AUSWAHLWERTE_KORRESPONDENZ}`);

    if (this.korrespondenz.versand.erstelltvon.id == null) {
      this.korrespondenz.versand.ort = this.$CONST("CONFIG").MANDANTSTADT;
      this.korrespondenz.verarbeitungsstatus = this.$CONST("CONFIG").KORVSTATUS;
      this.korrespondenz.versand.erstelltvon =
        this.angemeldeterMitarbeiter.person;
    }
  },

  methods: {
    unterschrift1Updated(u1) {
      if (u1) {
        if (!u1.id) return;

        this.korrespondenz.versand.unterschrift1 = u1;
      } else {
        this.korrespondenz.versand.unterschrift1 = {};
      }
    },
    unterschrift2Updated(u2) {
      if (u2) {
        if (!u2.id) return;

        this.korrespondenz.versand.unterschrift2 = u2;
      } else {
        this.korrespondenz.versand.unterschrift2 = {};
      }
    },
    speichern() {
      if (this.loading) return;

      if (
        (this.korrespondenz.versand.isVorlage && this.inhaltVorhanden) ||
        !this.korrespondenz.versand.isVorlage
      ) {
        this.loading = true;

        if (this.korrespondenz.versand.isVorlage) {

          const langObj = this.langs.find(lang => lang.id === this.korrespondenz.versand.defaultSprache)

          this.korrespondenz.versand.inhalt = this.korrespondenz.versand.translation[langObj.deepl_kuerzel].text

          this.korrespondenz.inhalt = this.korrespondenz.versand.translation[langObj.deepl_kuerzel].text

        }

        var json = Object.assign({}, this.korrespondenz);

        if (json.inhalt) {
          this.$set(json, "inhalt", this.unhighlightTextcodes(json.inhalt));
        }

        json.verarbeitungsstatus = this.korrespondenz.verarbeitungsstatus;

        json.archivierung = this.korrespondenz.archivierung;

        if (json.versand) {
          this.$set(
            json.versand,
            "inhalt",
            this.unhighlightTextcodes(json.versand.inhalt),
          );
        }

        if (json.versand.isVorlage) {
          const translationObjects = json.versand.translation;
          // Iteriere durch alle Übersetzungsobjekte und wende unhighlightTextcodes an
          Object.keys(translationObjects).forEach(key => {
              if (translationObjects[key].text) {
                  translationObjects[key].text = this.unhighlightTextcodes(translationObjects[key].text);
              }
          });
        }

        if (!this.id) {
          Api.post("korrespondenz/", json)
            .then((response) => {
              this.initializeKorrespondenz(response.data);
              this.$notify({
                type: "success",
                title: this.$t("global.actionsuccessful"),
                text: this.$t("global.correspondencesuccessfullysaved"),
              });

              this.$router.replace({
                name: "korrespondenz",
                params: { id: response.data.id, anzeige: 0 },
              });
            })
            .catch((e) => {
              this.$notify(apiErrorToAlert(e));
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          Api.put("korrespondenz/", json, {
            params: { id: this.korrespondenz.id },
          })
            .then((response) => {
              this.initializeKorrespondenz(response.data);
              this.$notify({
                type: "success",
                title: this.$t("global.actionsuccessful"),
                text: this.$t("global.correspondencesuccessfullysaved"),
              });
            })
            .catch((e) => {
              this.$notify(apiErrorToAlert(e));
            })
            .finally(() => {
              this.loading = false;
            });
        }
      } else {
        this.$notify({
          type: "error",
          title: this.$t("global.actionfailed"),
          text: this.$t(
            "global.pleasefillincontentandsubjectforatleastonelanguage"
          ),
        });
      }
    },

    abbrechen() {
      this.$router.push({ name: "korrespondenz-liste" });
    },

    initializeKorrespondenz(korrespondenz) {
      if (korrespondenz) {
        this.korrespondenz = korrespondenz;

        korrespondenz.person.sort((a, b) => {
          return a.personName > b.personName;
        });

        korrespondenz.firma.sort((a, b) => {
          return a.firmaName > b.firmaName;
        });

        if (this.korrespondenz.inhalt) {
          this.$set(
            this.korrespondenz,
            "inhalt",
            this.highlightTextcodes(this.korrespondenz.inhalt)
          );
        }

        if (this.korrespondenz.versand) {
          this.$set(
            this.korrespondenz.versand,
            "inhalt",
            this.highlightTextcodes(this.korrespondenz.versand.inhalt)
          );

          this.$set(
            this.korrespondenz,
            "inhalt",
            this.korrespondenz.versand.inhalt
          );

          if (!this.korrespondenz.versand.inhalt)
            this.korrespondenz.versand.inhalt = "";

          if (this.korrespondenz.versand.unterschrift1) {
            this.korrespondenz.versand.unterschrift1URL =
              this.$CONST("CONFIG").BASEURL +
              this.korrespondenz.versand.unterschrift1.bild;
          }
          if (this.korrespondenz.versand.unterschrift2) {
            this.korrespondenz.versand.unterschrift2URL =
              this.$CONST("CONFIG").BASEURL +
              this.korrespondenz.versand.unterschrift2.bild;
          }

          this.korrespondenz.versand.erstelltvon =
            korrespondenz.versand.erstelltvon;
          this.empfaengerKey += 1;
        } else {
          this.korrespondenz.versand = {
            erstelltvon: this.angemeldeterMitarbeiter.person,
            datum: new Intl.DateTimeFormat("de").format(new Date()),
            betreff: "",
            inhalt: "",
          };
        }

        /*if (this.korrespondenz.anmeldung) {
          if (korrespondenz.anmeldung.veranstaltung)
            this.korrespondenz.anmeldung =
              korrespondenz.anmeldung.veranstaltung.titel;
        }*/

        if (
          // Adresse nachträglich fixen. Wird benötigt wenn Korrespondenz von anderem Record her erstellt wird
          !this.korrespondenz.isMassKorrespondenz &&
          (this.korrespondenz.korrespondenzadresse == null ||
            this.korrespondenzadresse == "")
        ) {
          this.updatedEmpfaenger();
        }
        //this.updatedEmpfaenger();

        if (this.korrespondenz.versand.ort == null)
          this.korrespondenz.versand.ort = this.$CONST("CONFIG").MANDANTSTADT;

        //this.initializeChangewatcher(["korrespondenz"]);
      } else {
        console.error("korrespondenz ist null");
      }

      this.editable = !this.berechtigungen.m_korrespondenz.update
        ? false
        : this.editable;

      this.setAnrede();
    },

    vorlageWaehlen() {
      this.$bus.$emit("open-korrespondenz-vorlage-modal");
    },

    vorlageSetzen(vorlage) {
      //this.korrespondenz.versand = vorlage;
      this.korrespondenz.versand.isVorlage = true;
      this.korrespondenz.versand.datum = new Intl.DateTimeFormat("de").format(
        new Date()
      );
      this.korrespondenz.versand.erstelltvon =
        this.angemeldeterMitarbeiter.person;

      /*     this.korrespondenz.betreff = vorlage.betreff;
      this.korrespondenz.inhalt = vorlage.inhalt; */
      if (vorlage.inhalt) {
        this.$set(
          this.korrespondenz,
          "inhalt",
          this.unhighlightTextcodes(vorlage.inhalt)
        );
      }

      if (vorlage.versand?.inhalt) {
        this.$set(
          this.korrespondenz.versand,
          "inhalt",
          this.unhighlightTextcodes(vorlage.versand.inhalt)
        );
      }

      this.korrespondenz.versand.ort = vorlage.ort;
      this.korrespondenz.versand.betreff = vorlage.betreff;
      this.korrespondenz.versand.inhalt = this.highlightTextcodes(
        vorlage.inhalt
      );
      this.korrespondenz.versand.beilagen = vorlage.beilagen;
      this.korrespondenz.versand.unterschrift1 = vorlage.unterschrift1;
      this.korrespondenz.versand.unterschrift2 = vorlage.unterschrift2;

      this.langs.forEach((lang) => {
        this.korrespondenz.versand.translation[lang.kuerzel].betreff =
          vorlage.translation[lang.kuerzel].betreff;

        this.korrespondenz.versand.translation[lang.kuerzel].text =
          this.highlightTextcodes(vorlage.translation[lang.kuerzel].text);
      });
      this.korrespondenz.versand.defaultSprache = vorlage.defaultsprache;

      this.inhaltKey += 1;
      this.empfaengerKey += 1;
    },
    setTeilnehmer(teilnehmer) {
      this.teilnehmer = teilnehmer;
    },
    updatedSelected(selectedIds) {
      this.massSelected = selectedIds.length;
    },
    updatedEmpfaenger(person, firma) {
      if (person) this.korrespondenz.person = person;
      if (firma) this.korrespondenz.firma = firma;

      //Wenn nur Person gewählt

      let hatPerson = !this.korrespondenz.person
        ? false
        : !this.korrespondenz.person[0]
        ? false
        : this.korrespondenz.person[0].id
        ? true
        : false;
      let hatFirma = !this.korrespondenz.firma
        ? false
        : !this.korrespondenz.firma[0]
        ? false
        : this.korrespondenz.firma[0].id
        ? true
        : false;

      if (hatPerson && !hatFirma) {
        Api.get("geschaeftspartner/", {
          params: {
            id: this.korrespondenz.person[0].id,
          },
        }).then((response) => {
          this.korrespondenz.person[0] = response.data;

          if (
            response.data.anrede.id == this.$CONST("ANREDEN").FORMAL ||
            response.data.anrede.id == this.$CONST("ANREDEN").NEUTRAL
          ) {
            if (response.data.geschlecht) {
              this.korrespondenz.anrede =
                response.data.geschlecht.id == this.$CONST("GESCHLECHTER").MALE
                  ? this.$t("global.dearmr") + response.data.name
                  : response.data.geschlecht.id ==
                    this.$CONST("GESCHLECHTER").FEMALE
                  ? this.$t("global.dearms") + response.data.name
                  : this.$t("global.dearpersonformal") + response.data.name;
            } else {
              this.korrespondenz.anrede = this.$t("global.dearsirormadam");
            }
          } else {
            this.korrespondenz.anrede =
              response.data.geschlecht.id == this.$CONST("GESCHLECHTER").MALE
                ? this.$t("global.dearM") + response.data.vorname
                : response.data.geschlecht.id ==
                  this.$CONST("GESCHLECHTER").FEMALE
                ? this.$t("global.dearF") + response.data.vorname
                : this.$t("global.dearperson") + response.data.vorname;
          }

          this.empfaengerKey += 1;

          Api.get("geschaeftspartner/adresse/", {
            params: {
              gpid: this.korrespondenz.person[0].id,
              typ: this.$CONST("ADRESSVERWENDUNGSTYPEN").CORRESPONDENCE,
              aec: true,
            },
          }).then((response) => {
            if (response.data.length == 0) {
              this.korrespondenz.korrespondenzadresse = "";
              this.$notify({
                type: "warning",
                title: this.$t("global.hint"),
                text: this.$t("global.nocorrespondenceaddressfound"),
              });
              return;
            } else {
              let label = response.data[0].adresse_label.split("\n");

              let adressierung = this.korrespondenz.person[0].geschlecht
                ? this.korrespondenz.person[0].geschlecht.id ==
                  this.$CONST("GESCHLECHTER").MALE
                  ? this.$t("global.mr")
                  : this.korrespondenz.person[0].geschlecht.id ==
                    this.$CONST("GESCHLECHTER").FEMALE
                  ? this.$t("global.ms")
                  : this.$t("global.person")
                : "";

              adressierung += "\n" + this.korrespondenz.person[0].vorname + " " + this.korrespondenz.person[0].name;

              if (!response.data[0].firma) label.splice(0, 0, [adressierung]);
              else label.splice(1, 0, [adressierung]);

              this.korrespondenz.korrespondenzadresse = label.join("\n");
            }

            this.inhaltKey += 1;
          });
        });
        //Wenn nur Firma gewählt
      } else if (!hatPerson && hatFirma) {
        Api.get("geschaeftspartner/firma/", {
          params: {
            id: this.korrespondenz.firma[0].id,
          },
        }).then((response) => {
          this.korrespondenz.firma[0] = response.data;

          this.korrespondenz.anrede = this.$t("global.dearsirormadam");

          this.empfaengerKey += 1;

          Api.get("geschaeftspartner/adresse/", {
            params: {
              fid: this.korrespondenz.firma[0].id,
              typ: this.$CONST("ADRESSVERWENDUNGSTYPEN").CORRESPONDENCE,
              aec: true,
            },
          }).then((response) => {
            if (response.data.length == 0) {
              this.korrespondenz.korrespondenzadresse = "";
              this.$notify({
                type: "warning",
                title: this.$t("global.hint"),
                text: this.$t("global.nocorrespondenceaddressfound"),
              });
              return;
            } else {
              let label = response.data[0].adresse_label.split("\n");

              let adressierung = "";

              adressierung += this.korrespondenz.firma[0].name;

              if (!response.data[0].firma) label.splice(0, 0, [adressierung]);
              else label.splice(1, 0, [adressierung]);

              this.korrespondenz.korrespondenzadresse = label.join("\n");
            }

            this.inhaltKey += 1;
          });
        });
        //Wenn Firma und Person gewählt
      } else if (hatPerson && hatFirma) {
        Api.get("geschaeftspartner/", {
          params: {
            id: this.korrespondenz.person[0].id,
          },
        }).then((response) => {
          this.korrespondenz.person[0] = response.data;

          if (
            response.data.anrede.id == this.$CONST("ANREDEN").FORMAL ||
            response.data.anrede.id == this.$CONST("ANREDEN").NEUTRAL
          ) {
            if (response.data.geschlecht) {
              this.korrespondenz.anrede =
                response.data.geschlecht.id == this.$CONST("GESCHLECHTER").MALE
                  ? this.$t("global.dearmr") + response.data.name
                  : response.data.geschlecht.id ==
                    this.$CONST("GESCHLECHTER").FEMALE
                  ? this.$t("global.dearms") + response.data.name
                  : this.$t("global.dearpersonformal") + response.data.name;
            } else {
              this.korrespondenz.anrede = this.$t("global.dearsirormadam");
            }
          } else {
            this.korrespondenz.anrede =
              response.data.geschlecht.id == this.$CONST("GESCHLECHTER").MALE
                ? this.$t("global.dearM") + response.data.vorname
                : response.data.geschlecht.id ==
                  this.$CONST("GESCHLECHTER").FEMALE
                ? this.$t("global.dearF") + response.data.vorname
                : this.$t("global.dearperson") + response.data.vorname;
          }

          this.empfaengerKey += 1;

          Api.get("geschaeftspartner/adresse/", {
            params: {
              fid: this.korrespondenz.firma[0].id,
              typ: this.$CONST("ADRESSVERWENDUNGSTYPEN").CORRESPONDENCE,
              aec: true,
            },
          }).then((response) => {
            if (response.data.length == 0) {
              this.korrespondenz.korrespondenzadresse = "";
              return;
            } else {
              let label = response.data[0].adresse_label.split("\n");

              let adressierung = "";
              adressierung += this.korrespondenz.person[0].geschlecht
                ? this.korrespondenz.person[0].geschlecht.id ==
                  this.$CONST("GESCHLECHTER").MALE
                  ? this.$t("global.mr") + "\n"
                  : this.korrespondenz.person[0].geschlecht.id ==
                    this.$CONST("GESCHLECHTER").FEMALE
                  ? this.$t("global.ms") + "\n"
                  : this.$t("global.person") + "\n"
                : "";

              adressierung +=
                this.korrespondenz.person[0].vorname +
                " " +
                this.korrespondenz.person[0].name +
                "\n";
              adressierung += this.korrespondenz.firma[0].name;

              /*
              let adressierung = this.korrespondenz.firma[0].name;
              adressierung += this.korrespondenz.person[0].geschlecht
                ? this.korrespondenz.person[0].geschlecht.id ==
                  this.$CONST("GESCHLECHTER").MALE
                  ? "\nHerr "
                  : "\nFrau "
                : "\n";

              adressierung += this.korrespondenz.person[0].personName;
*/
              /*if (!response.data[0].firma) label.splice(0, 0, [adressierung]);
            else*/ label.splice(0, 0, [adressierung]);

              this.korrespondenz.korrespondenzadresse = label.join("\n");
            }

            this.inhaltKey += 1;
          });
        });
      }
    },

    ausgebenKorrespondenzPdfCache(selected) {
      this.pdfFiles = [];



      if (!this.korrespondenz.isMassKorrespondenz) {
        let ids = this.id;
        this.generatePDF(1, ids, 1, 1, false);
      } else {
        let printIds = [];
        if (selected) {
          let selected = this.korrespondenz.person.filter(
            (person) => person.selected
          );
          printIds = selected.map((person) => person.id);

          selected += this.korrespondenz.firma.filter(
            (firma) => firma.selected
          );
          printIds += "," + selected.map((firma) => firma.id);
        } else {
          if (this.teilnehmer.length > 0) {
            printIds = this.teilnehmer.map((teilnehmer) => teilnehmer.id);
          } else {
            printIds = this.korrespondenz.person.map((person) => person.id);
            printIds += "," + this.korrespondenz.firma.map((firma) => firma.id);
          }
        }

        if (printIds.length > 100) {
          let index = 1,
            counter = parseInt(printIds.length / 100, 10);

          if (parseInt(printIds.length / 100, 10) != printIds.length / 100)
            counter++;

          for (var i = 0; i < printIds.length; i += 100) {
            let subList = printIds.slice(i, i + 100);
            let ids = subList.join(",");

            this.generatePDF(index, ids, counter, subList.length, true);
            index++;
          }
        } else {
          printIds = printIds.join(",");
          this.generatePDF(1, printIds, 1, printIds.length, true);
        }
      }

      this.$refs.pdfLayer.show();
    },

    generatePDF(index, ids, counter, size, isMassKorrespondenz) {
      let json = {
        idlist: ids,
      };

      Api.post("printcache/", json, { params: {} }).then((response) => {
        let cacheId = response.data["cache-id"];

        const options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        };
        const dateTimeFormat = new Intl.DateTimeFormat("de", options);
        let filename = dateTimeFormat.format(new Date()).replace(/\./g, "-");
        let betreff = "";
        if (this.korrespondenz.versand.isVorlage) {
          betreff = this.korrespondenz.versand.translation[
            this.defaultSprache.kuerzel
          ].betreff.replace(" ", "_");
        } else {
          betreff = this.korrespondenz.versand.betreff.replace(" ", "_");
        }

        let pdfPath;

        if (isMassKorrespondenz) {
          pdfPath = `pdfgenerator/pdfcache.act?filename=${filename}_${betreff}.pdf&report=/api/pdfgenerator/massenkorrespondenz.htm&cache=${cacheId}&rec=${this.korrespondenz.id}`;
        } else {
          pdfPath = `pdfgenerator/pdfcache.act?filename=${filename}_${betreff}.pdf&report=/api/pdfgenerator/korrespondenz.htm&cache=${cacheId}&rec=${this.korrespondenz.id}`;
        }

        Api.get(pdfPath).then((response) => {
          let retValue = {
            index: index,
            url: server.url + response.data.filepath,
            withCredentials: true,
            count: counter,
            size: size,
          };

          this.pdfFiles.push(retValue);
        });
      });

      

    },

    setAnrede() {
      let hatPerson = !this.korrespondenz.person
        ? false
        : !this.korrespondenz.person[0]
        ? false
        : this.korrespondenz.person[0].id
        ? true
        : false;

      if (hatPerson) {
        if (
          this.korrespondenz.person[0].anrede.id ==
            this.$CONST("ANREDEN").FORMAL ||
          this.korrespondenz.person[0].anrede.id ==
            this.$CONST("ANREDEN").NEUTRAL
        ) {
          if (this.korrespondenz.person[0].geschlecht) {
            this.korrespondenz.anrede =
              this.korrespondenz.person[0].geschlecht.id ==
              this.$CONST("GESCHLECHTER").MALE
                ? this.$t("global.dearmr") + this.korrespondenz.person[0].name
                : this.korrespondenz.person[0].geschlecht.id ==
                  this.$CONST("GESCHLECHTER").FEMALE
                ? this.$t("global.dearms") + this.korrespondenz.person[0].name
                : this.$t("global.dearpersonformal") +
                  this.korrespondenz.person[0].name;
          } else {
            this.korrespondenz.anrede = this.$t("global.dearsirormadam");
          }
        } else {
          this.korrespondenz.anrede =
            this.korrespondenz.person[0].geschlecht.id ==
            this.$CONST("GESCHLECHTER").MALE
              ? this.$t("global.dearM") + this.korrespondenz.person[0].vorname
              : this.korrespondenz.person[0].geschlecht.id ==
                this.$CONST("GESCHLECHTER").FEMALE
              ? this.$t("global.dearF") + this.korrespondenz.person[0].vorname
              : this.$t("global.dearperson") +
                this.korrespondenz.person[0].vorname;
        }
      }
      this.empfaengerKey += 1;

      if (!hatPerson) {
        this.korrespondenz.anrede = this.$t("global.dearsirormadam");
      }
    },

    ausgebenKorrespondenzPdf() {
      if (!this.korrespondenz.isMassKorrespondenz) {
        this.pdfPath =
          server.url +
          `/pdfgenerator/pdf.act?filename=Brief.pdf&report=/pdfgenerator/korrespondenz.htm&ids=${this.id}&footer=/pdfgenerator/footer.htm&header=/pdfgenerator/header.htm`;

        this.$refs.pdfLayer.show();
      } else {
        let filename = new Intl.DateTimeFormat("de").format(new Date());

        this.pdfPath =
          server.url +
          `/pdfgenerator/pdf.act?filename=Brief-${filename}.pdf&report=/pdfgenerator/massenkorrespondenz.htm&ids=${this.id}&header=/pdfgenerator/header.htm&footer=/pdfgenerator/footer.htm`;

        this.$refs.pdfLayer.show();
      }
    },

    korrespondenzLoeschen() {
      Api.delete("korrespondenz/", {
        params: { ids: this.id },
      }).then(() => {
        this.$router.push({ name: "korrespondenz-liste" });
      });
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "korrespondenz-loeschen-modal");
    },
    setActiveLang(index) {
      this.activeLang = index;
    },

    highlightTextcodes(text) {
      if (!text) return "";

      const pattern = /(@\[([^\]]+)\])|(\{([^}]+)\})/g;

      return text.replace(pattern, (match, p1, p2, p3, p4) => {
        const highlightedText = p1 || p3;
        return highlightedText
          ? `<span style="color:#5299e0;">${highlightedText}</span>`
          : match;
      });
    },

    unhighlightTextcodes(text) {
      if (!text) return "";

      const pattern =
        /<span style="color:#5299e0;">([^<]+)<\/span>|<span style="color:#8bc34a;">([^<]+)<\/span>/g;

      return text.replace(pattern, (match, p1, p2) => {
        if (p1) return p1;
        if (p2) return p2;
      });
    },
    goToHelpPage() {
      window.open(
        this.$CONST("CONFIG").BASEURL +
          "/help/massenkorrespondenz/brief-feldfunktionen/",
        "_blank"
      );
    },
  },
};
</script>

<style></style>
