<template>
  <div class="modal" tabindex="-1" :id="id" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content bg-ice">
        <div class="modal-header bg-cosmos p-3">
          <span>{{ $t("global.dispensationhandlingtitle") }}</span>
          <modal-close-button @confirmed="close" />
        </div>
        <div class="modal-body">
          <p>{{ message || $t("global.dispensationhandlingmessage") }}</p>
          <slot></slot>
          <div class="checkboxInput">
            <div class="form-group">
              <b-form-checkbox
                v-model="alleNoteneingabenDispensiert"
                class="form-checkbox-input"
              >
                {{ $t("global.allgradesdispensed") }}
              </b-form-checkbox>
              <b-form-checkbox
                v-model="alleAnwesenheitenDispensiert"
                class="form-checkbox-input"
              >
                {{ $t("global.allattendancedispensed") }}
              </b-form-checkbox>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row w-100">
            <div class="col-12 text-center">
              <button type="button" class="btn btn-primary" @click="save">
                {{ $t("global.apply") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { modal } from "@/mixins/Modal";
import ModalCloseButton from "@/components/global/ModalCloseButton";

import App from "@/AppApi";

export default {
  name: "DispensationHandlingModal",
  components: {
    ModalCloseButton,
  },
  mixins: [modal],
  props: {
    id: {
      type: String,
      default: "Dispensation-handling-modal",
    },
    anmeldung: {
      type: Object,
    },
    message: {
      type: String,
    },
  },
  data() {
    return {
      alleNoteneingabenDispensiert: false,
      alleAnwesenheitenDispensiert: false,
    };
  },
  methods: {
    save() {
      // save inputs and PUT to dispensationhandling endpoint
      let json = {
        anmeldungId: this.anmeldung?.id,
        veranstaltungId: this.anmeldung?.veranstaltung?.id,
        anmeldetyp: this.anmeldung?.anmeldetyp,
        dispensiertVonAllenNoteneingaben: this.alleNoteneingabenDispensiert,
        dispensiertVonAllenAnwesenheiten: this.alleAnwesenheitenDispensiert,
      };
      App.put("anmeldung/dispensierung/", json, {})
        .then((response) => {
          this.$notify({
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.dispensationhandlingsuccessmessage"),
            type: "success",
          });
          this.$emit("saved");
        })
        .catch((error) => {
          this.$notify({
            title: this.$t("global.actionfailed"),
            text: this.$t("global.dispensationhandlingfailedmessage"),
            type: "warn",
          });
        })
        .finally(() => {
          this.closeModal();
        });
    },
    close() {
      this.$emit("closed");
      this.closeModal();
    },
  },
};
</script>

<style scoped></style>
